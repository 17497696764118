import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidationRecord } from 'new/form/common/types';
import { InquiryProcessValidations } from 'new/form/model';

import { contractDetailsStepValidations } from '../steps/contractDetails/validations';
import { customerDataPageValidations } from '../steps/customerData/validations';

//TODO add description

const createValidations: ValidationRecord<InquiryType.mmv> = {
  contractDetails: contractDetailsStepValidations,
  customerDataPage: customerDataPageValidations,
  userProfilePage: {
    contactPersonSection: () => true,
    passwordSection: () => true,
    termsAndConditionsSection: () => true,
  },
};

const editValidations: ValidationRecord<InquiryType.mmv> = {
  ...createValidations,
};

export const validations: InquiryProcessValidations<InquiryType.mmv> = {
  create: createValidations,
  editCustomer: editValidations,
  editOperation: editValidations,
};

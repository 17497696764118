import React, { useCallback, useMemo, useEffect } from 'react';

import { useForm } from 'react-final-form';

import { SelectWithField } from 'components/Selects/Select';
import {
  USER_PROFILE_FOUNDING_MONTH,
  CORONA_FOUNDING_MONTH,
  CORONA_FOUNDING_YEAR,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { getMonthsOptions } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingMonth/service';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { useTranslations } from 'utils/hooks/useTranslations';

const useCoronaFoundingMonthOptions = () => {
  const t = useTranslations();
  const yearField = useGetFieldNumericValue(CORONA_FOUNDING_YEAR);

  const currentYear = new Date().getUTCFullYear();
  const currentMonth = new Date().getUTCMonth() + 1;

  const filterFutureMonths = useCallback(
    // @ts-ignore
    // FIXME this is incorrectly typed
    ({ value }: any) => !(+currentYear === yearField && value > currentMonth),
    [currentMonth, currentYear, yearField],
  );

  return useMemo(() => getMonthsOptions(t).filter(filterFutureMonths), [filterFutureMonths, t]);
};

const CoronaFoundingMonth: React.FC = () => {
  const selectedMonth = useGetFieldNumericValue(CORONA_FOUNDING_MONTH);
  const { change } = useForm();
  const fieldController = FormFieldController.instance;

  useEffect(() => {
    change(USER_PROFILE_FOUNDING_MONTH, selectedMonth);
    fieldController.saveValue(USER_PROFILE_FOUNDING_MONTH, selectedMonth);
  }, [fieldController, change, selectedMonth]);

  const monthOptions = useCoronaFoundingMonthOptions();
  return (
    <SelectWithField
      name={CORONA_FOUNDING_MONTH}
      options={monthOptions}
      data-testid={'select-field-' + CORONA_FOUNDING_MONTH}
    />
  );
};

export default CoronaFoundingMonth;

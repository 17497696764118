import { mmvFields } from 'mmv/inquiry/fields';
import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

const fields = mmvFields;
const translations =
  translationsObject.inquiryType.mmv.steps.customerDataPage.sections
    .authorizationOfRepresentationSection.fields;

export const authorizationOfRepresentationSectionLabelsMap: LabelsMap = {
  [fields['user-representation']]: translations.authorizedToRepresent.caption,
  [fields['user-shared-representation']]: translations.authorizedToRepresentAlone.caption,
  // representative translations
  [fields['user-representative.representative-gender']]:
    translations.representative.fields.gender.caption,
  [fields['user-representative.representative-first-name']]:
    translations.representative.fields.firstName.caption,
  [fields['user-representative.representative-last-name']]:
    translations.representative.fields.lastName.caption,
  [fields['user-representative.representative-email']]:
    translations.representative.fields.email.caption,
  [fields['user-representative.representative-phone-number']]:
    translations.representative.fields.phoneNumber.caption,
  [fields['user-representative.representative-birthdate']]:
    translations.representative.fields.birthdate.caption,
};

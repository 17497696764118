import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import env from '@beam-australia/react-env';
import { ChakraProvider } from '@chakra-ui/react';
import _get from 'lodash/get';
import merge from 'lodash/merge';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';

import { loadPartnerSettingsAction } from 'components/App/PartnerProvider/service';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { ConfigController } from 'config/ConfigController';
import { FeatureFlagsMapper } from 'config/featureFlags/FeatureFlagsMapper';
import { FormConfigMapper } from 'config/formConfig/FormConfigMapper';
import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { PartnerMapper } from 'config/partner/PartnerMapper';
import mmvOverrides from 'mmv/theme/overrides';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryMapType } from 'modules/Inquiry/InquiryMapType';
import defaultTheme from 'styles/defaultTheme';
import { createBrandedTheme } from 'theme';
import { getPathForError } from 'utils/redirectOnFail';

import useLanguagePreferences from '../LanguageProvider/useLanguagePreferences';

export const THEME_OVERRIDES_MAP: InquiryMapType<unknown> = {
  [InquiryType.mmv]: mmvOverrides,
  default: {},
};

export const extractSubDomainFromHostname = (hostname: string) => {
  const hostnameArr = hostname.split('.');
  const subdomain = hostnameArr[0];
  /*
  This is for extracting partner subdomain from hostname.
  It also handles case when we have subdomain for dynamic staging.
  Examples:
  main stg: big.eco-banking-fe.staging.de
  dynamic stg: 2030-pr-big.eco-banking-fe.staging.de
  In both cases it extracts 'big'.
  */
  const splittedSubdomain = subdomain.split('-pr-');

  if (splittedSubdomain.length === 2) {
    return splittedSubdomain[1];
  }

  return subdomain;
};

const useLoadPartnerSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hostname = window.location.host;

  const mainPartner = env('MAIN_PARTNER_ID');
  const partnerId = mainPartner || extractSubDomainFromHostname(hostname);
  const [isLoading, setIsLoading] = useState(true);
  const [payload, setPayload] = useState<any>();

  const { selectedLanguage } = useLanguagePreferences();

  useEffect(() => {
    async function loadPartnerSettings() {
      const { error, payload } = (await dispatch(
        loadPartnerSettingsAction(partnerId, hostname),
      )) as any;
      if (error) {
        const status = _get(error, 'response.status', 0);
        history.push(getPathForError(status));
      }
      setPayload(payload);
    }
    loadPartnerSettings();
  }, [dispatch, history, hostname, partnerId]);

  useEffect(() => {
    if (payload?.data?.data) {
      const flagsMapper = new FeatureFlagsMapper(payload?.data?.data?.attributes);
      ConfigController.featureFlags.save(flagsMapper.resolveFlags(), { force: true });
      const partnerMapper = new PartnerMapper(payload?.data?.data);
      ConfigController.partner.save(
        {
          details: partnerMapper.resolveDetails(),
          meta: {
            ...partnerMapper.resolveMeta(),
            language: selectedLanguage,
          },
          copy: partnerMapper.resolveCopy(),
        },
        { force: true },
      );
      const formConfigMapper = new FormConfigMapper(payload?.data?.data?.attributes);
      const resolvedConfig = formConfigMapper.resolveConfig();
      ConfigController.form.save(
        {
          availableInquiryTypes: resolvedConfig.availableInquiryTypes,
          formsConfig: resolvedConfig.formsConfig,
          assets: resolvedConfig.assets,
          dropdowns: resolvedConfig.dropdowns,
        },
        { force: true },
      );
      ConfigController.form.selectInquiryType(resolvedConfig.selectedInquiryType);
      setIsLoading(false);
    }
  }, [payload, selectedLanguage]);

  return isLoading;
};

export const PartnerProvider = ({ children }: { children: ReactNode }) => {
  const isLoading = useLoadPartnerSettings();

  const formConfig = useFormConfig();

  const {
    meta: { color },
  } = usePartnerConfig();

  // Preferably a dynamic import and lazy loading particular partner overrides
  const themeOverrides = useMemo(() => {
    return THEME_OVERRIDES_MAP[formConfig.selectedInquiryType] || THEME_OVERRIDES_MAP.default;
  }, [formConfig.selectedInquiryType]);

  const theme = useMemo(() => merge({}, defaultTheme, { colors: { primary: color } }), [color]);

  const chakraTheme = useMemo(
    () => merge({}, createBrandedTheme(color), themeOverrides),
    [color, themeOverrides],
  );

  if (isLoading) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <SpinnerWrapper data-testid="spinner">
          <Spinner />
        </SpinnerWrapper>
      </ThemeProvider>
    );
  }

  return (
    <ChakraProvider theme={chakraTheme} resetCSS={false} portalZIndex={200}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ChakraProvider>
  );
};

import React, { useEffect, useState } from 'react';

import { FormSpy, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { useFormConfig } from 'config/formConfig/hooks';
import { fieldIsValid, fieldHasValue, fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import {
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import CompanyNoteSection from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyNoteSection/CompanyNoteSection';
import CompanySuggestion from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/CompanySuggestion';
import ExistingUserCompanySelect from 'pages/inquiryFlow/CompanyDetails/sections/Company/ExistingUserCompanySelect';
import { shouldShowCompanySuggestionsSelect } from 'pages/inquiryFlow/CompanyDetails/sections/Company/service';
import { SsoCompanySection } from 'pages/inquiryFlow/CompanyDetails/sections/Company/SsoCompanySection/SsoCompanySection';
import CompanyInformationForm from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/CompanyInformationForm';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { isLoggedInSelector } from 'store/user/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateExistingUserCompanySection = (form: any) =>
  fieldIsValid(COMPANY_DETAILS_EXISTING_USER_COMPANY)({ form });

export const validateCompanySelection =
  (isLoggedIn: boolean) =>
  (form: any): boolean => {
    if (!isLoggedIn) {
      return fieldIsValid(COMPANY_DETAILS_COMPANY)({ form });
    }
    if (
      fieldHasValue(
        COMPANY_DETAILS_EXISTING_USER_COMPANY,
        COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
      )({ form })
    ) {
      return fieldsAreValid([COMPANY_DETAILS_COMPANY, COMPANY_DETAILS_EXISTING_USER_COMPANY])({
        form,
      });
    }
    return fieldIsValid(COMPANY_DETAILS_EXISTING_USER_COMPANY)({ form });
  };

const CompanySelectionSection = ({ tooltip = false }) => {
  const t = useTranslations('pages.companyDetails.sections.company');
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [firstRender, setFirstRender] = useState(true);
  const form = useForm();
  const { change } = form;
  const fieldController = FormFieldController.instance;
  const shouldShowSuggestion = shouldShowCompanySuggestionsSelect({
    isLoggedIn,
    form,
  });

  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  // CRV-2291 Refresh AOS (animation library) to show section if page height change
  useEffect(() => {
    if (!firstRender) {
      change(USER_PROFILE_COMPANY_ALREADY_CUSTOMER, null);
      fieldController.saveValue(USER_PROFILE_COMPANY_ALREADY_CUSTOMER, null);
      change(USER_PROFILE_FOUNDING_YEAR, null);
      fieldController.saveValue(USER_PROFILE_FOUNDING_YEAR, null);
      change(USER_PROFILE_INDUSTRY, null);
      fieldController.saveValue(USER_PROFILE_INDUSTRY, null);
      change(USER_PROFILE_LEGAL_FORM, null);
      fieldController.saveValue(USER_PROFILE_LEGAL_FORM, null);
      change(USER_PROFILE_TURNOVER_CLASS, null);
      fieldController.saveValue(USER_PROFILE_TURNOVER_CLASS, null);
      change(USER_PROFILE_COMPANY_NAME, null);
      fieldController.saveValue(USER_PROFILE_COMPANY_NAME, null);
      change(USER_PROFILE_COMPANY_STREET, null);
      fieldController.saveValue(USER_PROFILE_COMPANY_STREET, null);
      change(USER_PROFILE_COMPANY_ZIP_CODE, null);
      fieldController.saveValue(USER_PROFILE_COMPANY_ZIP_CODE, null);
      change(USER_PROFILE_COMPANY_CITY, null);
      fieldController.saveValue(USER_PROFILE_COMPANY_CITY, null);
    }
    setFirstRender(false);
    // eslint-disable-next-line
  }, [shouldShowSuggestion]);

  return (
    <FormSpy
      subscription={{ values: true }}
      render={() => (
        <FormSection name={FormSections.companySelection} sectionNumber={1} title={t('title')}>
          {isLoggedIn && <ExistingUserCompanySelect tooltip={tooltip} />}
          {shouldShowSuggestion && (
            <FormRow>
              <CompanySuggestion fieldName={COMPANY_DETAILS_COMPANY} />
            </FormRow>
          )}
          <CompanyNoteSection />

          {isBfsService && (
            <Condition condition={validateCompanySelection(isLoggedIn)(form)}>
              <CompanyInformationForm />
            </Condition>
          )}
        </FormSection>
      )}
    />
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_SSO_ENABLED]: SsoCompanySection,
  default: CompanySelectionSection,
});

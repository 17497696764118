import { mittweidaInitialFormState } from 'mittweida/config/initialFormState';
import { mittweidaInitialFormValues } from 'mittweida/config/initialFormValues';
import { mittweidaRouteToPageNameMap } from 'mittweida/config/routeToPageNameMap';
import { mittweidaStepData } from 'mittweida/config/stepData';
import { mittweidaStepSequence } from 'mittweida/config/stepSequence';
import { mittweidaSummaryFields } from 'mittweida/config/summaryFields';
import { mittweidaSummaryPath } from 'mittweida/config/summaryPath';
import { mittweidaValidations } from 'mittweida/config/validations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryProcessConfig } from 'new/form/model';

export const mittweidaInquiryProcessConfig: InquiryProcessConfig<InquiryType.profiMittweida> = {
  validations: mittweidaValidations,
  summaryFields: mittweidaSummaryFields,
  summaryPath: mittweidaSummaryPath,
  stepData: mittweidaStepData,
  stepSequence: mittweidaStepSequence,
  routeToPageNameMap: mittweidaRouteToPageNameMap,
  initialFormState: mittweidaInitialFormState,
  initialFormValues: mittweidaInitialFormValues,
  formName: 'MITTWEIDA_FORM',
};

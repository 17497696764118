import { RLLProfitability } from 'models/types/Profitability.type';
import {
  CURRENT_USAGE_REE_PARKING_LOT_VALUE,
  CURRENT_USAGE_REE_GARAGE_VALUE,
  CURRENT_USAGE_REE_GARAGE_RENT,
  CURRENT_USAGE_REE_PARKING_LOT_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_VALUE,
  FUTURE_USAGE_REE_GARAGE_VALUE,
  FUTURE_USAGE_REE_GARAGE_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_RENT,
  CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT,
} from 'modules/Inquiry/Form/formFields';

const mapRealEstateMarketValueFromApi = (response: RLLProfitability['realEstateExpert']) => ({
  [CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING]: response.current.building.marketValuePerSqm,
  [CURRENT_USAGE_REE_PARKING_LOT_VALUE]: response.current.unit.marketValueParkingLot,
  [CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM]: response.current.unit.commercialRentPerSqm,
  [CURRENT_USAGE_REE_PARKING_LOT_RENT]: response.current.unit.rentParkingLot,
  [CURRENT_USAGE_REE_GARAGE_VALUE]: response.current.unit.marketValueGarage,
  [CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT]: response.current.unit.marketValuePerSqm,
  [CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]: response.current.unit.residentialRentPerSqm,
  [CURRENT_USAGE_REE_GARAGE_RENT]: response.current.unit.rentGarage,
  [FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING]: response.future.building.marketValuePerSqm,
  [FUTURE_USAGE_REE_PARKING_LOT_VALUE]: response.future.unit.marketValueParkingLot,
  [FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM]: response.future.unit.commercialRentPerSqm,
  [FUTURE_USAGE_REE_PARKING_LOT_RENT]: response.future.unit.rentParkingLot,
  [FUTURE_USAGE_REE_GARAGE_VALUE]: response.future.unit.marketValueGarage,
  [FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT]: response.future.unit.marketValuePerSqm,
  [FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]: response.future.unit.residentialRentPerSqm,
  [FUTURE_USAGE_REE_GARAGE_RENT]: response.future.unit.rentGarage,
})

export default mapRealEstateMarketValueFromApi;

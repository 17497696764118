import * as React from 'react';

import { Center, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { Asset } from 'components/Asset';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { allowDigitalProcessingAction } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/service';
import { useToasts } from 'shared/hooks/useToasts';
import { SET_ALLOW_DIGITAL_PROCESSING_SUCCESS } from 'store/inquiryDetails/actions';
import {
  getInquiryIdSelector,
  getAllowDigitalProcessingSelector,
} from 'store/inquiryDetails/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

type DigitalProcessing = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
};

export const DigitalProcessingStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: DigitalProcessing) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.digitalProcessing');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();
  const { makeCall } = useDispatchApiCall();
  const { success: notifySuccess } = useToasts();

  const allowDigitalProcessing = useSelector(getAllowDigitalProcessingSelector);

  const submitAction = async () => {
    const response = await makeCall(allowDigitalProcessingAction(inquiryId!!));
    if (!response.error) {
      notifySuccess({ description: t('actionSuccess') });
      dispatch({ type: SET_ALLOW_DIGITAL_PROCESSING_SUCCESS });
      onClose();
    }
  };

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>

          <TextComponent mb={6}>
            <MarkdownWrapper text={t('description')} />
          </TextComponent>

          <ButtonComponent
            disabled={!!allowDigitalProcessing}
            leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
            onClick={onOpen}
            variant="primary"
          >
            {t('action')}
          </ButtonComponent>
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset
              htmlHeight="200"
              type="dashboard"
              value={ProgressSectionType.DIGITAL_PROCESSING}
            />
          </Center>
        </GridItem>
      </Grid>

      {!isCompleted && (
        <AlertDialogComponent
          cancelText={t('cancelText')}
          confirmText={t('confirmText')}
          data-testid="archive-user-modal"
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={submitAction}
          title={t('title')}
        >
          {t('confirmation')}
        </AlertDialogComponent>
      )}
    </ProgressSection>
  );
};

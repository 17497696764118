import React, { FC } from 'react';

import _compact from 'lodash/compact';
import { useSelector } from 'react-redux';

import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { StyledFormRow } from 'pages/operationPortal/OperationInquiryList/FiltersDrawer/styles';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getInquiryListManagers } from 'store/inquiryList/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const DvagFilters: FC = () => {
  const t = useTranslations();

  const inquiryManagersOptions = _compact(
    useSelector(getInquiryListManagers).filter(
      ({ hasAssignedZipCodes = false, name }) => hasAssignedZipCodes,
    ),
  ).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <>
      <StyledFormRow>
        <SelectWithField
          name={INQUIRY_FILTERS.INQUIRY_MANAGER}
          caption={t('pages.inquiryList.filtersDrawer.fields.inquiryManager.caption')}
          options={[...inquiryManagersOptions]}
          placeholder={t('placeholders.pleaseChoose')}
          isClearable
          data-testid={'select-field-' + INQUIRY_FILTERS.INQUIRY_MANAGER}
        />
      </StyledFormRow>
      <StyledFormRow>
        <InputWithField
          name={INQUIRY_FILTERS.CUSTOMER_VB_NUMBER}
          caption={t('pages.inquiryList.filtersDrawer.fields.customerVBNumber.caption')}
          isClearable
        />
      </StyledFormRow>
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagFilters,
  default: () => null,
});

import { validateCompanyDetailsSection } from 'mittweida/inquiry/steps/company/sections/companyDetails/validations';
import { validateCompletingCompanyDetailsSection } from 'mittweida/inquiry/steps/company/sections/completingCompanyDetailsSection/validations';
import { validateObjectInformationSection } from 'mittweida/inquiry/steps/financingNeed/sections/objectInformationSection/validations';
import { validateOtherProjectInformationSection } from 'mittweida/inquiry/steps/financingNeed/sections/otherProjectInformationSection/validations';
import { validateProjectCompositionSection } from 'mittweida/inquiry/steps/financingNeed/sections/projectCompositionSection/validation';
import { validateRoleSection } from 'mittweida/inquiry/steps/financingNeed/sections/roleSection/validation';
import { validateOtherFundingSection } from 'mittweida/inquiry/steps/fundingDetails/sections/otherFundingSection/validations';
import { validateContactPersonSection } from 'mittweida/inquiry/steps/userProfile/sections/contactPerson/validations';
import { validatePasswordSection } from 'mittweida/inquiry/steps/userProfile/sections/passwordSection/validations';
import { validateTermsAndConditionsSection } from 'mittweida/inquiry/steps/userProfile/sections/termsAndConditions/validations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidationRecord } from 'new/form/common/types';
import { InquiryProcessValidations } from 'new/form/model';

const mittweidaCreateValidations: ValidationRecord<InquiryType.profiMittweida> = {
  financingNeedPage: {
    roleSection: (form, values, store) => validateRoleSection(form, values, store),
    otherProjectInformation: (form, values, store) =>
      validateOtherProjectInformationSection(form, values, store),
    objectInformationSection: (form, values, store) =>
      validateObjectInformationSection(form, values, store),
    projectCompositionSection: (form, values, store) =>
      validateProjectCompositionSection(form, values, store),
  },
  companyDetailPage: {
    companyDetailsSection: (form, values, store) =>
      validateCompanyDetailsSection(form, values, store),
    completingCompanyDetailsSection: (form, values, store) =>
      validateCompletingCompanyDetailsSection(form, values, store),
  },
  fundingDetailsPage: {
    otherFundingSection: (form, values, store) => validateOtherFundingSection(form, values, store),
  },
  userProfilePage: {
    contactPersonSection: (form, values, store) =>
      validateContactPersonSection(form, values, store),
    passwordSection: (form, values, store) => validatePasswordSection(form, values, store),
    termsAndConditionsSection: (form, values, store) =>
      validateTermsAndConditionsSection(form, values, store),
  },
};

const mittweidaEditValidations: ValidationRecord<InquiryType.profiMittweida> = {
  ...mittweidaCreateValidations,
  userProfilePage: {
    contactPersonSection: () => true,
    passwordSection: () => true,
    termsAndConditionsSection: () => true,
  },
};

export const mittweidaValidations: InquiryProcessValidations<InquiryType.profiMittweida> = {
  create: mittweidaCreateValidations,
  editCustomer: mittweidaEditValidations,
  editOperation: mittweidaEditValidations,
};

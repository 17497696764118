import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/Selects/Select/Select';
import { SALE_TYPE } from 'modules/Inquiry/Form/formFields';
import { AppDispatch } from 'store';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FILTERS_SALE_TYPES } from './constants';
import {
  getMarketDataFilterLegacy,
  setMarketDataFilter as setMarketDataFilterAction,
} from './filterManagementReducer';

const SalesTypeSelect = () => {
  const dispatch: AppDispatch = useDispatch();
  const filterData = useSelector(getMarketDataFilterLegacy);
  const t = useTranslations('pages.planningEvaluation.marketComparison');

  const [value, setValue] = useState(filterData?.saleType);
  const [label, setLabel] = useState(t(`dropdownField.${filterData?.saleType}.label`));

  useEffect(() => {
    switch (value) {
      case FILTERS_SALE_TYPES.UNIT:
        setLabel(t('dropdownField.saleHouse.label'));
        break;
      case FILTERS_SALE_TYPES.RENT:
        setLabel(t('dropdownField.rent.label'));
        break;
      case FILTERS_SALE_TYPES.BUILDING:
        setLabel(t('dropdownField.saleBuilding.label'));
        break;
      default:
        throw Error;
    }
  }, [value, setValue, t]);

  return (
    <Select
      name={SALE_TYPE}
      isNotSearchable={true}
      width="20.3125em"
      options={[
        { value: FILTERS_SALE_TYPES.UNIT, label: t('dropdownField.saleHouse.label') },
        {
          value: FILTERS_SALE_TYPES.BUILDING,
          label: t('dropdownField.saleBuilding.label'),
        },
        { value: FILTERS_SALE_TYPES.RENT, label: t('dropdownField.rent.label') },
      ]}
      onChange={(value) => {
        setValue(value);
        dispatch(
          setMarketDataFilterAction({
            ...filterData,
            saleType: value?.target?.value || value,
          }),
        );
      }}
      onBlur={() => null}
      onFocus={() => null}
      value={{ value: value, label: label }}
      data-testid={'select-field-' + SALE_TYPE}
    />
  );
};

export default SalesTypeSelect;

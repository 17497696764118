import React, { FormEvent } from 'react';

import { DrawerBody } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { DayPickerWithField } from 'components/DatePicker';
import FormRowBase from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { IInquiryListFilterByOptions } from 'models/InquiryList.model';
import { PurposeKind } from 'models/PurposeKind.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { INQUIRY_STATUSES, useInquiryStatusTranslator } from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { getInquiryListStatuses } from 'store/inquiryList/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import DvagFilters from './DvagFilters';
import { StyledFormRow, StyledHalfRow } from './styles';
import { usePurposeProducts } from './usePurposeProducts';

type Props = {
  values: IInquiryListFilterByOptions;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

export const FiltersDrawerBody = ({ values, handleSubmit }: Props) => {
  const t = useTranslations();
  const { isInteger, isSameOrAfter, isSameOrBefore, optional } = useFieldValidators();
  const { formTypes, purposes, products, setSelectedPurpose, setSelectedFormType } =
    usePurposeProducts();
  const translateStatus = useInquiryStatusTranslator();

  const statusesOptions = useSelector(getInquiryListStatuses).map(({ status }) => ({
    value: status,
    label: translateStatus(status),
  }));

  const allState = {
    value: 'all',
    label: translateStatus(INQUIRY_STATUSES.ALL),
  };

  const setPurpose = (purpose: PurposeKind) => setSelectedPurpose(purpose);

  const setFormtype = (formType: InquiryType) => setSelectedFormType(formType);

  const isBfsService = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: true,
    default: false,
  });

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit}>
        <StyledFormRow>
          <InputWithField
            name={INQUIRY_FILTERS.ID}
            validate={optional}
            caption={t('pages.inquiryList.filtersDrawer.fields.id.caption')}
            isClearable
          />
        </StyledFormRow>

        <FormRowBase>
          <StyledHalfRow>
            <DayPickerWithField
              name={INQUIRY_FILTERS.CREATED_AT_FROM}
              validate={isSameOrBefore(values.createdAtTo)}
              caption={t('pages.inquiryList.filtersDrawer.fields.createdAtFrom.caption')}
              isClearable
            />
          </StyledHalfRow>

          <StyledHalfRow>
            <DayPickerWithField
              name={INQUIRY_FILTERS.CREATED_AT_TO}
              validate={isSameOrAfter(values.createdAtFrom)}
              caption={t('pages.inquiryList.filtersDrawer.fields.createdAtTo.caption')}
              isClearable
            />
          </StyledHalfRow>
        </FormRowBase>

        <StyledFormRow>
          <InputWithField
            name={INQUIRY_FILTERS.COMPANY_NAME}
            validate={optional}
            caption={t('pages.inquiryList.filtersDrawer.fields.companyName.caption')}
            isClearable
          />
        </StyledFormRow>

        {formTypes.length > 1 && (
          <StyledFormRow>
            <SelectWithField
              name={INQUIRY_FILTERS.INQUIRY_TYPE}
              validate={optional}
              caption={t('pages.inquiryList.filtersDrawer.fields.inquiryType.caption')}
              placeholder={t('placeholders.pleaseChoose')}
              options={formTypes}
              isClearable
              onChange={setFormtype}
              data-testid={'select-field-' + INQUIRY_FILTERS.INQUIRY_MANAGER}
            />
          </StyledFormRow>
        )}

        {purposes.length > 1 && (
          <StyledFormRow>
            <SelectWithField
              name={INQUIRY_FILTERS.PURPOSE_KIND}
              validate={optional}
              caption={t('pages.inquiryList.filtersDrawer.fields.purposeKind.caption')}
              placeholder={t('placeholders.pleaseChoose')}
              options={purposes}
              isClearable
              onChange={setPurpose}
              data-testid="All-Filters-Labels"
            />
          </StyledFormRow>
        )}
        {products.length > 1 && !isBfsService && (
          <StyledFormRow>
            <SelectWithField
              name={INQUIRY_FILTERS.PRODUCT_KIND}
              validate={optional}
              caption={t('pages.inquiryList.filtersDrawer.fields.productKind.caption')}
              placeholder={t('placeholders.pleaseChoose')}
              options={products}
              isClearable
              data-testid={'select-field-' + INQUIRY_FILTERS.PURPOSE_KIND}
            />
          </StyledFormRow>
        )}

        <FormRowBase>
          <StyledHalfRow>
            <InputWithField
              name={INQUIRY_FILTERS.AMOUNT_FROM}
              validate={isInteger}
              caption={t('pages.inquiryList.filtersDrawer.fields.amountFrom.caption')}
              isClearable
            />
          </StyledHalfRow>

          <StyledHalfRow>
            <InputWithField
              name={INQUIRY_FILTERS.AMOUNT_TO}
              validate={isInteger}
              caption={t('pages.inquiryList.filtersDrawer.fields.amountTo.caption')}
              isClearable
            />
          </StyledHalfRow>
        </FormRowBase>

        <StyledFormRow>
          <SelectWithField
            name={INQUIRY_FILTERS.STATUS}
            validate={optional}
            caption={t('pages.inquiryList.filtersDrawer.fields.status.caption')}
            options={[allState, ...statusesOptions]}
            placeholder={t('placeholders.pleaseChoose')}
            isClearable
            data-testid={'select-field-' + INQUIRY_FILTERS.STATUS}
          />
        </StyledFormRow>

        <DvagFilters />
      </form>
    </DrawerBody>
  );
};

import _ from 'lodash';

import { DEFAULT_CURRENCY, PERCENTAGE } from 'constants/globalConstants';

import { tryParseFloat } from './number';

export const yearFormat = 'yyyy';
export const monthFormat = 'MM.yyyy';
export const payloadDateFormat = 'DD-MM-YYYY';
export const formDateFormat = 'yyyy-MM-dd';
export const datepickerFormat = 'dd.MM.yyyy';

const CURRENCY = 'EUR';
// price format is 1 123 4567,89 EUR
export const formatPrice = (
  price: number | string | undefined,
  withCurrency = true,
): string | undefined => {
  if (typeof price !== 'number' && !price) {
    return undefined;
  }
  return Number(price)
    .toLocaleString('de-DE', { style: 'currency', currency: CURRENCY })
    .replace(/\./g, String.fromCharCode(0x00a0) /*no-break space*/)
    .replace(/\s€/g, withCurrency ? '€' : '');
};

export const formatColumnValues = (
  value: number | string,
  unit: string = DEFAULT_CURRENCY,
  isBox: boolean = false,
) => {
  if (!value && typeof value !== 'number') {
    if (isBox) return '-';
    return '';
  }
  let roundValue;
  if (unit === PERCENTAGE || unit.includes(PERCENTAGE)) roundValue = _.round(Number(value), 2);
  else roundValue = _.round(Number(value));
  return roundValue
    .toLocaleString('de-DE')
    .replace(/\./g, String.fromCharCode(0x00a0))
    .concat(` ${unit}`);
};

/**
 * format 7000 to 7000 €
 */
export const formatPriceForFactoringLine = (
  price: number | string,
  withCurrency = true,
): string | undefined => {
  if (typeof price !== 'number' && !price) {
    return undefined;
  }

  return Number(price)
    .toLocaleString('de-DE', { style: 'currency', currency: CURRENCY, minimumFractionDigits: 0 })
    .replace(/\./g, String.fromCharCode(0x00a0) /*no-break space*/)
    .replace(/\s€/g, withCurrency ? '€' : '');
};

export const formatPriceGerman = (
  price: number | string,
  withCurrency = true,
): string | undefined => {
  if (typeof price !== 'number' && !price) {
    return undefined;
  }
  return Number(price)
    .toLocaleString('de-DE', { style: 'currency', currency: CURRENCY })
    .replace(/\s€/g, withCurrency ? '€' : '');
};

/**
 * format 2000000 to 2 000 000
 */
export const formatKilometer = (value: number | string): number | string | undefined =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(0x00a0));

export const formatPercentage = (n: number): number => parseFloat((n * 100).toFixed(2));

export const formatPriceAsThousands = (price: number): string | null | undefined =>
  formatPrice(price)?.replace(' €', ' T€');

export const formatDecimalWithPrecision2 = (value: string) =>
  parseFloat(parseFloat(value).toFixed(2));

// Adds two 0s to the end of values
export const formatDecimalWithOutRoundOff = (value: string | number) => {
  if (value === null || isNaN(Number(value))) {
    return '';
  }
  const re = new RegExp('^-?\\d+(?:.\\d{0,2})?');
  return value.toString().match(re)![0];
};

export const formatAsPercentage = (value: number) =>
  formatDecimalWithPrecision2(String(value * 100));

export const replaceDotWithComma = (value: string): string => value.replace('.', ',');

export const formatToDecimal = (value: string): string =>
  Number.parseFloat(value).toFixed(1).toString();

/**
 * format 20000 to 20.000
 */
export const numberWithCommas = (value: number | string) => {
  if (typeof value === 'number') {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatDecimalWithOutPrecision = (value: string) =>
  parseFloat(parseFloat(value).toFixed(0));

export const replaceDotWithCommaAndAddUnit = (value: string | undefined, unit?: string) => {
  if (value) {
    if (unit) {
      return replaceDotWithComma(value) + unit;
    }

    return replaceDotWithComma(value);
  }

  return '';
};

type DecimalFormatOptions = IntegerFormatOptions & { digits?: number }

const DEFAULT_FORMAT_OPTIONS = {
  digits: 2,
  locale: navigator?.language || 'de-DE'
}

export const formatDecimal = (value: string | number, options: DecimalFormatOptions = {}) => {
  const { digits, locale, unit } = { ...DEFAULT_FORMAT_OPTIONS, ...options }
  let number = typeof value === 'string' ? tryParseFloat(value) : value

  if (number === null || isNaN(number)) return '-'

  const formattedValue = Intl
    .NumberFormat(locale, { minimumFractionDigits: digits, maximumFractionDigits: digits })
    .format(number)

  return unit ? `${formattedValue} ${unit}` : formattedValue
}

interface IntegerFormatOptions {
  locale?: string
  unit?: string
}

export const formatInteger = (value: string | number, options: IntegerFormatOptions = {}) =>
  formatDecimal(value, { ...options, digits: 0 })

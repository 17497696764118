import {
  mittweidaFields,
  mittweidaFieldsWithoutParents,
  mittweidaFieldsWithSectionString,
  MittweidaFieldTypes,
} from 'mittweida/inquiry/fields';
import {MAX_AMOUNT_VALUE, MAX_DESCRIPTION_LENGTH, MIN_LOT_SIZE,} from 'modules/Inquiry/inquiry.constants';
import {InquiryType} from "modules/Inquiry/Inquiry.type";
import {fieldValidators} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import {ValidationMapType} from 'new/form/common/types';
import {validateArrayFields, validateFields, ValidateSectionFunction,} from 'new/form/useValidateFields';
import {InquiryConstants} from 'schema/inquirySchema.constants';

import {isPartitionOrStockholder, isUsageDeviation} from '../../../../conditions';

const {
  address,
  zipCode,
  city,
  description,
  lotSize,
  year,
  yearOfModernization,
  plannedProjectStart,
  plannedProjectEnd,
  furnishingQuality,
  qualityCondition,
  additionalBathroom,
  balcony,
  lift,
  cellar,
  parkingLotsCount,
  parkingLotPrice,
  parkingLotRentNow,
  parkingLotRentFuture,
  garagesCount,
  garagesPrice,
  garageRentNow,
  garageRentFuture,
  futureObjectType,
  futureUsage: { typeOfUse, usageSpace, plannedSalesPrice, numberOfUnits },
  usageDeviation,
  currentObjectType,
  currentUsage: {
    typeOfUse: currentTypeOfUse,
    usageSpace: currentUsageSpace,
    numberOfUnits: currentNumberOfUnits,
  },
  currentTotalRentResidential,
  currentTotalRentCommercial,
  vacancyResidential,
  vacancyCommercial,
  futureTotalRentResidential,
  futureTotalRentCommercial,
  rentalContracts,
  rentalIntends,
} = mittweidaFields.financingNeedPage.objectInformationSection;

const {
  futureUsage: {
    typeOfUse: typeOfUseWithoutParent,
    usageSpace: usageSpaceWithoutParent,
    plannedSalesPrice: plannedSalesPriceWithoutParent,
    numberOfUnits: numberOfUnitsWithoutParent,
  },
  currentUsage: {
    typeOfUse: currentTypeOfUseWithoutParent,
    usageSpace: currentUsageSpaceWithoutParent,
    numberOfUnits: currentNumberOfUnitsWithoutParent,
  },
} = mittweidaFieldsWithoutParents.financingNeedPage.objectInformationSection;

const validateGeneralData: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  return validateFields({
    fields: [
      address,
      zipCode,
      city,
      description,
      lotSize,
      year,
      yearOfModernization,
      plannedProjectStart,
      plannedProjectEnd,
    ],
    form,
    values,
    inquiryType: InquiryType.profiMittweida
  });
};

const validateQualityData: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  return validateFields({
    fields: [furnishingQuality, qualityCondition, additionalBathroom, balcony, lift, cellar],
    form,
    values,
    inquiryType: InquiryType.profiMittweida
  });
};

const validateParkingData: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  return validateFields({
    fields: [
      parkingLotsCount,
      parkingLotPrice,
      parkingLotRentNow,
      parkingLotRentFuture,
      garagesCount,
      garagesPrice,
      garageRentNow,
      garageRentFuture,
    ],
    form,
    values,
    inquiryType: InquiryType.profiMittweida
  });
};

const validateFutureObjectUsage: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  const normalFieldsValid = validateFields({ fields: [futureObjectType], form, values, inquiryType: InquiryType.profiMittweida });
  const arrayFieldsValid = validateArrayFields({
    arrayValues: values.financingNeedPage.objectInformationSection.futureUsage,
    fieldsWithoutParent: [
      typeOfUseWithoutParent,
      usageSpaceWithoutParent,
      plannedSalesPriceWithoutParent,
      numberOfUnitsWithoutParent,
    ],
    parentName:
      mittweidaFieldsWithSectionString.financingNeedPage.objectInformationSection.futureUsage
        .sectionString,
    form,
    values,
    inquiryType: InquiryType.profiMittweida
  });

  return [normalFieldsValid, arrayFieldsValid].every(Boolean);
};

const validateCurrentObjectUsage: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  if (isUsageDeviation(values)) {
    const normalFieldsValid = validateFields({
      fields: [usageDeviation, currentObjectType],
      form,
      values,
      inquiryType: InquiryType.profiMittweida
    });
    const arrayFieldsValid = validateArrayFields({
      arrayValues: values.financingNeedPage.objectInformationSection.currentUsage,
      fieldsWithoutParent: [
        currentTypeOfUseWithoutParent,
        currentUsageSpaceWithoutParent,
        currentNumberOfUnitsWithoutParent,
      ],
      parentName:
        mittweidaFieldsWithSectionString.financingNeedPage.objectInformationSection.currentUsage
          .sectionString,
      form,
      values,
      inquiryType: InquiryType.profiMittweida
    });

    return [normalFieldsValid, arrayFieldsValid].every(Boolean);
  }

  return validateFields({ fields: [usageDeviation], form, values,
    inquiryType: InquiryType.profiMittweida });
};

const validateRentalData: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  if (isPartitionOrStockholder(values)) {
    return validateFields({
      fields: [
        currentTotalRentResidential,
        currentTotalRentCommercial,
        vacancyResidential,
        vacancyCommercial,
        futureTotalRentResidential,
        futureTotalRentCommercial,
        rentalContracts,
        rentalIntends,
      ],
      form,
      values,
      inquiryType: InquiryType.profiMittweida
    });
  }

  return true;
};

export const validateObjectInformationSection: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values, store) => {
  const isGeneralDataValid = validateGeneralData(form, values, store);
  const isQualityDataValid = validateQualityData(form, values, store);
  const isParkingDataValid = validateParkingData(form, values, store);
  const isFutureObjectUsageValid = validateFutureObjectUsage(form, values, store);
  const isCurrentObjectUsageValid = validateCurrentObjectUsage(form, values, store);
  const isRentalDataValid = validateRentalData(form, values, store);

  const toValidate: Array<boolean | ((value: any, values: MittweidaFieldTypes) => boolean)> = [
    isGeneralDataValid,
    isQualityDataValid,
    isParkingDataValid,
    isFutureObjectUsageValid,
    isCurrentObjectUsageValid,
    isRentalDataValid,
  ];

  return toValidate.every(Boolean);
};

export const objectInformationSectionValidationMap: ValidationMapType = {
  [address]: fieldValidators.string().max(InquiryConstants.MAX_OBJECT_ADDRESS_LENGTH).required(),
  [zipCode]: fieldValidators
    .postalCode()
    .isValid()
    .required()
    .max(InquiryConstants.MAX_POSTAL_LENGTH),
  [city]: fieldValidators.string().required().max(InquiryConstants.MAX_OBJECT_CITY_LENGTH),
  [description]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
  [lotSize]: fieldValidators.number().required().min(MIN_LOT_SIZE).max(MAX_AMOUNT_VALUE),
  [year]: fieldValidators
    .yearFormat()
    .required()
    .minYear(InquiryConstants.MITTWEIDA_MIN_BUILDING_YEAR)
    .maxYear(InquiryConstants.MAX_BUILDING_YEAR),
  [yearOfModernization]: fieldValidators
    .yearFormat()
    .optional()
    .minYear(InquiryConstants.MIN_BUILDING_YEAR)
    .maxYear(InquiryConstants.MAX_BUILDING_YEAR),
  [plannedProjectStart]: fieldValidators
    .date()
    .optional()
    .min(InquiryConstants.MIN_PLANNED_DATE)
    .max(InquiryConstants.MAX_PLANNED_DATE),
  [plannedProjectEnd]: fieldValidators
    .date()
    .optional()
    .min(InquiryConstants.MIN_PLANNED_DATE)
    .max(InquiryConstants.MAX_PLANNED_DATE),
  [furnishingQuality]: fieldValidators.string().required(),
  [qualityCondition]: fieldValidators.string().required(),
  [additionalBathroom]: fieldValidators.boolean().required(),
  [balcony]: fieldValidators.boolean().required(),
  [lift]: fieldValidators.boolean().required(),
  [cellar]: fieldValidators.boolean().required(),
  [parkingLotsCount]: fieldValidators
    .number()
    .required()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOTS),
  [parkingLotPrice]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [parkingLotRentNow]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [parkingLotRentFuture]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [garagesCount]: fieldValidators.number().required().min(0).max(InquiryConstants.MAX_PARKING_LOTS),
  [garagesPrice]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [garageRentNow]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [garageRentFuture]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [futureObjectType]: fieldValidators.string().required(),
  [typeOfUse]: fieldValidators.string().required(),
  [usageSpace]: fieldValidators.number().required().min(0).max(InquiryConstants.MAX_USAGE_SPACE),
  [plannedSalesPrice]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_SALES_PRICE),
  [numberOfUnits]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_NUMBER_OF_UNITS),
  [usageDeviation]: fieldValidators.boolean().required(),
  [currentObjectType]: fieldValidators.string().required(),
  [currentTypeOfUse]: fieldValidators.string().required(),
  [currentUsageSpace]: fieldValidators
    .number()
    .required()
    .min(0)
    .max(InquiryConstants.MAX_USAGE_SPACE),
  [currentNumberOfUnits]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_NUMBER_OF_UNITS),
  [currentTotalRentResidential]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [currentTotalRentCommercial]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [vacancyResidential]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [vacancyCommercial]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [futureTotalRentResidential]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [futureTotalRentCommercial]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [rentalContracts]: fieldValidators.boolean().required(),
  [rentalIntends]: fieldValidators.boolean().required(),
};

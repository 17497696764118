import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import mapFinancingCriteriaToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapFinancingCriteriaToEdit';
import { FINANCING_CRITERIA_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { FILTERS_FINANCING_ROLE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import FinancingRoleSelect from 'pages/operationPortal/PlanningEvaluation/SharedComponent/FinancingRoleSelect';
import ProjectCostingHeader from 'pages/operationPortal/PlanningEvaluation/SharedComponent/ProjectCostingHeader';
import UsageTypeRadio from 'pages/operationPortal/PlanningEvaluation/SharedComponent/UsageTypeRadio';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import FinancingCriteriaPropertyDeveloper from './FinancingCriteriaPropertyDeveloper';
import FinancingCriteriaPropertyPartition from './FinancingCriteriaPropertyPartition';
import FinancingCriteriaPropertyStockholder from './FinancingCriteriaPropertyStockholder';
import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';
import { getFinanacialCriteriaTopBoxes, getFinancialCriteriaData } from '../../store/selectors';

const FinancingRole = () => {
  const filterData = useSelector(getMarketDataFilterLegacy);

  switch (filterData?.financingRole) {
    case FILTERS_FINANCING_ROLE.DEVELOPER:
      return <FinancingCriteriaPropertyDeveloper />;
    case FILTERS_FINANCING_ROLE.STOCKHOLDER:
      return <FinancingCriteriaPropertyStockholder />;
    case FILTERS_FINANCING_ROLE.PARTITION:
      return <FinancingCriteriaPropertyPartition />;
    default:
      throw new Error('Unknown financing role');
  }
};

export interface FinancingCriteriaTopBoxValues {
  customerGrade: string;
  customerType: string;
  locationGrade: string;
  projectGrade: string;
}

const FinancingCriteria = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.financingCriteria');
  const values = useSelector(getFinancialCriteriaData);
  const topBoxValues: FinancingCriteriaTopBoxValues = useSelector(getFinanacialCriteriaTopBoxes);
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { editFinancingCriteriaDetails } = paths.operation.inquiryDetails.planningEvaluation;

  const { onEdit } = useEditFormConfig({
    pathToEdit: editFinancingCriteriaDetails(inquiryId),
    recordType: FINANCING_CRITERIA_RECORD_TYPE,
    loadData: loadPlanningEvaluationProfitabilityData,
    editFormStructure: mapFinancingCriteriaToEdit(),
  });

  if (values === undefined) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Box>
      <ProjectCostingHeader heading={t('title')} />
      <Flex mb={10} align={'center'}>
        <UsageTypeRadio disableCurrentUsage={false} />
        <FinancingRoleSelect />
      </Flex>
      <SummaryCards onEdit={onEdit}>
        <SummaryCard
          caption={t('summaryCards.customerType')}
          value={topBoxValues.customerType === 'new_customer' ? 'Neukunde' : 'Bestandskunde'}
        />
        <SummaryCard
          caption={t('summaryCards.customerRating')}
          value={topBoxValues.customerGrade}
        />
        <SummaryCard
          caption={t('summaryCards.locationClassification')}
          value={topBoxValues.locationGrade}
        />
        <SummaryCard
          caption={t('summaryCards.projectClassification')}
          value={topBoxValues.projectGrade || 1}
        />
      </SummaryCards>
      <FinancingRole />
    </Box>
  );
};

export default FinancingCriteria;

import React from 'react';

import { Divider, Stack, Text, VStack } from '@chakra-ui/react';

import Condition from 'components/Condition';
import type { UploadableFileTypes } from 'models/File.model';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FileList } from './components/FileList';
import { LoadingComponent } from './components/LoadingComponent';
import { useMultipleFiles } from '../hooks/useMultipleFiles';

interface MultipleFilesBlockProps {
  title: string;
  description?: string;
  files?: Array<UploadableFileTypes>;
  fileRequestId?: string;
  maxDescriptionLength?: number;
  showFileDownloadStatusBadge?: boolean;
  isFileUploadingEnabled?: boolean;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
}

export const MultipleFilesBlock = ({
  title,
  description,
  files = [],
  fileRequestId,
  maxDescriptionLength = 100,
  showFileDownloadStatusBadge,
  isFileUploadingEnabled,
  isPrivateFile,
  isAssessmentFile,
}: MultipleFilesBlockProps) => {
  const t = useTranslations();

  const [showPreview, setShowPreview] = React.useState(true);

  const isDescriptionTooLong = description ? description?.length > maxDescriptionLength : false;

  const previewText = isDescriptionTooLong
    ? `${description?.slice(0, maxDescriptionLength)}...`
    : description;

  const { getRootProps, getInputProps, open, isUploading } = useMultipleFiles({
    fileRequestId,
    isPrivateFile,
    isAssessmentFile,
  });

  return (
    <>
      <VStack
        padding={8}
        spacing={4}
        marginBottom={8}
        backgroundColor="white"
        boxShadow="lg"
        borderRadius="lg"
        width="full"
        alignItems="normal"
      >
        <Stack direction={{ base: 'column', md: 'row' }} {...getRootProps()}>
          <VStack alignItems="start" flexGrow="1">
            <HeadingComponent variant="secondary" color="brand.default" fontWeight="semibold">
              {title}
            </HeadingComponent>
            <Condition condition={Boolean(previewText)}>
              <Text color="text.secondary">{showPreview ? previewText : description}</Text>
            </Condition>
            <input {...getInputProps()} />
            <Condition condition={showPreview && isDescriptionTooLong}>
              <ButtonComponent
                variant="link"
                color="brand.default"
                onClick={() => setShowPreview(false)}
              >
                {t('other.readMore')}
              </ButtonComponent>
            </Condition>
          </VStack>
          <Condition condition={Boolean(isFileUploadingEnabled)}>
            <LoadingComponent isLoading={isUploading}>
              <ButtonComponent
                onClick={open}
                variant="link"
                color="brand.default"
                leftIcon={<ArrowRightIcon />}
                flexShrink="0"
              >
                {t('components.upload.uploadFurtherDocuments')}
              </ButtonComponent>
            </LoadingComponent>
          </Condition>
        </Stack>
        <Condition condition={Boolean(files.length)}>
          <>
            <Divider borderColor="border.darkGrey" />
            <FileList
              isAssessmentFile={isAssessmentFile}
              files={files}
              fileRequestId={fileRequestId}
              showFileDownloadStatusBadge={showFileDownloadStatusBadge}
              isPrivateFile={isPrivateFile}
            />
          </>
        </Condition>
      </VStack>
    </>
  );
};

export default MultipleFilesBlock;

import * as React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { PROJECT_FINANCING_MARKET_TRIGGER_CONDITION } from 'modules/Inquiry/Form/formFields';
import {
  getInquiryDetailsSelector,
  getInquiryFormTypeSelector,
  getInquiryIdSelector,
} from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getPropertyDetails } from '../../../../../mittweida/portals/operations/store/selectors';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
}

const useMittweidaButtonCondition = () => {
  const propertyDetails = useSelector(getPropertyDetails);
  const minPercentageOfResidentialSpace = 0.67;

  const futureResidentialSpace = propertyDetails?.future.residential_space;
  const futureTotalSpace = propertyDetails?.future.total_space;
  const currentResidentialSpace = propertyDetails?.current.residential_space;
  const currentTotalSpace = propertyDetails?.current.total_space;

  return (
    currentResidentialSpace / currentTotalSpace >= minPercentageOfResidentialSpace ||
    futureResidentialSpace / futureTotalSpace >= minPercentageOfResidentialSpace
  );
};
export const TriggerMarketResearchStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: Props) => {
  const history = useHistory();
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.triggerMarketResearch');
  const inquiryId = useSelector(getInquiryIdSelector);
  const path = paths.operation.inquiryDetails.marketInformationDetails.replace(':id', inquiryId!);
  const redirectToMarketInformation = () => history.push(path);
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const inquiryType = useSelector(getInquiryFormTypeSelector);
  const isMittweidaInquiry = inquiryType === 'profi_mittweida';
  const mittweidaIsButtonActive = { isButtonActive: useMittweidaButtonCondition() };

  const { isButtonActive } = isMittweidaInquiry
    ? mittweidaIsButtonActive
    : inquiryDetails.financingPurposeFields[PROJECT_FINANCING_MARKET_TRIGGER_CONDITION];

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={3}>{t('description')}</TextComponent>
          <ButtonComponent
            leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
            variant="primary"
            onClick={redirectToMarketInformation}
            disabled={!isButtonActive}
          >
            {t('action')}
          </ButtonComponent>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};

import React, { createContext } from 'react';

import { mittweidaPortalConfig } from 'mittweida/portals/data';
import { portalConfig as mmvPortalConfig } from 'mmv/portals/data';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { AnyPortalConfig } from 'new/portals/state/portalConfigContext/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

export const PortalConfigContext = createContext<
  { portalConfig: AnyPortalConfig | undefined } | undefined
>(undefined);

type PortalConfigProviderProps = {
  children: React.ReactNode;
};

export function PortalConfigProvider({ children }: PortalConfigProviderProps) {
  const portalConfig: AnyPortalConfig | undefined = useSelectedInquiryTypeSpecificValue({
    [InquiryType.profiMittweida]: mittweidaPortalConfig,
    [InquiryType.mmv]: mmvPortalConfig,
    default: undefined,
  });

  return (
    <PortalConfigContext.Provider value={{ portalConfig }}>{children}</PortalConfigContext.Provider>
  );
}

import React, { useMemo } from 'react';

import { Box } from '@chakra-ui/react';

import PortalTable from 'components/PortalTable/PortalTable';
import { DEFAULT_CURRENCY } from 'constants/globalConstants';
import { RLLProfitabilityRealEstateExpert } from 'models/types/Profitability.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translateRow = (name: string) => t(`pages.planningEvaluation.appraiser.table.columns.${name}`)

  return [
    {
      key: 'name',
      text: '',
    },
    {
      key: 'unit',
      text: translateRow('unit'),
    },
    {
      key: 'values',
      text: translateRow('values'),
    }
  ]
}

const getData = (appraiser: RLLProfitabilityRealEstateExpert, t: ReturnType<typeof useTranslations>) => {
  const translateRow = (name: string) => t(`pages.planningEvaluation.appraiser.table.rows.${name}`)
  const translateUnit = (name: string) => `${DEFAULT_CURRENCY} / ${t(`other.${name}`)}`

  return [
    {
      name: translateRow('market_value_per_sqm'),
      unit: translateUnit('m2'),
      values: formatDecimal(appraiser.marketValuePerSqm),
    },
    {
      name: translateRow('market_value_parking_lot'),
      unit: translateUnit('perPiece'),
      values: formatDecimal(appraiser.marketValueParkingLot),
    },
    {
      name: translateRow('commercial_rent_per_sqm'),
      unit: translateUnit('m2'),
      values: formatDecimal(appraiser.commercialRentPerSqm),
    },
    {
      name: translateRow('rent_parking_lot'),
      unit: translateUnit('perPiece'),
      values: formatDecimal(appraiser.rentParkingLot),
    },
    {
      name: translateRow('market_value_garage'),
      unit: translateUnit('perPiece'),
      values: formatDecimal(appraiser.marketValueGarage),
    },
    {
      name: translateRow('residential_rent_per_sqm'),
      unit: translateUnit('m2'),
      values: formatDecimal(appraiser.residentialRentPerSqm),
    },
    {
      name: translateRow('rent_garage'),
      unit: translateUnit('perPiece'),
      values: formatDecimal(appraiser.rentGarage),
    },
  ]
}

interface AppraiserTableProps {
  appraiser: RLLProfitabilityRealEstateExpert
}

const AppraiserTable: React.FC<AppraiserTableProps> = ({ appraiser }) => {
  const t = useTranslations();
  const headers = useMemo(() => getHeaders(t), [t])
  const data = useMemo(() => getData(appraiser, t), [appraiser, t])

  return (
    <Box>
      <PortalTable
        onRowClick={() => {}}
        onSortChange={() => {}}
        sortBy="name"
        isLoading={false}
        isInitialized={true}
        headers={headers}
        tableData={data}
      />
    </Box>
  );
};

export default AppraiserTable

import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import FileDownloadBlock from 'components/UploadBlock/NewFileRequestBlock/FileDownloadBlock';
import { IInternalFile } from 'models/File.model';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import { getInternalFiles } from 'store/documentExchange/documentExchange.selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const FilesInternal: React.FC = () => {
  const internalFiles: IInternalFile[] = useSelector(getInternalFiles);

  const internalFilesWithHttps = internalFiles.map((file) => {
    file.downloadUrl = file.downloadUrl.replace('http://', 'https://');
    return file;
  });

  const t = useTranslations('pages.inquiryDetails.documentExchange');

  return (
    <section>
      <Box display="flex" alignItems="center" justifyContent="space-between" w="100%" mb="2rem">
        <FileSectionTitle mb={0}>{t('filesInternal')}</FileSectionTitle>
      </Box>

      {internalFilesWithHttps.map((file) => (
        <FileDownloadBlock file={file} />
      ))}
    </section>
  );
};

export default FilesInternal;

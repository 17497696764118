import React from 'react';

import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { InputWithField } from 'components/Input';
import { StyledHeading, FieldWrapper } from 'pages/RequestPasswordReset/styles';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { combineValidators, required, isEmail } from 'utils/validators';

const Form = ({ onSubmit, formatMessage, isPending }) => {
  const submitLabel = formatMessage({
    id: 'pages.requestPasswordReset.submitLabel',
  });
  return (
    <>
      <StyledHeading as="h3">
        {formatMessage({
          id: 'pages.requestPasswordReset.subheading',
        })}
      </StyledHeading>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FieldWrapper>
              <InputWithField
                name="email"
                validate={combineValidators(
                  required(formatMessage({ id: 'errors.required' })),
                  isEmail(formatMessage({ id: 'pages.requestPasswordReset.fields.email.error' })),
                )}
                caption={formatMessage({
                  id: 'pages.requestPasswordReset.fields.email.caption',
                })}
              />
            </FieldWrapper>

            <ButtonComponent
              data-testid="request-password-reset-button"
              disabled={isPending}
              isLoading={isPending}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              type="submit"
            >
              {submitLabel}
            </ButtonComponent>
          </form>
        )}
      />
    </>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default Form;

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  FINANCING_AMOUNT,
  FORM_TYPE,
  OFFER_FACTORING_LINE,
  PRODUCT_KIND,
  PURPOSE_KIND,
  STATUS,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryValueParserForDisplay } from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { VOLUME_OF_SALES } from 'onlinefactoring/formFields';
import { getIdShortcut } from 'pages/operationPortal/InquiryDetails/Dashboard/Summary/InquiryId';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import {
  getIndicativeConditionCompleted,
  getInquiryDetailsSelector,
  getInquiryLane,
} from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPriceForFactoringLine } from 'utils/valueFormats';

import { getIndicativeOffer } from './../../../../store/inquiryDetails/selectors';
import { mittweidaFields } from '../../../../mittweida/inquiry/fields';
import { getNewInquiryDetails } from '../../../../store/newInquiryDetails/selector';

const formatDate = (date: Date | string | null) =>
  date ? new Date(date).toLocaleString('ru-RU') : '';

const useSummaryCardAmount = () => {
  const t = useTranslations();
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const indicativeConditions = useSelector(getIndicativeOffer) as Record<string, string>;
  const indicativeOfferCompleted = useSelector(getIndicativeConditionCompleted);
  const bfssAmount = indicativeOfferCompleted
    ? formatPriceForFactoringLine(
        _get(indicativeConditions, [OFFER_FACTORING_LINE])?.replace(/\s/g, ''),
      )
    : t('pages.inquiryDetails.dashboard.actions.indicativeOffer.comingSoon');

  const amount = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: bfssAmount,
    default: _get(inquiryDetails, [INQUIRY_SECTIONS.ADDITIONAL_FIELDS, VOLUME_OF_SALES]),
  });
  return amount;
};

const useProductLabel = () => {
  const t = useTranslations();
  const valueTranslator = useInquiryValueParserForDisplay();
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const label = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: t(
      'pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringLine',
    ),
    default: valueTranslator(
      _get(inquiryDetails, [INQUIRY_SECTIONS.DETAILS, PRODUCT_KIND]),
      PRODUCT_KIND,
    ),
  });
  return label;
};

export const useSummaryCardData = () => {
  const t = useTranslations();
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const newInquiryDetails = useSelector(getNewInquiryDetails);
  const valueTranslator = useInquiryValueParserForDisplay();
  const { selectedInquiryType } = useFormConfig();
  const isOnlineFactoring = selectedInquiryType === InquiryType.onlinefactoring;
  const lane = useSelector(getInquiryLane);

  const createdAtRaw = _get(inquiryDetails, 'createdAt') || '';
  const diffTime = new Date().getTime() - new Date(inquiryDetails?.createdAt ?? '').getTime();

  const daysSince = Math.round(diffTime / (1000 * 3600 * 24));
  const hoursSince = Math.round(diffTime / (1000 * 3600));
  const sinceString =
    daysSince !== 0 ? `${daysSince} ${t('other.days')}` : `${hoursSince} ${t('other.hours')}`;

  const amountValue = useSummaryCardAmount();
  const onlineFactoringAmount = amountValue ? valueTranslator(amountValue, VOLUME_OF_SALES) : '';
  const finanacefinancingAmount =
    _get(inquiryDetails, [INQUIRY_SECTIONS.FINANCING, FINANCING_AMOUNT]) || null;
  const detailsFianncingAmount =
    _get(inquiryDetails, [INQUIRY_SECTIONS.DETAILS, FINANCING_AMOUNT]) || null;
  const defaultAmount = valueTranslator(
    !finanacefinancingAmount ? detailsFianncingAmount : finanacefinancingAmount,
    FINANCING_AMOUNT,
  );
  const amount = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: onlineFactoringAmount,
    default: isOnlineFactoring ? onlineFactoringAmount : defaultAmount,
  });
  const purposeKind = _get(inquiryDetails, [INQUIRY_SECTIONS.FINANCING, PURPOSE_KIND]);
  const purpose = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: t(
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.factomat.title',
    ),
    default: valueTranslator(purposeKind, PURPOSE_KIND),
  });
  const product = useProductLabel();

  const status = valueTranslator(inquiryDetails.status, STATUS);
  const formType = valueTranslator(inquiryDetails.formType, FORM_TYPE);

  let role = '';
  if (newInquiryDetails?.financingNeedPage?.roleSection?.projectFinancingRole) {
    role = valueTranslator(
      newInquiryDetails.financingNeedPage.roleSection.projectFinancingRole,
      mittweidaFields.financingNeedPage.roleSection.projectFinancingRole,
    );
  }

  const createdAt = formatDate(createdAtRaw);
  const id = getIdShortcut(_get(inquiryDetails, 'inquiryId'));
  const companyName = _get(inquiryDetails, 'company.name');
  return {
    amount,
    product,
    role,
    purpose,
    status,
    createdAt,
    createdAtSince: sinceString,
    id,
    companyName,
    lane,
    formType,
  };
};

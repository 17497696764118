import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

import { mmvFields } from '../../../../fields';

export const financingObjectValidation: ValidateSectionFunction<InquiryType.mmv> = (
  form,
  values,
) => {
  return validateFieldValidationMap({
    validationMap: mmvFinancingObjectValidationMap,
    form,
    values,
    inquiryType: InquiryType.mmv,
  });
};

export const mmvFinancingObjectValidationMap = {
  [mmvFields.contractDetails.financingObjectSection.serialNumber]: fieldValidators
    .number()
    .positive(),
};

import React from 'react';

import { useSelector } from 'react-redux';

import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { isLoggedInSelector } from 'store/user/selectors';

import { ContactPersonSection, LoggedInContactPersonSection } from './sections/contactPerson';
import { LoggedInPasswordSection, PasswordSection } from './sections/passwordSection';
import { TermsAndConditionsSection } from './sections/termsAndConditions';

const NotLoggedInUser = () => {
  return (
    <>
      <ContactPersonSection />
      <PasswordSection />
      <TermsAndConditionsSection />
    </>
  );
};

const LoggedInUser = () => {
  return (
    <>
      <LoggedInContactPersonSection />
      <LoggedInPasswordSection />
      <TermsAndConditionsSection />
    </>
  );
};

export const MittweidaUserProfileStep = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);

  return (
    <Page
      translationStringForTitle={
        translations.inquiryType.mittweida.pages.userDetails.progressBarTitle
      }
    >
      {isLoggedIn ? <LoggedInUser /> : <NotLoggedInUser />}
    </Page>
  );
};

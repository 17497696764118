import React from 'react';

import { ButtonComponent } from 'theme/components/Button';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IDownloadSignedContractInRequest {
  actionUrl: string;
}

const DownloadSignedContractInRequest = ({ actionUrl }: IDownloadSignedContractInRequest) => {
  const t = useTranslations('components.upload');

  return (
    <ButtonComponent
      as="a"
      href={actionUrl}
      target="_blank"
      leftIcon={<DownloadIcon boxSize={6} display="block" />}
      variant="primary"
    >
      {t('download')}
    </ButtonComponent>
  );
};

export default DownloadSignedContractInRequest;

import styled from 'styled-components/macro';

import { Text } from 'components/Text';
import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { mqMedium } from 'styles/breakpoints';

export const ForgotPasswordLink = styled(Text)`
  font-size: 1rem;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

export const InputsWrapper = styled.div`
  ${mqMedium`
    display: flex;
  `};

  > div {
    flex: 1 1 0%;

    &:first-child {
      margin-bottom: 3rem;

      ${mqMedium`
        margin-right: 4rem;
        margin-bottom: 0;
      `};
    }
  }
`;

export const StyledHeading = styled(Text)`
  margin-bottom: 2rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
`;

export const StyledRegisterInfo = styled.div`
  margin-top: 3rem;
  font-size: 1rem;

  > a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/Selects/Select/Select';
import { FINANCING_ROLE, SALE_TYPE } from 'modules/Inquiry/Form/formFields';
import { AppDispatch } from 'store';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FILTERS_FINANCING_ROLE } from './constants';
import {
  getMarketDataFilterLegacy,
  setMarketDataFilter as setMarketDataFilterAction,
} from './filterManagementReducer';

const FinancingRoleSelect = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.common.financingRole');
  const dispatch: AppDispatch = useDispatch();
  const filterData = useSelector(getMarketDataFilterLegacy);
  const [value, setValue] = useState(filterData.financingRole);
  const [label, setLabel] = useState(t(filterData.financingRole));

  useEffect(() => {
    switch (value) {
      case FILTERS_FINANCING_ROLE.DEVELOPER:
        setLabel(t('developer'));
        break;
      case FILTERS_FINANCING_ROLE.PARTITION:
        setLabel(t('partition'));
        break;
      case FILTERS_FINANCING_ROLE.STOCKHOLDER:
        setLabel(t('stockholder'));
        break;
      default:
        throw new Error('Unknown financing role');
    }
  }, [value, setValue, t]);

  return (
    <Select
      name={SALE_TYPE}
      isNotSearchable={true}
      width="30rem"
      options={[
        { value: FILTERS_FINANCING_ROLE.DEVELOPER, label: t('developer') },
        {
          value: FILTERS_FINANCING_ROLE.PARTITION,
          label: t('partition'),
        },
        { value: FILTERS_FINANCING_ROLE.STOCKHOLDER, label: t('stockholder') },
      ]}
      onChange={(value) => {
        setValue(value);
        dispatch(
          setMarketDataFilterAction({
            ...filterData,
            financingRole: value?.target?.value || value,
          }),
        );
      }}
      initial={FILTERS_FINANCING_ROLE.DEVELOPER}
      onBlur={() => null}
      onFocus={() => null}
      value={{ value, label }}
      data-testid={'select-field-' + FINANCING_ROLE}
    />
  );
};

export default FinancingRoleSelect;

import React from 'react';

import { Box } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import { useSelector } from 'react-redux';

import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import paths from 'constants/paths';
import mapBankValuesAdditionalInformationToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapBankValuesAdditionalInformationToEdit';
import { BANK_VALUES_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { BankValuesTableData } from './ObjectEvaluation';
import { FieldUnitType, unitFormatters } from '../../../../inquiry/steps/summary/units';
import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';
import { getFurtherObjectData } from '../../store/selectors';

export interface FurtherObjectEvaluationMappingProps {
  operating_costs: string;
  vacancy: string;
  remaining_usage_duration: string;
  presales_rate: string;
  visited_by: string;
  visited_date: string;
}

type ObjectDataKey = keyof FurtherObjectEvaluationMappingProps;

const orderArray = [
  'operating_costs',
  'vacancy',
  'remaining_usage_duration',
  'presales_rate',
  'visited_date',
  'visited_by',
];

const mappedUnitObject: Record<
  ObjectDataKey,
  {
    displayUnit: string;
    formattingUnit: FieldUnitType;
  }
> = {
  operating_costs: { displayUnit: '€', formattingUnit: 'currency' },
  vacancy: { displayUnit: 'Art', formattingUnit: 'none' },
  remaining_usage_duration: { displayUnit: 'Jahre', formattingUnit: 'days' },
  presales_rate: { displayUnit: '%', formattingUnit: 'percentage' },
  visited_date: { displayUnit: '', formattingUnit: 'date' },
  visited_by: { displayUnit: '', formattingUnit: 'none' },
};

const formatValue = (value: string, formatter: Function | undefined) => {
  if (formatter) {
    const formattedValue = formatter(value, false);
    if (formattedValue === 'Invalid date') {
      return '';
    }
    return formattedValue;
  }
  return value;
};

const useFurtherObjectDataMapping = (objectData: FurtherObjectEvaluationMappingProps) => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.bankValues');

  const mappedArray: Array<BankValuesTableData> = Object.keys(objectData).map((key) => {
    const formatter = unitFormatters[mappedUnitObject?.[key as ObjectDataKey]?.formattingUnit];
    return {
      id: key,
      label: t(`tables.furtherObjectDetails.rowNames.${key}`),
      value: formatValue(objectData[key as ObjectDataKey], formatter),
      unit: mappedUnitObject[key as ObjectDataKey]?.displayUnit,
    };
  });

  return orderArray.map((item) => {
    return mappedArray.find((object) => {
      if (object.id === item) {
        return object;
      }
      return null;
    });
  });
};

const FurtherObjectDetails = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.bankValues.tables.furtherObjectDetails',
  );
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { editBankValueAdditionalInformationDetails } =
    paths.operation.inquiryDetails.planningEvaluation;

  const { onEdit } = useEditFormConfig({
    pathToEdit: editBankValueAdditionalInformationDetails(inquiryId),
    recordType: BANK_VALUES_RECORD_TYPE.ADDITIONAL_INFORMATION,
    loadData: loadPlanningEvaluationProfitabilityData,
    editFormStructure: mapBankValuesAdditionalInformationToEdit(),
  });

  const furtherObjectData: {
    current: FurtherObjectEvaluationMappingProps;
    future: FurtherObjectEvaluationMappingProps;
  } = useSelector(getFurtherObjectData);
  const filterData = useSelector(getMarketDataFilterLegacy);
  const tableData = useFurtherObjectDataMapping(furtherObjectData?.[filterData?.usageType]);

  const translatedTableData = tableData
    .filter((obj): obj is BankValuesTableData => obj !== undefined)
    .map((obj: BankValuesTableData) => {
      const { id, value } = obj;
      if (id === 'visited_date') {
        return { ...obj, value: _isString(value) ? value.split('-').reverse().join('.') : value };
      }
      if (obj.id === 'vacancy') {
        return { ...obj, value: !_isEmpty(value) ? t(`vacancy.${value}`) : value };
      }
      return obj;
    });

  const furtherObjectTable = usePortalTable({
    data: translatedTableData,
    headers: [
      { key: 'label', text: '' },
      { key: 'value', text: 'Wert' },
      { key: 'unit', text: 'Einheit' },
    ],
  });

  return (
    <Box paddingTop={10}>
      <Card onEdit={onEdit} title={t('title')}>
        <PortalTable
          {...furtherObjectTable}
          columnStylesMap={{ value: { italic: true, rightAligned: true } }}
          headerStylesMap={{
            value: { width: '10%' },
            unit: { width: '10%' },
            label: { width: '80%' },
          }}
        />
      </Card>
    </Box>
  );
};

export default FurtherObjectDetails;

import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { IMessageEvent } from 'websocket';

import useLanguagePreferences from 'components/App/LanguageProvider/useLanguagePreferences';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { USER_SCOPE } from 'constants/userScopes';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import FilesToCompeon from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToCompeon/FilesToCompeon';
import AdditionalFilesFromCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/AdditionalFilesFromCustomer/AdditionalFilesFromCustomer';
import AssessmentFiles from 'pages/operationPortal/InquiryDetails/DocumentExchange/AssessmentFiles/AssessmentFiles';
import FilesForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/FilesForCustomer/FilesForCustomer';
import FilesInternal from 'pages/operationPortal/InquiryDetails/DocumentExchange/FilesInternal';
import PrivateFilesForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/PrivateFilesForCustomer/PrivateFilesForCustomer';
import RequestsForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/RequestsForCustomer';
import { useFetchInquiryFileRequests } from 'shared/documentExchange/useFetchInquiryFileRequests';
import {
  useFetchInquiryFiles,
  useFetchInquiryInternalFiles,
  useFetchPrivateFiles,
  useFetchAssessmentFiles,
  useFetchUpdatedFile,
  useFetchPrivateUpdatedFile,
} from 'shared/documentExchange/useFetchInquiryFiles';
import { useIsMittweidaInquiry } from 'shared/hooks/useIsMittweidaInquiry';
import { useIsProfiInquiry } from 'shared/hooks/useIsProfiInquiry';
import { useToasts } from 'shared/hooks/useToasts';
import { useWebSocket, Channels, WebSocketMessage } from 'shared/useWebsocket';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

const OperationDocumentExchange = () => {
  const isProfiInquiry = useIsProfiInquiry();
  const isMittweidaInquiry = useIsMittweidaInquiry();
  const inquiryId = useSelector(getInquiryIdSelector);
  const download = useFetchInquiryFiles({ isDownloadedBy: USER_SCOPE.OPERATION_PORTAL });
  const internalFiles = useFetchInquiryInternalFiles();
  const privateFiles = useFetchPrivateFiles();
  const assessmentFiles = useFetchAssessmentFiles();
  const { selectedLanguage } = useLanguagePreferences();
  const { isLoading, fetchData: fetchFileRequests } = useFetchInquiryFileRequests({
    isDownloadedBy: USER_SCOPE.OPERATION_PORTAL,
    selectedLanguage,
  });
  const { fetchUpdatedFile } = useFetchUpdatedFile();
  const { fetchPrivateUpdatedFile } = useFetchPrivateUpdatedFile();
  const { error } = useToasts();

  const handleWebsocketMessage = useCallback(
    (messageEvent: IMessageEvent) => {
      const data: WebSocketMessage = JSON.parse(messageEvent.data as string);
      try {
        switch (data.type) {
          case 'uploaded_files':
            fetchUpdatedFile(data.message.id);
            fetchFileRequests(inquiryId);
            return;
          case 'private_uploaded_files':
            fetchPrivateUpdatedFile(data.message.id);
            return;
          case 'archived_files':
            // TODO @ematala: adjust this to actual message type once backend is ready
            // EITHER fetch single updated file (regular or private file)
            // OR just fetch all files again (regular and private files)
            // AFTERWARDS refetch file requests
            // POSSIBLY also refetch inquiry details ?
            fetchFileRequests(inquiryId);
            return;
          default:
            return;
        }
      } catch (err) {
        error({});
      }
    },
    [fetchFileRequests, fetchUpdatedFile, inquiryId, fetchPrivateUpdatedFile, error],
  );

  useWebSocket({
    channel: Channels.UPLOADABLE_FILE,
    customProps: {
      inquiry_id: inquiryId,
    },
    onMessage: handleWebsocketMessage,
  });

  if (
    download.isLoading ||
    isLoading ||
    internalFiles.isLoading ||
    (isMittweidaInquiry && privateFiles.isLoading) ||
    (isProfiInquiry && assessmentFiles?.isLoading)
  ) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <article>
      <InquiryDetailsHeader />
      <RequestsForCustomer />
      <FilesInternal />
      <AdditionalFilesFromCustomer />
      <FilesForCustomer />
      <FilesToCompeon />
      {isMittweidaInquiry ? <PrivateFilesForCustomer /> : null}
      {isProfiInquiry ? <AssessmentFiles /> : null}
    </article>
  );
};

export default OperationDocumentExchange;

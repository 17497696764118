import React, { useCallback, useEffect, useState } from 'react';

import { Box, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  loadProfitabilityRealEstateExpert
} from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import { StyledSpinnerWrapper } from 'components/Spinner/styles';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import paths from 'constants/paths';
import { USER_SCOPE } from 'constants/userScopes';
import { getPlanningEvaluationProfitability } from 'mittweida/portals/operations/store/action';
import type { IFile } from 'models/File.model';
import type { ApiFile } from 'models/FileApi.model';
import { RLLProfitability } from 'models/types/Profitability.type';
import { EditStepWrapper } from 'modules/SummaryTable/EditStepButton/styles';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import { useFetchInquiryFiles } from 'shared/documentExchange/useFetchInquiryFiles';
import { addFileAction } from 'store/documentExchange/documentExchange.actions';
import { getAdditionalFilesSentByRealEstateExpert } from 'store/documentExchange/documentExchange.selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { isRealEstateExpert } from 'store/user/selectors';
import ButtonComponent from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { formatDateWithTime } from 'utils/date';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { deepCamelcaseKeys } from 'utils/object';

import AppraiserTable from './AppraiserTable';
import mapRealEstateValuesToEdit from '../EditMarketValuesReeProfitability/mapRealEstateValuesToEdit';
import { PROFI_RECORD_TYPE } from '../SharedComponent/constants';
import { getMarketDataFilter } from '../SharedComponent/filterManagementReducer';
import ProjectCostingFilters from '../SharedComponent/ProjectCostingFilters';
import ProjectCostingHeader from '../SharedComponent/ProjectCostingHeader';
import useEditFormConfig from '../useEditFormConfig';

export const Appraiser = () => {
  const { editMarketValueDetailsRee } = paths.operation.inquiryDetails.planningEvaluation;
  const download = useFetchInquiryFiles({ isDownloadedBy: USER_SCOPE.OPERATION_PORTAL });
  // FIXME
  // @ts-ignore
  const files: IFile[] = useSelector(getAdditionalFilesSentByRealEstateExpert)
  const { makeCall, isPending } = useDispatchApiCall({
    showErrorNotification: true,
    isPendingInitially: true,
  });
  const t = useTranslations('pages.planningEvaluation.appraiser')
  const tBase = useTranslations()

  const { saleType, usageType } = useSelector(getMarketDataFilter);
  const inquiryId = useSelector(getCreatedInquiryId) || '';
  const [profitability, setProfitability] = useState<RLLProfitability>()

  const isRealEstateExpertEnabled = useSelector(isRealEstateExpert);

  const uploadUrl = endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } });

  const dispatch = useDispatch();
  const addFileToList = (file: ApiFile) => dispatch(addFileAction(mapApiFile(file)));

  const fetchProfitability = useCallback(async () => {
    const { error, payload } = await makeCall(getPlanningEvaluationProfitability(inquiryId));
    if (!error) setProfitability(deepCamelcaseKeys(payload.data.data.attributes.details))
  }, [inquiryId, makeCall]);

  useEffect(() => {
    fetchProfitability()
  }, [fetchProfitability]);

  const { onEdit } = useEditFormConfig({
    pathToEdit: editMarketValueDetailsRee(inquiryId),
    recordType: PROFI_RECORD_TYPE.REE,
    loadData: loadProfitabilityRealEstateExpert,
    editFormStructure: mapRealEstateValuesToEdit(),
  });

  if (download.isLoading || isPending || !profitability) {
    return (
      <StyledSpinnerWrapper>
        <Spinner />
      </StyledSpinnerWrapper>
    );
  }
  return (
    <>
      <ProjectCostingHeader
        heading={t('pageTitle')}
        subheading={t('subheading')}
        subheadingDate={profitability.realEstateExpertUpdatedAt ? formatDateWithTime(profitability.realEstateExpertUpdatedAt) : '-'}
      />

      <ProjectCostingFilters />
      <AppraiserTable appraiser={profitability.realEstateExpert[usageType][saleType]} />

      <EditStepWrapper>
        <ButtonComponent
          leftIcon={<EditIcon boxSize={6} display="block" />}
          onClick={onEdit}
          variant="tertiary"
        >
          {tBase('buttons.edit')}
        </ButtonComponent>
      </EditStepWrapper>

      <Box boxShadow="xl" p="0">
        {files.length === 0 ? (
          <>
            {isRealEstateExpertEnabled && (
              <UploadAnyFile onUpload={addFileToList} url={uploadUrl} isReePage={true} />
            )}
          </>
        ) : (
          <UploadedFileBlock
            file={files[0]}
            canRemove
            isReeEnabled={isRealEstateExpertEnabled}
            isReePage={true}
          />
        )}
      </Box>
    </>
  );
};

import * as React from 'react';

import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import { usePortalConfig } from 'new/portals/state/portalConfigContext/hooks';
import {
  SummaryPageField,
  useBuildFieldsForSummaryPage,
} from 'new/summary/hooks/useBuildFieldsForSummary';
import { useTranslations } from 'utils/hooks/useTranslations';

import { GridWithDivider } from './GridWithDivider';

export const InquiryDetailsSummary = () => {
  const { portalConfig } = usePortalConfig();
  const t = useTranslations();
  const pagesWithFieldCaptionAndValue = useBuildFieldsForSummaryPage({
    summaryFields: portalConfig?.summaryFields,
    stepSequence: portalConfig?.stepSequence,
  });

  if (!portalConfig) {
    return null;
  }

  return (
    <>
      {Object.keys(pagesWithFieldCaptionAndValue).map((pageName) => {
        const fields: Array<SummaryPageField> = (pagesWithFieldCaptionAndValue as any)[pageName];
        return (
          <Section
            title={t((portalConfig.stepData as any)[pageName].progressBarTitle)}
            key={pageName}
          >
            <GridWithDivider fields={fields} />
          </Section>
        );
      })}
    </>
  );
};

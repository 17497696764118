import React from 'react';

import { ComputedMarketDataTopBoxes } from 'models/types/MarketData.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal, formatInteger } from 'utils/valueFormats';

import ProjectCostingBoxes from './ProjectCostingBoxes';

interface MarketDataTopBoxesProps {
  topBoxes: ComputedMarketDataTopBoxes
}

const MarketDataTopBoxes: React.FC<MarketDataTopBoxesProps> = ({ topBoxes }) => {
  const t = useTranslations('pages.planningEvaluation.marketComparison.clientBoxes');
  const tUnit = useTranslations('other')

  return (
    <ProjectCostingBoxes
      values={[
        {
          name: t('saleRevenue'),
          value: formatDecimal(topBoxes.potentialSalesRevenue, { unit: '€' }),
        },
        {
          name: t('rentalPerYear'),
          value: formatDecimal(topBoxes.possibleRentalIncomePerYear, { unit: '€' }),
        },
        {
          name: t('livingSpace'),
          value: formatInteger(topBoxes.livingSpace, { unit: tUnit('m2') }),
        },
        {
          name: t('commercialSpace'),
          value: formatInteger(topBoxes.commercialSpace, { unit: tUnit('m2') })
        },
      ]}
    />
  );
};

export default MarketDataTopBoxes

import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { NewStaticFieldWithField } from 'components/StaticField';
import { mmvFields } from 'mmv/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';
const { runtime, objectValue, downPayment, ratePerMonth, additionalInformation, remainingValue } =
  mmvFields.contractDetails.financingConditionsSection;

interface SFinancingConditionsSectionProps {
  sectionNumber?: number;
}
export const FinancingConditionsSection = (props: SFinancingConditionsSectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      title={t(
        translations.inquiryType.mmv.steps.contractDetails.sections.financingConditions.title,
      )}
      name="financingConditoions"
      {...props}
    >
      <FormRow>
        <NewStaticFieldWithField
          // caption={t(
          //   translations.inquiryType.mmv.steps.contractDetails.sections.financingConditions.runtime
          //     .caption,
          // )}
          name={runtime}
        />
        <NewStaticFieldWithField
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingConditions
              .objectValue.caption,
          )}
          name={objectValue}
        />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingConditions
              .downPayment.caption,
          )}
          name={downPayment}
        />
        <NewStaticFieldWithField
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingConditions
              .ratePerMonth.caption,
          )}
          name={ratePerMonth}
        />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingConditions
              .additionalInformation.caption,
          )}
          name={additionalInformation}
        />
        <NewStaticFieldWithField
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingConditions
              .remainingValue.caption,
          )}
          name={remainingValue}
        />
      </FormRow>
    </FormSection>
  );
};

import React from 'react';

import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';

import { ObjectInformationSection } from './sections/objectInformationSection';
import { OtherProjectInformationSection } from './sections/otherProjectInformationSection';
import { ProjectCompositionSection } from './sections/projectCompositionSection';
import { RoleSection } from './sections/roleSection';


export const MittweidaFinancingNeedStep = () => {
  return (
    <Page
      translationStringForTitle={
        translations.inquiryType.mittweida.pages.financingNeed.progressBarTitle
      }
    >
      <RoleSection />
      <ProjectCompositionSection />
      <ObjectInformationSection />
      <OtherProjectInformationSection />
    </Page>
  );
};

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

import { mmvFields } from '../../../../fields';

export const bankDetailsValidation: ValidateSectionFunction<InquiryType.mmv> = (form, values) => {
  return validateFieldValidationMap({
    validationMap: mmvBankDetailsSectionValidationMap,
    form,
    values,
    inquiryType: InquiryType.mmv,
  });
};

export const mmvBankDetailsSectionValidationMap = {
  [mmvFields.contractDetails.bankDetailsSection.iban]: fieldValidators
    .bank()
    .isValidIban()
    .isGermanIban()
    .required(),
};

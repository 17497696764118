import paths from 'constants/paths';
import { RouteToPageNameMap } from 'new/form/model';

import { FieldTypes } from './../fields';

export const routeToPageNameMap: RouteToPageNameMap<FieldTypes> = {
  contractDetails: {
    create: paths.contractDetails,
    editOperation: paths.operation.inquiryDetails.edit.customerData,
    editCustomer: paths.customer.inquiryDetails.edit.contractDetails,
  },
  customerDataPage: {
    create: paths.customerData,
    editOperation: paths.operation.inquiryDetails.edit.customerData,
    editCustomer: paths.customer.inquiryDetails.edit.customerData,
  },
  userProfilePage: {
    create: paths.userProfile,
    editOperation: paths.operation.inquiryDetails.edit.userProfile,
    editCustomer: paths.customer.inquiryDetails.edit.userProfile,
  },
};

import React, { FC, useState, createRef, useEffect } from 'react';

import { FieldWrapper } from 'components/ControlWrapper';
import { InputWithField } from 'components/Input';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { useFormConfig } from 'config/formConfig/hooks';
import { USER_PROFILE_PHONE_NUMBER_VERIFYER, SEND_PHONE_NUMBER, PHONE_PIN_INPUT } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';
import { required as requiredWithMessage } from 'utils/validators';

import { CardHeader, StyledLinkButton, PinEntryWrapper, PinEntryGroup, PinEntry } from './styles';
interface GetCodeNumberProps {
  onSubmit: any;
  phoneNumber: string;
  submitRetry: any;
  pending?: boolean;
}

export const GetCodeNumber: FC<GetCodeNumberProps> = ({
  onSubmit,
  phoneNumber,
  submitRetry,
  pending,
}: GetCodeNumberProps) => {
  const t = useTranslations();
  const [pin, setPin] = useState(false);
  const pinRef = createRef<HTMLInputElement[]>();
  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  useEffect(() => {
    // Focus first input
    if (pinRef.current) {
      pinRef.current[0].focus();
    }

    // Set pin (ready to check) if every input contain number
    if (pinRef.current && pinRef.current.every((v: HTMLInputElement) => v.value)) {
      setPin(true);
    } else {
      setPin(false);
    }
  }, [pin, pinRef]);

  const cleanCode = () => {
    if (pinRef.current) {
      pinRef.current.forEach((input: HTMLInputElement) => {
        input.value = ''; // eslint-disable-line no-param-reassign
      });
    }
  };

  const submitPin = (code: string) => {
    if (code.length === 6) {
      setPin(true);
      onSubmit(code);
    }
    cleanCode();
  };

  const retryPin = () => {
    cleanCode();
    submitRetry();
  };

  return (
    <>
      <CardHeader>
        {t('components.smsVerification.header2').replace('{phoneNumber}', phoneNumber)}
      </CardHeader>
      <MarkdownWrapper text={t('components.smsVerification.info2')} />
      <StyledLinkButton type="button" onClick={retryPin}>
        {t('components.smsVerification.fields.submitCodeRetry')}
      </StyledLinkButton>
      <FieldWrapper
        caption={t('components.smsVerification.fields.codeCaption')}
        tooltip={t('components.smsVerification.fields.codeTooltip')}
      >
        <PinEntryWrapper>
          <PinEntryGroup>
          <PinEntry ref={pinRef} length={6} validate={/^[0-9]$/} onComplete={submitPin} data-testid={PHONE_PIN_INPUT} />
          </PinEntryGroup>
          <ButtonComponent
            data-testid={SEND_PHONE_NUMBER}
            disabled={!pin}
            isLoading={pending}
            onClick={onSubmit}
            type="submit"
          >
            {t('components.smsVerification.fields.submitCondtion')}
          </ButtonComponent>
        </PinEntryWrapper>
        {isBfsService && (
          <InputWithField
            type="hidden"
            name={USER_PROFILE_PHONE_NUMBER_VERIFYER}
            validate={requiredWithMessage(t('errors.required'))}
          ></InputWithField>
        )}
      </FieldWrapper>
    </>
  );
};

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidateSectionFunction } from 'new/form/useValidateFields';
import { validateBeneficiaryOwnerSection } from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/BeneficiaryOwnersSection';

export const beneficialOwnerSectionValidations: ValidateSectionFunction<InquiryType.mmv> = (
  form,
  values,
  store,
) => {
  return validateBeneficiaryOwnerSection()(form);
};

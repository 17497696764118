import { InitialFormStateType } from 'new/form/model';

import { FieldTypes } from '../fields';

export const initialFormState: InitialFormStateType<FieldTypes> = {
  currentStep: 'contractDetails',
  inquiryMode: 'editCustomer',
  pageValidations: {
    contractDetails: {
      progress: { numberOfValidSections: 2, numberOfSections: 4, isSectionValid: false },
      validations: {
        dealerSection: true,
        financingObjectSection: false,
        financingConditionsSection: true,
        bankDetailsSection: false,
      },
    },
    customerDataPage: {
      progress: { numberOfValidSections: 2, numberOfSections: 4, isSectionValid: false },
      validations: {
        // These sections are static, so they're always valid
        companySection: true,
        contactPersonSection: true,
        authorizationOfRepresentationSection: false,
        beneficialOwnerSection: false,
      },
    },
    userProfilePage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        contactPersonSection: false,
        passwordSection: false,
        termsAndConditionsSection: false,
      },
    },
  },
};

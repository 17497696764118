import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Asset } from 'components/Asset';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  actionUrl: string;
  id?: string;
}

export const ResolveKycDataBankStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  actionUrl,
  id,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.resolveKycDataBank');
  const history = useHistory();

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={3}>{t('description')}</TextComponent>
          {actionUrl && !isCompleted && (
            <ButtonComponent
              onClick={() => history.push(actionUrl)}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="primary"
            >
              {t('action')}
            </ButtonComponent>
          )}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset htmlHeight="200" type="dashboard" value={ProgressSectionType.TRIGGER_KYC} />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};

import { rgba } from 'polished';
import styled from 'styled-components/macro';

import { FONT_WEIGHT_BOLD, FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqMedium } from 'styles/breakpoints';
import { transition, zIndex } from 'theme/themeConstants';

export const StyledTableWrapper = styled.div`
  position: relative;
  padding-bottom: 3.3rem;
`;

export const StyledTableScrollWrapper = styled.div`
  overflow: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const StyledTableHeaderCell = styled.th`
  padding: 0 1rem 2rem;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 1.25;
  text-align: left;
  color: ${(props) =>
    props.color === 'default' || !props.color
      ? props.theme.colors.primary
      : props.theme.colors.text[props.color]};
  cursor: pointer;
`;

export const StyledTableHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTableCell = styled.td<{ heading?: boolean; valueBold?: boolean }>`
  position: relative;
  z-index: ${zIndex.above};
  padding: ${(props) => (props.heading ? '2rem 1rem 2rem 1.0rem' : '2rem 1.5rem')};
  justify-content: ${(props) => (props.heading ? 'center' : '')};
  font-size: 1rem;
  font-weight: ${(props) => props.valueBold && FONT_WEIGHT_BOLD};
  line-height: 1.25;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.text.secondary};
  transition: color ${transition.default}, background-color ${transition.default};
`;

export const StyledTableRow = styled.tr<{ heading?: boolean }>`
  opacity: 0;
  position: relative;
  z-index: ${zIndex.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  transition: color ${transition.default}, background-color ${transition.default};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
  background-color: ${({ heading, theme }) => heading && theme.colors.background.lightGrey};
  &.entered {
    opacity: 1;
  }

  &:hover {
    ${StyledTableCell} {
      color: ${({ theme }) => theme.colors.text.primary};
      background-color: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
    }
  }
`;

export const StyledTableRowCell = styled(StyledTableCell)`
  height: 147px;

  &:first-child::after {
    display: none;
  }
`;

export const LoadingRow = styled(StyledTableRow)`
  opacity: 1;
  width: 100%;
  height: 90px;
  margin-bottom: 1rem;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background-color: ${(props) => props.theme.colors.background.lightGrey};
  /* stylelint-disable function-comma-newline-after, function-comma-space-after */
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.background.white} 2%,
    ${(props) => props.theme.colors.background.lightGrey} 18%,
    ${(props) => props.theme.colors.background.white} 33%
  );
  /* stylelint-enable function-comma-newline-after, function-comma-space-after */
  background-size: 1300px;

  td {
    margin-bottom: 1rem;
  }

  @keyframes placeholderAnimate {
    0% {
      background-position: -650px 0;
    }

    100% {
      background-position: 650px 0;
    }
  }
`;

export const StyledTableFooter = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;

  ${mqMedium`
    flex-direction: row;
  `}
`;

import React from 'react';

import { useSelector } from 'react-redux';

import loadEditProfitabilitySection from 'api/CompeonReverseApi/operation/loadEditProfitabilitySections/actions';
import paths from 'constants/paths';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatColumnValues } from 'utils/valueFormats';

import { useRoles } from './hooks';
import TableCardWithEdit from './TableCardWithEdit';
import { orderMapping } from './utils';
import mapProfitabilityToEdit from '../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import { PROFITABILITY_RECORD_TYPE } from '../../SharedComponent/constants';
import useEditFormConfig from '../../useEditFormConfig';
import {
  probabilitySaleAsBuildingTranslationMapping,
  probabilitySaleAsBuildingUnitMapping,
  PROFITABILITY_SALE_AS_BUILDING,
} from '../types';

interface IRecordTypeProps {
  profitabilityFormType: PROFITABILITY_RECORD_TYPE;
}

export type SaleType = {
  [key: string]: {
    sales_prices_according_to_customer: number;
    market_data_building_values: number;
    ree_opinion_in_presales: number;
  };
};
interface IProfitabilitySaleProps {
  data: SaleType;
}

type Props = IProfitabilitySaleProps & IRecordTypeProps;

const ProfitabilitySale: React.FC<Props> = ({ data, profitabilityFormType }) => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { salesCostRate, saleAsUnit } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const pathToEdit =
    profitabilityFormType === PROFITABILITY_RECORD_TYPE.SALES_COST_RATE
      ? salesCostRate(inquiryId)
      : saleAsUnit(inquiryId);
  const { onEdit } = useEditFormConfig({
    pathToEdit,
    recordType: profitabilityFormType,
    loadData: loadEditProfitabilitySection,
    editFormStructure: mapProfitabilityToEdit[profitabilityFormType](),
  });
  const { hasRoleBankAdvisorOrRealEstateExpert } = useRoles();
  const canEdit = hasRoleBankAdvisorOrRealEstateExpert;

  const t = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.profitabilitySale',
  );
  const unitTranslation = useTranslations();

  const headers = [
    {
      key: 'type',
      text: t('tableHeaders.type'),
    },
    {
      key: 'salesPriceAccordingToCustomer',
      text: t('tableHeaders.salesPriceAccordingToCustomer'),
    },
    {
      key: 'marketData',
      text: t('tableHeaders.marketData'),
    },
    {
      key: 'reeOpinionInclPresales',
      text: t('tableHeaders.reeOpinionInclPresales'),
    },
  ];

  const orderedData: SaleType = orderMapping<SaleType>(
    data,
    probabilitySaleAsBuildingTranslationMapping,
  );

  const highlightedRows = [
    `${PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SALES_REVENUE_TOTAL}`,
    `${PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SURPLUS_EURO}`,
    `${PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SURPLUS_PERCENT}`,
  ];

  const rows =
    orderedData &&
    Object.keys(orderedData).map((item: string) => ({
      type: t(`tableRows.${probabilitySaleAsBuildingTranslationMapping[item]}`),
      salesPriceAccordingToCustomer: formatColumnValues(
        orderedData[item].sales_prices_according_to_customer,
        probabilitySaleAsBuildingUnitMapping(unitTranslation)[item],
      ),
      marketData: formatColumnValues(
        orderedData[item].market_data_building_values,
        probabilitySaleAsBuildingUnitMapping(unitTranslation)[item],
      ),
      reeOpinionInclPresales: formatColumnValues(
        orderedData[item].ree_opinion_in_presales,
        probabilitySaleAsBuildingUnitMapping(unitTranslation)[item],
      ),
      ...(highlightedRows.includes(item) && { rowHighlight: true, valueBold: true }),
    }));

  return (
    <>
      <AccordionComponent headingLevel="h3" mb={8} title={t('title')} defaultIndex={[0]}>
        <TableCardWithEdit
          canEdit={canEdit}
          tableHeaders={headers}
          tableData={rows}
          onEdit={onEdit}
          componentName={profitabilityFormType}
        />
      </AccordionComponent>
    </>
  );
};

export default ProfitabilitySale;

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { PasswordInputWithField } from 'components/PasswordInput';
import { StyledActions } from 'components/StyledActions';
import { Text } from 'components/Text';
import { InputsWrapper } from 'modules/Login/styles';
import { resetPasswordAction } from 'pages/ChangePassword/service';
import intlShape from 'shared/intlShape';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { usePartnerSpecificValidators } from 'utils/hooks/usePartnerSpecificValidators';
import { withIntl } from 'utils/intl';
import { combineValidators, equalTo, required } from 'utils/validators';

export const PASSWORD_FIELD_NAME = 'password-input';
export const CONFIRM_PASSWORD_FIELD_NAME = 'password-confirm';

const Form = ({ intl: { formatMessage }, resetPasswordToken }) => {
  const [isPending, setIsPending] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const dispatch = useDispatch();
  const partnerSpecificValidators = usePartnerSpecificValidators();

  const t = (id) =>
    formatMessage({
      id: `pages.changePassword.${id}`,
    });
  const changePasswordLabel = t('submitLabel');

  async function handleFormSubmit(formFields) {
    setIsPending(true);

    const response = await dispatch(
      resetPasswordAction(
        formFields[PASSWORD_FIELD_NAME],
        formFields[CONFIRM_PASSWORD_FIELD_NAME],
        resetPasswordToken,
      ),
    );

    if (!response.error) {
      setIsPasswordChanged(true);
    } else {
      setIsPending(false);
    }
  }

  if (isPasswordChanged) {
    return (
      <Text data-testid="change-password-success-txt">
        {formatMessage({ id: 'pages.changePassword.submitSuccess' })}
      </Text>
    );
  }

  return (
    <FinalForm
      onSubmit={handleFormSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <InputsWrapper>
            <PasswordInputWithField
              name={PASSWORD_FIELD_NAME}
              validate={partnerSpecificValidators}
              caption={t('fields.password.caption')}
            />
            <PasswordInputWithField
              name={CONFIRM_PASSWORD_FIELD_NAME}
              validate={combineValidators(
                required(formatMessage({ id: 'errors.required' })),
                equalTo(PASSWORD_FIELD_NAME, t('fields.confirmPassword.error')),
              )}
              caption={t('fields.confirmPassword.caption')}
            />
          </InputsWrapper>
          <StyledActions>
            <ButtonComponent
              data-testid="change-password-button"
              disabled={isPending}
              isLoading={isPending}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              type="submit"
            >
              {changePasswordLabel}
            </ButtonComponent>
          </StyledActions>
        </form>
      )}
    />
  );
};

Form.propTypes = {
  intl: intlShape.isRequired,
  resetPasswordToken: PropTypes.string.isRequired,
};

export default withIntl(Form);

import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { getTranslationPath } from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/service';
import {
  StyledOverviewFields,
  Subtitle,
  BreakLine,
} from 'pages/operationPortal/CompaniesDetails/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

import CompanyOverviewContact from './CompanyOverviewContact';
import { getCompanyDetails } from '../store/selectors';

const CompanyOverviewSummary: React.FC = () => {
  const companyDetails = useSelector(getCompanyDetails);
  const t = useTranslations();

  const registerCourt =
    companyDetails.districtCourt && companyDetails.registerCity
      ? `${companyDetails.districtCourt}, ${companyDetails.registerCity}`
      : '-';

  return (
    <>
      <Subtitle>{t('pages.companiesDetails.overview.summary')}</Subtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t('pages.companiesDetails.overview.foundingDate')}
          text={companyDetails.foundingDate ?? '-'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.registerNumber')}
          text={companyDetails.registerNumber ?? '-'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.registerCourt')}
          text={registerCourt}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.legalForm')}
          text={companyDetails.legalForm ?? '-'}
        />

        <StaticField
          caption={t('pages.companiesDetails.overview.businessPurpose')}
          text={companyDetails.businessPurpose ?? '-'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.industry')}
          text={
            companyDetails.industry.length
              ? t(getTranslationPath(companyDetails.industry[0])) ?? '-'
              : '-'
          }
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.legalEntityIdentifier')}
          text={companyDetails.legalEntityIdentifier ?? '-'}
        />

        <StaticField
          caption={t('pages.companiesDetails.overview.taxNumber')}
          text={companyDetails.taxNumber ?? '-'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.taxVatNumber')}
          text={companyDetails.taxVatNumber ?? '-'}
        />
        {companyDetails.alreadyCustomer ? (
          <StaticField
            caption={t('pages.companiesDetails.overview.iban')}
            text={companyDetails.iban ?? '-'}
          />
        ) : null}
      </StyledOverviewFields>
      <BreakLine />
      <CompanyOverviewContact />
    </>
  );
};

export default CompanyOverviewSummary;

import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useFormState } from 'react-final-form';

import Page from 'components/Page/Page';
import PageTitle from 'components/PageTitle/PageTitle';
import { translations } from 'new/form/common/types';
import SummaryHeading from 'pages/inquiryFlow/SummaryPage/SummaryHeading/SummaryHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

import { SummaryActions } from './components/SummaryActions';
import { SummarySections } from './components/SummarySections';
import { SummaryStepProvider } from './Context';
import { MittweidaFieldTypes } from '../../fields';

export const MittweidaSummaryStep = () => {
  const t = useTranslations();
  const { values } = useFormState<MittweidaFieldTypes>();

  return (
    <Page hero={<SummaryHeading />}>
      <PageTitle title={t(translations.pages.inquiryDetails.detailed.summary.title)} />
      <VStack gap={4}>
        <SummaryStepProvider values={values}>
          <SummarySections />
        </SummaryStepProvider>
        <SummaryActions />
      </VStack>
    </Page>
  );
};

import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['container', 'actions', 'inputs']);

// FIXME: probably should extend less feature component like AuthForm
const LoginForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {},
    actions: {
      mt: 16,
      justifyContent: 'flex-end',
      display: 'flex',
    },
    inputs: {
      display: 'flex',
      gap: 16,
      flexDirection: ['column', null, 'row'],
    },
  },
  variants: {
    horizontal: {
      container: {
        margin: 'auto',
      },
      inputs: {
        flexDirection: 'column',
      },
      actions: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
      },
    },
    vertical: {},
  },
  defaultProps: {
    variant: 'vertical',
  },
});

export default LoginForm;

import React from 'react';

import { Box } from '@chakra-ui/react';

import { MonthPickerWithField } from 'components/DatePicker';
import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import Separator from 'components/FormRow/Separator';
import FormSection from 'components/FormSection';
import Slider from 'components/Slider';
import SectionHeading from 'components/Text/SectionHeading';
import Condition from 'modules/ConditionalSubform/Condition';
import {
  INVESTMENT_LOAN_TERM_IN_YEARS,
  FUNDING_CLOSING_DATE,
} from 'modules/Inquiry/Form/formFields';
import { productKindFieldConditions } from 'modules/Inquiry/Form/Validations/default/RequestDetails/bfsProductKindSection.validator';
import { FormSections } from 'schema/inquirySchema.models';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import InvestionsIcon from 'theme/components/Icon/ProductKind/InvestionsIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { futureMonths } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

interface BfsProductKindProps {
  maxYears: Number;
  productKind: string;
}

const BfsProductKindSection = (props: BfsProductKindProps) => {
  const loanSectionTranslation = useTranslations(`pages.requestDetails.sections.investmentLoan`);
  const closingSectionTranslation = useTranslations(`pages.requestDetails.sections.fundingClosing`);
  const loanInYearsValue = FormFieldController.instance.getValue(INVESTMENT_LOAN_TERM_IN_YEARS);
  return (
    <>
      <FormSection
        name={FormSections.investmentLoan}
        title={loanSectionTranslation(`title.${props.productKind}`)}
        sectionNumber={1}
      >
        <FormRow>
          <FinancingAmountSummary icon={<InvestionsIcon />} />
        </FormRow>
        <Condition condition={productKindFieldConditions()}>
          <Separator />
        </Condition>
        <Condition condition={productKindFieldConditions()}>
          <FormRow>
            <Box display="flex">
              <Slider
                name={INVESTMENT_LOAN_TERM_IN_YEARS}
                caption={loanSectionTranslation(`fields.noOfYears.caption.${props.productKind}`)}
                step={1}
                defaultValue={loanInYearsValue?.value ?? 5}
                max={props.maxYears}
                min={1}
              />
              <PopoverComponent
                ml={1}
                transform="translateY(-11px)"
                text={loanSectionTranslation('fields.noOfYears.tooltip')}
                trigger={
                  <IconButtonComponent
                    icon={<InfoIcon boxSize={6} display="block" />}
                    label={loanSectionTranslation('buttons.moreInfos')}
                  />
                }
              />
            </Box>
          </FormRow>
        </Condition>
      </FormSection>
      <FormSection
        name={FormSections.fundingClosingDate}
        title={
          closingSectionTranslation(`title.${props.productKind}`) ||
          closingSectionTranslation(`title.default`)
        }
        sectionNumber={2}
      >
        <SectionHeading>{closingSectionTranslation('fields.date.caption')}</SectionHeading>

        <FormRow>
          <MonthPickerWithField name={FUNDING_CLOSING_DATE} optional minDate={futureMonths} />
        </FormRow>
      </FormSection>
    </>
  );
};

export default BfsProductKindSection;

import * as React from 'react';

import { Flex } from '@chakra-ui/react';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { StepActions } from 'new/form/ChangeStep';
import { translations } from 'new/form/common/types';
import ButtonComponent from 'theme/components/Button';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { MMV_FORM } from '../../../index';

export const SummaryActions = () => {
  const t = useTranslations();
  const { submitting } = useFormState();
  const history = useHistory();

  return (
    <StepActions>
      <Flex w={'full'} justifyContent={'flex-end'} gap={4}>
        <ButtonComponent
          onClick={() => history.goBack()}
          leftIcon={<ArrowLeftIcon boxSize={6} />}
          variant={'tertiary'}
        >
          {t(translations.buttons.back)}
        </ButtonComponent>
        <ButtonComponent
          form={MMV_FORM}
          type={'submit'}
          leftIcon={<ArrowRightIcon boxSize={6} />}
          isLoading={submitting}
        >
          {t(translations.buttons.send)}
        </ButtonComponent>
      </Flex>
    </StepActions>
  );
};

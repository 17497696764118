import {
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_GENDER,
  USER_REPRESENTATION,
  USER_REPRESENTATIVE,
  USER_SHARED_REPRESENTATION,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_PHONE_NUMBER,
  REPRESENTATIVE_BIRTHDATE,
  HAS_BENEFICIARY_OWNER,
  IS_BENEFICIARY_OWNER,
  EXISTING_BENEFICIARY,
  BENEFICIARY_OWNER,
  BENEFICIARY_GENDER,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  SELF_BENEFICIARY_OWNER,
  BENEFICIARY_ZIP_CODE,
  BENEFICIARY_BIRTH_COUNTRY,
  NO_BENEFICIARY_CHECKBOX,
} from 'modules/Inquiry/Form/formFields';
import { concatenateKeys } from 'new/form/common/concatenateKeys';
import { getKeyObject, PropertiesToString, SecondLevelKeys } from 'new/form/common/types';


export type Gender = 'mr' | 'ms' | undefined;

// FIXME: after upgrading TypeScript to 5.0 - https://github.com/microsoft/TypeScript/pull/51865
const USER_REPRESENTATIVES_GENDER = `${USER_REPRESENTATIVE}.${REPRESENTATIVE_GENDER}` as const;
const USER_REPRESENTATIVES_FIRST_NAME =
  `${USER_REPRESENTATIVE}.${REPRESENTATIVE_FIRST_NAME}` as const;
const USER_REPRESENTATIVES_LAST_NAME =
  `${USER_REPRESENTATIVE}.${REPRESENTATIVE_LAST_NAME}` as const;
const USER_REPRESENTATIVES_EMAIL = `${USER_REPRESENTATIVE}.${REPRESENTATIVE_EMAIL}` as const;
const USER_REPRESENTATIVES_PHONE_NUMBER =
  `${USER_REPRESENTATIVE}.${REPRESENTATIVE_PHONE_NUMBER}` as const;
const USER_REPRESENTATIVES_BIRTHDATE =
  `${USER_REPRESENTATIVE}.${REPRESENTATIVE_BIRTHDATE}` as const;

const BENEFICIARY_OWNER_GENDER = `${BENEFICIARY_OWNER}.${BENEFICIARY_GENDER}` as const;
const BENEFICIARY_OWNER_FIRST_NAME = `${BENEFICIARY_OWNER}.${BENEFICIARY_FIRST_NAME}` as const;
const BENEFICIARY_OWNER_LAST_NAME = `${BENEFICIARY_OWNER}.${BENEFICIARY_LAST_NAME}` as const;
const BENEFICIARY_OWNER_TAX_ID = `${BENEFICIARY_OWNER}.${BENEFICIARY_TAX_ID}` as const;
const BENEFICIARY_OWNER_PRIVATE_ADDRESS =
  `${BENEFICIARY_OWNER}.${BENEFICIARY_PRIVATE_ADDRESS}` as const;
const BENEFICIARY_OWNER_PRIVATE_CITY = `${BENEFICIARY_OWNER}.${BENEFICIARY_PRIVATE_CITY}` as const;
const BENEFICIARY_OWNER_PRIVATE_COUNTRY =
  `${BENEFICIARY_OWNER}.${BENEFICIARY_PRIVATE_COUNTRY}` as const;
const BENEFICIARY_OWNER_BIRTHDATE = `${BENEFICIARY_OWNER}.${BENEFICIARY_BIRTHDAY}` as const;
const BENEFICIARY_OWNER_COMPANY_SHARE =
  `${BENEFICIARY_OWNER}.${BENEFICIARY_COMPANY_SHARE}` as const;
const BENEFICIARY_OWNER_ZIP_CODE = `${BENEFICIARY_OWNER}.${BENEFICIARY_ZIP_CODE}` as const;
const BENEFICIARY_OWNER_BIRTH_COUNTRY =
  `${BENEFICIARY_OWNER}.${BENEFICIARY_BIRTH_COUNTRY}` as const;

const SELF_BENEFICIARY_OWNER_GENDER = `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_GENDER}` as const;
const SELF_BENEFICIARY_OWNER_FIRST_NAME =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_FIRST_NAME}` as const;
const SELF_BENEFICIARY_OWNER_LAST_NAME =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_LAST_NAME}` as const;
const SELF_BENEFICIARY_OWNER_TAX_ID = `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_TAX_ID}` as const;
const SELF_BENEFICIARY_OWNER_PRIVATE_ADDRESS =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_PRIVATE_ADDRESS}` as const;
const SELF_BENEFICIARY_OWNER_PRIVATE_CITY =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_PRIVATE_CITY}` as const;
const SELF_BENEFICIARY_OWNER_PRIVATE_COUNTRY =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_PRIVATE_COUNTRY}` as const;
const SELF_BENEFICIARY_OWNER_BIRTHDATE =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_BIRTHDAY}` as const;
const SELF_BENEFICIARY_OWNER_COMPANY_SHARE =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_COMPANY_SHARE}` as const;
const SELF_BENEFICIARY_OWNER_ZIP_CODE =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_ZIP_CODE}` as const;
const SELF_BENEFICIARY_OWNER_BIRTH_COUNTRY =
  `${SELF_BENEFICIARY_OWNER}.${BENEFICIARY_BIRTH_COUNTRY}` as const;

interface BeneficiaryOwner {
  [BENEFICIARY_OWNER_GENDER]: Gender;
  [BENEFICIARY_OWNER_FIRST_NAME]: string | undefined;
  [BENEFICIARY_OWNER_LAST_NAME]: string | undefined;
  [BENEFICIARY_OWNER_TAX_ID]: string | undefined;
  [BENEFICIARY_OWNER_BIRTHDATE]: string | undefined;
  [BENEFICIARY_OWNER_COMPANY_SHARE]: string | undefined;
  [BENEFICIARY_OWNER_PRIVATE_ADDRESS]: string | undefined;
  [BENEFICIARY_OWNER_PRIVATE_CITY]: string | undefined;
  [BENEFICIARY_OWNER_PRIVATE_COUNTRY]: string | undefined;
  [BENEFICIARY_OWNER_ZIP_CODE]: string | undefined;
  [BENEFICIARY_OWNER_BIRTH_COUNTRY]: string | undefined;
}

/**
 * Includes all field types **along with fields from deprecated inquiry flow**.
 * For field types for new flow, see `FieldTypes`.
 * @see FieldTypes
 */
export type AllFieldTypes = {
  contractDetails: {
    dealerSection: {
      supplier: string | undefined;
      agent: string | undefined;
    };
    financingObjectSection: {
      objectAlreadyDelivered: boolean | undefined;
      objectDescription: string | undefined;
      state: string | undefined;
      serialNumber: number | undefined;
    };
    financingConditionsSection: {
      runtime: number | undefined;
      objectValue: string | undefined;
      downPayment: string | undefined;
      ratePerMonth: string | undefined;
      additionalInformation: string | undefined;
      remainingValue: string | undefined;
    };
    bankDetailsSection: {
      accountHolder: string | undefined;
      iban: string | undefined;
    };
  };
  [USER_REPRESENTATION]: boolean | undefined;
  [USER_SHARED_REPRESENTATION]: boolean | undefined;
  [USER_REPRESENTATIVE]: {
    [USER_REPRESENTATIVES_GENDER]: Gender;
    [USER_REPRESENTATIVES_FIRST_NAME]: string | undefined;
    [USER_REPRESENTATIVES_LAST_NAME]: string | undefined;
    [USER_REPRESENTATIVES_EMAIL]: string | undefined;
    [USER_REPRESENTATIVES_PHONE_NUMBER]: string | undefined;
    [USER_REPRESENTATIVES_BIRTHDATE]: string | undefined;
  }[];

  [HAS_BENEFICIARY_OWNER]: boolean | undefined;
  [IS_BENEFICIARY_OWNER]: boolean | undefined;
  [SELF_BENEFICIARY_OWNER]: BeneficiaryOwner[];
  [EXISTING_BENEFICIARY]: string | undefined;
  [NO_BENEFICIARY_CHECKBOX]: boolean | undefined;
  [BENEFICIARY_OWNER]: BeneficiaryOwner[];

  customerDataPage: {
    companySection: {
      companyName: string | undefined;
      address: string | undefined;
    };
    contactPersonSection: {
      title: string | undefined;
      firstName: string | undefined;
      lastName: string | undefined;
      email: string | undefined;
    };
    // empty sections
    authorizationOfRepresentationSection: {};
    beneficialOwnerSection: {};
  };
  userProfilePage: {
    contactPersonSection: {
      gender: Gender;
      firstName: string | undefined;
      lastName: string | undefined;
      email: string | undefined;
      phone: string | undefined;
    };
    passwordSection: {
      password: string | undefined;
      passwordConfirmation: string | undefined;
    };
    termsAndConditionsSection: {
      termsAndConditionsAccepted: boolean | undefined;
    };
  };
};
export type FieldTypes = Pick<
  AllFieldTypes,
  'contractDetails' | 'customerDataPage' | 'userProfilePage'
>;
type StringFieldTypes = PropertiesToString<FieldTypes>;

export const fieldsWithoutParents: StringFieldTypes = {
  contractDetails: {
    dealerSection: {
      supplier: 'supplier',
      agent: 'agent',
    },
    financingObjectSection: {
      objectAlreadyDelivered: 'objectAlreadyDelivered',
      objectDescription: 'objectDescription',
      state: 'state',
      serialNumber: 'serialNumber',
    },
    financingConditionsSection: {
      runtime: 'runtime',
      objectValue: 'objectValue',
      downPayment: 'downPayment',
      ratePerMonth: 'ratePerMonth',
      additionalInformation: 'additionalInformation',
      remainingValue: 'remainingValue',
    },
    bankDetailsSection: {
      accountHolder: 'accountHolder',
      iban: 'iban',
    },
  },
  customerDataPage: {
    companySection: {
      companyName: 'companyName',
      address: 'companyAddress',
    },
    contactPersonSection: {
      title: 'salutation',
      firstName: 'first_name',
      lastName: 'last_name',
      email: 'email',
    },
    authorizationOfRepresentationSection: {},
    beneficialOwnerSection: {},
  },
  userProfilePage: {
    contactPersonSection: {
      gender: 'gender',
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      phone: 'phone',
    },
    passwordSection: {
      password: 'password',
      passwordConfirmation: 'passwordConfirmation',
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: 'termsAndConditionsAccepted',
    },
  },
};

export const mmvFields = {
  [USER_REPRESENTATION]: USER_REPRESENTATION,
  [USER_SHARED_REPRESENTATION]: USER_SHARED_REPRESENTATION,
  [USER_REPRESENTATIVE]: USER_REPRESENTATIVE,
  [USER_REPRESENTATIVES_GENDER]: USER_REPRESENTATIVES_GENDER,
  [USER_REPRESENTATIVES_FIRST_NAME]: USER_REPRESENTATIVES_FIRST_NAME,
  [USER_REPRESENTATIVES_LAST_NAME]: USER_REPRESENTATIVES_LAST_NAME,
  [USER_REPRESENTATIVES_EMAIL]: USER_REPRESENTATIVES_EMAIL,
  [USER_REPRESENTATIVES_PHONE_NUMBER]: USER_REPRESENTATIVES_PHONE_NUMBER,
  [USER_REPRESENTATIVES_BIRTHDATE]: USER_REPRESENTATIVES_BIRTHDATE,
  [HAS_BENEFICIARY_OWNER]: HAS_BENEFICIARY_OWNER,
  [IS_BENEFICIARY_OWNER]: IS_BENEFICIARY_OWNER,
  [EXISTING_BENEFICIARY]: EXISTING_BENEFICIARY,
  [NO_BENEFICIARY_CHECKBOX]: NO_BENEFICIARY_CHECKBOX,
  [BENEFICIARY_OWNER]: BENEFICIARY_OWNER,
  [BENEFICIARY_OWNER_GENDER]: BENEFICIARY_OWNER_GENDER,
  [BENEFICIARY_OWNER_FIRST_NAME]: BENEFICIARY_OWNER_FIRST_NAME,
  [BENEFICIARY_OWNER_LAST_NAME]: BENEFICIARY_OWNER_LAST_NAME,
  [BENEFICIARY_OWNER_TAX_ID]: BENEFICIARY_OWNER_TAX_ID,
  [BENEFICIARY_OWNER_BIRTHDATE]: BENEFICIARY_OWNER_BIRTHDATE,
  [BENEFICIARY_OWNER_COMPANY_SHARE]: BENEFICIARY_OWNER_COMPANY_SHARE,
  [BENEFICIARY_OWNER_PRIVATE_ADDRESS]: BENEFICIARY_OWNER_PRIVATE_ADDRESS,
  [BENEFICIARY_OWNER_PRIVATE_CITY]: BENEFICIARY_OWNER_PRIVATE_CITY,
  [BENEFICIARY_OWNER_PRIVATE_COUNTRY]: BENEFICIARY_OWNER_PRIVATE_COUNTRY,
  [BENEFICIARY_OWNER_ZIP_CODE]: BENEFICIARY_OWNER_ZIP_CODE,
  [BENEFICIARY_OWNER_BIRTH_COUNTRY]: BENEFICIARY_OWNER_BIRTH_COUNTRY,
  [SELF_BENEFICIARY_OWNER]: SELF_BENEFICIARY_OWNER,
  [SELF_BENEFICIARY_OWNER_GENDER]: SELF_BENEFICIARY_OWNER_GENDER,
  [SELF_BENEFICIARY_OWNER_FIRST_NAME]: SELF_BENEFICIARY_OWNER_FIRST_NAME,
  [SELF_BENEFICIARY_OWNER_LAST_NAME]: SELF_BENEFICIARY_OWNER_LAST_NAME,
  [SELF_BENEFICIARY_OWNER_TAX_ID]: SELF_BENEFICIARY_OWNER_TAX_ID,
  [SELF_BENEFICIARY_OWNER_BIRTHDATE]: SELF_BENEFICIARY_OWNER_BIRTHDATE,
  [SELF_BENEFICIARY_OWNER_COMPANY_SHARE]: SELF_BENEFICIARY_OWNER_COMPANY_SHARE,
  [SELF_BENEFICIARY_OWNER_PRIVATE_ADDRESS]: SELF_BENEFICIARY_OWNER_PRIVATE_ADDRESS,
  [SELF_BENEFICIARY_OWNER_PRIVATE_CITY]: SELF_BENEFICIARY_OWNER_PRIVATE_CITY,
  [SELF_BENEFICIARY_OWNER_PRIVATE_COUNTRY]: SELF_BENEFICIARY_OWNER_PRIVATE_COUNTRY,
  [SELF_BENEFICIARY_OWNER_ZIP_CODE]: SELF_BENEFICIARY_OWNER_ZIP_CODE,
  [SELF_BENEFICIARY_OWNER_BIRTH_COUNTRY]: SELF_BENEFICIARY_OWNER_BIRTH_COUNTRY,
  ...(concatenateKeys(fieldsWithoutParents) as StringFieldTypes),
};

export const fieldsWithSectionString = getKeyObject<FieldTypes>(fieldsWithoutParents);

export type Steps = keyof FieldTypes;
export type Sections = SecondLevelKeys<typeof fieldsWithoutParents>;

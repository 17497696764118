import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidateSectionFunction } from 'new/form/useValidateFields';
import { validateLegalRepresentationSection } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentationSection';
import { getCompanyAssociatedPersonsSelector } from 'pages/operationPortal/CompaniesDetails/store/selectors';

export const authorizationOfRepresentationSectionValidations: ValidateSectionFunction<InquiryType.mmv> = (
  form,
  values,
  store,
) => {
  const associatedPeople = getCompanyAssociatedPersonsSelector(store.getState());
  return validateLegalRepresentationSection(associatedPeople, form)?.(form, InquiryType.mmv);
};

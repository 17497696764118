import {InquiryType} from "modules/Inquiry/Inquiry.type";
import {fieldValidators} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import {validateFieldValidationMap, ValidateSectionFunction,} from 'new/form/useValidateFields';

import {mittweidaFields} from '../../../../fields';

export const validateOtherFundingSection: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  return validateFieldValidationMap({
    validationMap: otherFundingSectionValidationMap,
    form,
    values,
    inquiryType: InquiryType.profiMittweida,
  });
};

export const otherFundingSectionValidationMap = {
  [mittweidaFields.fundingDetailsPage.otherFundingSection.description]: fieldValidators
    .string()
    .required()
    .max(5000),
};

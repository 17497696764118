import { ToMapFrontendToBackend } from 'new/form/common/types';
import { INQUIRY_VALUE_FORMATTERS_REFS } from 'new/form/formatters';

import { AllFieldTypes, mmvFields } from '../fields';

export const feToBeMappingKeys: ToMapFrontendToBackend<AllFieldTypes> = {
  contractDetails: {
    dealerSection: {
      supplier: 'supplier',
      agent: 'agent',
    },
    financingObjectSection: {
      objectAlreadyDelivered: 'object-already-delivered',
      objectDescription: 'object-description',
      state: 'state',
      serialNumber: 'serial-number',
    },
    financingConditionsSection: {
      runtime: 'runtime',
      objectValue: 'object-value',
      downPayment: 'down-payment',
      ratePerMonth: 'lease-rate',
      additionalInformation: 'maintenance',
      remainingValue: 'residual-value',
    },
    bankDetailsSection: {
      accountHolder: 'account-holder',
      iban: 'iban',
    },
  },
  [mmvFields['user-representation']]: 'user-representation',
  [mmvFields['user-shared-representation']]: 'user-shared-representation',
  [mmvFields['user-representative']]: {
    mappingKey: 'user-representative',
    keys: {
      [mmvFields['user-representative.representative-gender']]:
        'user-representative.representative-gender',
      [mmvFields['user-representative.representative-first-name']]:
        'user-representative.representative-first-name',
      [mmvFields['user-representative.representative-last-name']]:
        'user-representative.representative-last-name',
      [mmvFields['user-representative.representative-email']]:
        'user-representative.representative-email',
      [mmvFields['user-representative.representative-phone-number']]:
        'user-representative.representative-phone-number',
      [mmvFields['user-representative.representative-birthdate']]:
        'user-representative.representative-birthdate',
    },
  },
  [mmvFields['has-beneficiary-owner']]: 'beneficial_owner',
  [mmvFields['is-beneficiary-owner']]: 'wb_fictitious',
  [mmvFields['self-beneficiary-owner']]: {
    mappingKey: 'self-beneficiary-owner',
    keys: {
      [mmvFields['beneficiary-owner.beneficiary-first-name']]: 'beneficiary-first-name',
      [mmvFields['beneficiary-owner.beneficiary-last-name']]: 'beneficiary-last-name',
      [mmvFields['beneficiary-owner.beneficiary-gender']]: 'beneficiary-gender',
      [mmvFields['beneficiary-owner.beneficiary-tax-id']]: 'beneficiary-tax-id',
      [mmvFields['beneficiary-owner.beneficiary-birthday']]: 'beneficiary-birthday',
      [mmvFields['beneficiary-owner.beneficiary-company-share']]: 'beneficiary-company-share',
      [mmvFields['beneficiary-owner.beneficiary-private-city']]: 'beneficiary-private-city',
      [mmvFields['beneficiary-owner.beneficiary-private-address']]: 'beneficiary-private-address',
      [mmvFields['beneficiary-owner.beneficiary-private-country']]: 'beneficiary-private-country',
      [mmvFields['beneficiary-owner.beneficiary-zip-code']]: 'beneficiary-zip-code',
      [mmvFields['beneficiary-owner.beneficiary-birth-country']]: 'beneficiary-birth-country',
    },
  },
  [mmvFields['existing-beneficiary']]: 'existing-beneficiary',
  [mmvFields['no-beneficiary-checkbox']]: 'no-beneficiary-checkbox',
  [mmvFields['beneficiary-owner']]: {
    mappingKey: 'beneficiary-owner',
    keys: {
      [mmvFields['beneficiary-owner.beneficiary-first-name']]: 'beneficiary-first-name',
      [mmvFields['beneficiary-owner.beneficiary-last-name']]: 'beneficiary-last-name',
      [mmvFields['beneficiary-owner.beneficiary-gender']]: 'beneficiary-gender',
      [mmvFields['beneficiary-owner.beneficiary-tax-id']]: 'beneficiary-tax-id',
      [mmvFields['beneficiary-owner.beneficiary-birthday']]: 'beneficiary-birthday',
      [mmvFields['beneficiary-owner.beneficiary-company-share']]: 'beneficiary-company-share',
      [mmvFields['beneficiary-owner.beneficiary-private-city']]: 'beneficiary-private-city',
      [mmvFields['beneficiary-owner.beneficiary-private-address']]: 'beneficiary-private-address',
      [mmvFields['beneficiary-owner.beneficiary-private-country']]: 'beneficiary-private-country',
      [mmvFields['beneficiary-owner.beneficiary-zip-code']]: 'beneficiary-zip-code',
      [mmvFields['beneficiary-owner.beneficiary-birth-country']]: 'beneficiary-birth-country',
    },
  },
  customerDataPage: {
    companySection: {
      companyName: 'name',
      address: INQUIRY_VALUE_FORMATTERS_REFS.company,
    },
    contactPersonSection: {
      title: 'salutation',
      firstName: 'first_name',
      lastName: 'last_name',
      email: 'email',
    },
    authorizationOfRepresentationSection: {},
    beneficialOwnerSection: {},
  },
  userProfilePage: {
    contactPersonSection: {
      gender: 'salutation',
      phone: 'phone_number',
      email: 'email',
      lastName: 'last_name',
      firstName: 'first_name',
    },
    passwordSection: {
      password: '',
      passwordConfirmation: '',
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: '',
    },
  },
};

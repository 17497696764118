import { useSelector } from 'react-redux';

import { ProductKindType } from 'models/ProductKind.model';
import {
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__CREFO_FACTORING,
  OFFER_INSTALLMENT_RATE,
  OFFER_MONTHLY_INSTALLMENT,
  OFFER_FACTORING_LINE,
  OFFER_FACTORING_FEE,
  OFFER_PAYOUT_RATIO,
  CONDITION_INTEREST_RATE,
  CONDITION_LAST_INSTALLMENT_AMOUNT,
  CONDITION_INSTALLMENT_AMOUNT,
  PRODUCT_KIND__KFINANZ,
  PRODUCT_KIND__FACTOMAT,
  PRODUCT_KIND__BFSS_ONLINE_FACTORING,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import {
  getIndicativeCondition,
  getIndicativeOffer,
  getInquiryProductKind,
} from 'store/inquiryDetails/selectors';
import { isEmptyObject } from 'utils/helpers';

export const useOfferInputFields = (): InputField[] => {
  const productKind = useSelector(getInquiryProductKind) as ProductKindType;

  return productKind && inputFields[productKind] ? inputFields[productKind] : [];
};

export const useOfferFields = (): StaticField[] => {
  const productKind = useSelector(getInquiryProductKind) as ProductKindType;
  const indicativeConditions = useSelector(getIndicativeCondition);
  const indicativeOffer = useSelector(getIndicativeOffer);

  if (!isEmptyObject(indicativeConditions) && !isEmptyObject(indicativeOffer)) {
    return conditionFields;
  }

  return productKind && staticFields[productKind] ? staticFields[productKind] : [];
};

export interface StaticField {
  name: string;
  title: string;
  info: string;
  unit: string;
}

export interface InputField {
  caption: string;
  input: string;
  unit: FIELD_UNIT_TYPE;
  maxValue: number;
  minValue?: string;
  isDecimal: boolean;
  captionTooltip?: string;
}

const conditionFields: StaticField[] = [
  {
    title: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.installmentRate',
    info: '',
    name: CONDITION_INTEREST_RATE,
    unit: '%',
  },
  {
    title: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.installmentAmount',
    info: '',
    name: CONDITION_INSTALLMENT_AMOUNT,
    unit: '€',
  },
  {
    title: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.lastInstallmentAmount',
    info: '',
    name: CONDITION_LAST_INSTALLMENT_AMOUNT,
    unit: '€',
  },
];

const offerFields = [
  {
    title: 'pages.inquiryDetails.dashboard.actions.indicativeOffer.factoringFee.caption',
    info: 'pages.inquiryDetails.dashboard.actions.indicativeOffer.factoringFee.tooltip',
    name: OFFER_FACTORING_FEE,
    unit: '%',
  },
  {
    title: 'pages.inquiryDetails.dashboard.actions.indicativeOffer.factoringLine.caption',
    info: 'pages.inquiryDetails.dashboard.actions.indicativeOffer.factoringLine.tooltip',
    name: OFFER_FACTORING_LINE,
    unit: '€',
  },
  {
    title: 'pages.inquiryDetails.dashboard.actions.indicativeOffer.payoutRatio.caption',
    info: 'pages.inquiryDetails.dashboard.actions.indicativeOffer.payoutRatio.tooltip',
    name: OFFER_PAYOUT_RATIO,
    unit: '%',
  },
];

const staticFields: Record<string, StaticField[]> = {
  [PRODUCT_KIND__LOAN]: [
    {
      title: 'pages.inquiryDetails.dashboard.actions.indicativeOffer.installmentRate.caption',
      info: '',
      name: OFFER_INSTALLMENT_RATE,
      unit: '%',
    },
    {
      title: 'pages.inquiryDetails.dashboard.actions.indicativeOffer.monthlyInstallment.caption',
      info: '',
      name: OFFER_MONTHLY_INSTALLMENT,
      unit: '€',
    },
  ],
  [PRODUCT_KIND__CREFO_FACTORING]: offerFields,
  [PRODUCT_KIND__KFINANZ]: offerFields,
  [PRODUCT_KIND__FACTOMAT]: offerFields,
  [PRODUCT_KIND__BFSS_ONLINE_FACTORING]: offerFields,
};

const inputFields: Record<string, InputField[]> = {
  [PRODUCT_KIND__LOAN]: [
    {
      caption: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.installmentRate',
      input: OFFER_INSTALLMENT_RATE,
      unit: FIELD_UNIT_TYPE.PERCENTAGE,
      maxValue: 100,
      isDecimal: true,
    },
    {
      caption: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.monthlyInstallment',
      input: OFFER_MONTHLY_INSTALLMENT,
      unit: FIELD_UNIT_TYPE.CURRENCY,
      maxValue: 999999999,
      isDecimal: true,
    },
  ],
  [PRODUCT_KIND__CREFO_FACTORING]: [
    {
      caption: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringFee',
      input: OFFER_FACTORING_FEE,
      unit: FIELD_UNIT_TYPE.PERCENTAGE,
      maxValue: 100,
      isDecimal: true,
      captionTooltip: 'components.indicativeConditions.nonBindingOffer.factoringFee.tooltip',
    },
    {
      caption: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringLine',
      input: OFFER_FACTORING_LINE,
      unit: FIELD_UNIT_TYPE.CURRENCY,
      maxValue: 999999999,
      isDecimal: false,
      captionTooltip: 'components.indicativeConditions.nonBindingOffer.factoringLine.tooltip',
    },

    {
      caption: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.payoutRate',
      input: OFFER_PAYOUT_RATIO,
      unit: FIELD_UNIT_TYPE.PERCENTAGE,
      maxValue: 100,
      isDecimal: false,
      captionTooltip: 'components.indicativeConditions.nonBindingOffer.payoutRate.tooltip',
    },
  ],
  [PRODUCT_KIND__BFSS_ONLINE_FACTORING]: [
    {
      caption: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringFee',
      input: OFFER_FACTORING_FEE,
      unit: FIELD_UNIT_TYPE.PERCENTAGE,
      maxValue: 100,
      minValue: '0,01',
      isDecimal: true,
      captionTooltip: 'components.indicativeConditions.nonBindingOffer.factoringFee.tooltip',
    },
    {
      caption: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringLine',
      input: OFFER_FACTORING_LINE,
      unit: FIELD_UNIT_TYPE.CURRENCY,
      maxValue: 999999999,
      isDecimal: false,
      captionTooltip: 'components.indicativeConditions.nonBindingOffer.factoringLine.tooltip',
    },

    {
      caption: 'pages.inquiryDetails.dashboard.actions.nonBindingOffer.payoutRate',
      input: OFFER_PAYOUT_RATIO,
      unit: FIELD_UNIT_TYPE.PERCENTAGE,
      maxValue: 100,
      minValue: '0,01',
      isDecimal: true,
      captionTooltip: 'components.indicativeConditions.nonBindingOffer.payoutRate.tooltip',
    },
  ],
};

import _get from 'lodash/get';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import mapMarketValueFromApi from 'modules/EditMarketValuesReeProfitability/mapMarketValueFromApi';
import { PROFI_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { flattenObject } from 'utils/helpers';
import { IMakeCall } from 'utils/hooks/useDispatchApiCallHook';
import { deepCamelcaseKeys } from 'utils/object';


const getMarketValueDetailsAction = (inquiryId: string, recordType: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.MARKET_RESEARCH.compose({ params: { inquiryId } }),
    method: API_METHODS.GET,
  });

const loadMarketValueDetails = async (
  makeCall: IMakeCall,
  inquiryId: string,
  recordType: string,
) => {
  const { payload, error } = await makeCall(getMarketValueDetailsAction(inquiryId, recordType));
  const data = _get(payload, 'data');
  let editFormValues;
  if (recordType === PROFI_RECORD_TYPE.VALUE_API) {
    editFormValues = mapMarketValueFromApi(deepCamelcaseKeys(_get(data, 'data.attributes.details')));
  }
  FormFieldController.instance.saveBulk(flattenObject(editFormValues), { emit: false });
  return { error };
};

export default loadMarketValueDetails;

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { IMessageEvent } from 'websocket';

import useLanguagePreferences from 'components/App/LanguageProvider/useLanguagePreferences';
import { USER_SCOPE } from 'constants/userScopes';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFetchInquiryFileRequests } from 'shared/documentExchange/useFetchInquiryFileRequests';
import { useFetchInquiryFiles } from 'shared/documentExchange/useFetchInquiryFiles';
import { Channels, useWebSocket } from 'shared/useWebsocket';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { ConfirmObjectTurnoverStep } from './ConfirmObjectTurnoverStep';
import LeaseplanCustomerDashboard from './LeaseplanDashboard';
import { ProgressAdditionalFields } from './ProgressAdditionalFields';
import { ProgressAllRequiredFilesUploaded } from './ProgressAllRequiredFilesUploaded';
import { ProgressFactoringOffer } from './ProgressFactoringOffer';
import { ProgressIndicativeOffer } from './ProgressIndicativeOffer';
import { ProgressSuccess } from './ProgressSuccess';
import { ProgressVideoIdent } from './ProgressVideoIdent';
import SummaryCard from './SummaryCard';
import SummaryHeader from './SummaryHeader';
import { ProgressSectionType } from './types';
import { useProgressSections } from './useProgressSections';

export const SectionByType = (props: Record<string, any>) => {
  const { section, sectionProps } = props;
  switch (section.type) {
    case ProgressSectionType.INDICATIVE_OFFER:
      return <ProgressIndicativeOffer {...sectionProps} />;
    case ProgressSectionType.VIDEO_IDENT:
      return <ProgressVideoIdent {...sectionProps} />;
    case ProgressSectionType.DOCUMENT_UPLOAD:
      return <ProgressAllRequiredFilesUploaded {...sectionProps} />;
    case ProgressSectionType.ADDITIONAL_FIELDS:
      return <ProgressAdditionalFields {...sectionProps} />;
    case ProgressSectionType.FACTORING_OFFER:
      return <ProgressFactoringOffer {...sectionProps} />;
    case ProgressSectionType.CONFIRM_OBJECT_TAKEOVER:
      return <ConfirmObjectTurnoverStep {...sectionProps} />;
    default:
      return null;
  }
};

const CustomerDashboard = () => {
  useFetchInquiryFiles({ isDownloadedBy: USER_SCOPE.CUSTOMER });
  const { selectedLanguage } = useLanguagePreferences();
  useFetchInquiryFileRequests({ isDownloadedBy: USER_SCOPE.OPERATION_PORTAL, selectedLanguage });
  const { firstOpenTask, completedValue, sectionsDone, sectionsOpen, sections, lastItemIndex } =
    useProgressSections();
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();

  useWebSocket({
    channel: Channels.INQUIRY,
    customProps: {
      id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
      }
    },
  });

  return (
    <article>
      <SummaryHeader />
      <SummaryCard
        completedValue={completedValue}
        progressIncrement={sectionsDone}
        progressTotal={sectionsDone + sectionsOpen}
        isCompleted={sectionsOpen === 0}
        openTaskId={firstOpenTask}
        canShowProgress={sectionsDone + sectionsOpen > 0}
      />
      {sections &&
        Object.values(sections)
          .filter((item) => item.visible)
          .map((section, index) => {
            const sectionProps = {
              completedValue: section.completedValue,
              isFirst: index === 0,
              isLast: sectionsOpen !== 0 && index === lastItemIndex,
              isCompleted: completedValue === 100,
              isPending: section.isPending,
              progressIncrement: section.increment,
              actionUrl: section.data?.url,
              progressTotal: section.total,
              id: firstOpenTask === section.type ? firstOpenTask : undefined,
            };
            return <SectionByType section={section} sectionProps={sectionProps} />;
          })}
      {sectionsOpen === 0 && (
        <ProgressSuccess
          isLast
          isCompleted
          completedValue={100}
          progressIncrement={1}
          progressTotal={1}
        />
      )}
    </article>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.leaseplan]: LeaseplanCustomerDashboard,
  default: CustomerDashboard,
});

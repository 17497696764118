import * as React from 'react';

import { useFormState } from 'react-final-form';

import FormRow from 'components/FormRow';
import { HiddenInputWithField } from 'components/HiddenInput';
import { InputWithField } from 'components/Input';
import StaticField from 'components/StaticField';
import { MAX_ZIP_CODE_LENGTH } from 'constants/globalConstants';
import { Company, mittweidaFields, MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import {
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';


import { useGetSelectedCompany } from '../hooks/useGetSelectedCompany';

type StaticCompanyInformationProps = {
  company: NonNullable<Company>;
};

const {
  name: nameField,
  street: streetField,
  zipCode: zipCodeField,
  city: cityField,
} = mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company;

const StaticCompanyInformation = ({
  company: { name, street, zipCode, city },
}: StaticCompanyInformationProps) => {
  const t = useTranslations();
  const { userProfileCompanyName, userProfileCompanyStreet } =
    translations.pages.userProfile.sections.completingCompanyDetails.fields;

  return (
    <FormRow separator alignItems={'flex-start'}>
      <StaticField caption={t(userProfileCompanyName.caption)} text={name} />
      <StaticField
        caption={t(userProfileCompanyStreet.caption)}
        text={`${street}\n${zipCode} ${city}`}
      />
      {/*We can think about validating them in the sectionValidation via their name and the respective validator.*/}
      {/*But this would not be handled by react-final-form, thus we are rendering these fields for now to get proper validation*/}
      <HiddenInputWithField name={nameField} />
      <HiddenInputWithField name={streetField} />
      <HiddenInputWithField name={zipCodeField} />
      <HiddenInputWithField name={cityField} />
    </FormRow>
  );
};

const { name, street, zipCode, city } =
  mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company;

const FillCompanyInformationForm = () => {
  return (
    <>
      <FormRow>
        <InputWithField name={name} />
      </FormRow>
      <FormRow>
        <InputWithField name={street} />
      </FormRow>
      <FormRow separator>
        <InputWithField
          name={zipCode}
          type={'tel'}
          pattern={'[0-9]*'}
          inputMaxLimit={MAX_ZIP_CODE_LENGTH}
          separateThousands={false}
        />
        <InputWithField name={city} />
      </FormRow>
    </>
  );
};

export const CompanyInformationForm = () => {
  const selectedCompany = useGetSelectedCompany();
  const { values } = useFormState<MittweidaFieldTypes>();
  const { selectedCompanyName } = values.companyDetailPage.companyDetailsSection;

  if (
    selectedCompanyName === COMPANY_DETAILS_COMPANY__NOT_FOUND ||
    selectedCompanyName === COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  ) {
    return <FillCompanyInformationForm />;
  }

  if (selectedCompany !== null) {
    return <StaticCompanyInformation company={selectedCompany} />;
  }

  return null;
};

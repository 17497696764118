import { SummaryFieldsType } from 'new/form/model';

import { FieldTypes } from '../fields';

// the following structure must contain all the steps and fields as per fields;
export const summaryFields: SummaryFieldsType<FieldTypes> = {
  contractDetails: {
    dealerSection: {
      supplier: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      agent: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
    financingObjectSection: {
      objectAlreadyDelivered: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      objectDescription: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      state: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      serialNumber: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
    financingConditionsSection: {
      runtime: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      objectValue: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      downPayment: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      ratePerMonth: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      additionalInformation: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      remainingValue: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
    bankDetailsSection: {
      accountHolder: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      iban: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
  },
  customerDataPage: {
    companySection: {
      companyName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      address: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    contactPersonSection: {
      title: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      firstName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      lastName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      email: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    authorizationOfRepresentationSection: {},
    beneficialOwnerSection: {},
  },
  userProfilePage: {
    contactPersonSection: {
      gender: { unit: 'none', showOnSummaryPage: true },
      firstName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      lastName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      email: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      phone: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    passwordSection: {
      password: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      passwordConfirmation: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
  },
};

/* eslint-disable */
import { useTranslations } from 'utils/hooks/useTranslations';
import { useToasts } from 'shared/hooks/useToasts';
import { Route, Switch, useHistory } from 'react-router-dom';
import React from 'react';
import paths from 'constants/paths';
import { translations } from 'new/form/common/types';
import { useGetInitialValuesForEditMode } from 'new/form/persist';
import { FieldTypes } from 'mmv/inquiry/fields';
import { InquiryMode } from 'new/form/state/types';
import { useSelector } from 'react-redux';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { isLoggedInSelector } from 'store/user/selectors';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import {
  createSpecializedInquiryAction,
  updateSpecializedInquiryAction,
} from 'pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import { InquiryProcessForm } from 'new/form';
import { useRootRedirect } from 'mittweida/inquiry';
import { withCanEditInquiryGuard } from 'modules/InquiryEdit/WithCanEditInquiryGuard';
import { ContractDetails } from './steps/contractDetails';
import { MmvSummaryStep } from './steps/summary';
import { MmvCustomerDataStep } from './steps/customerData';

export const MMV_FORM = 'MMV_FORM';

function mapContractDetailsToApi(values: FieldTypes) {
  const { dealerSection, financingObjectSection, financingConditionsSection, bankDetailsSection } =
    values.contractDetails;
  return {
    supplier: dealerSection.supplier,
    agent: dealerSection.agent,
    object_description: financingObjectSection.objectDescription,
    object_already_delivered: financingObjectSection.objectAlreadyDelivered,
    state: financingObjectSection.state,
    runtime: financingConditionsSection.runtime,
    object_value: financingConditionsSection.objectValue,
    down_payment: financingConditionsSection.downPayment,
    lease_rate: financingConditionsSection.ratePerMonth,
    maintenance: financingConditionsSection.additionalInformation,
    residual_value: financingConditionsSection.remainingValue,
    // account_holder:
    iban: bankDetailsSection.iban,
  };
}

// //TODO add explanation for the following function usage
// function mapFutureUsage(values: FieldTypes) {
//   const { futureUsage } = values.financingNeedPage.objectInformationSection;
//   const isFutureUsageEmpty = isArrayFieldEmpty(futureUsage);
//
//   if (isFutureUsageEmpty) {
//     return {
//       project_financing_object_usages_future: [],
//     };
//   }
//
//   return {
//     project_financing_object_usages_future: futureUsage.map((usage) => {
//       return {
//         type_of_use: usage.typeOfUse,
//         usage_space: usage.usageSpace,
//         number_of_units: usage.numberOfUnits,
//         planned_sales_price: usage.plannedSalesPrice,
//       };
//     }),
//   };
// }
//
// function mapFinancingNeedData(values: FieldTypes) {
//   const futureUsage = mapFutureUsage(values);
//   const currentUsage = mapCurrentUsage(values);
//
//   const {
//     roleSection,
//     objectInformationSection,
//     otherProjectInformation,
//     projectCompositionSection,
//   } = values.financingNeedPage;
//
//   return {};
// }
//
// // chapter: data operations
//
// function mapCompanyDetailsToApi(values: FieldTypes) {
//   const { completingCompanyDetailsSection } = values.companyDetailPage;
//
//   return {
//     'company-attributes': {
//       name: completingCompanyDetailsSection.company.name,
//       street: completingCompanyDetailsSection.company.street,
//       'zip-code': completingCompanyDetailsSection.company.zipCode,
//       city: completingCompanyDetailsSection.company.city,
//       industry: completingCompanyDetailsSection.company.industry,
//       'legal-form': completingCompanyDetailsSection.company.legalForm,
//       'founding-year': completingCompanyDetailsSection.company.foundingYear,
//       'turnover-class': completingCompanyDetailsSection.company.turnoverClass,
//       'already-customer': completingCompanyDetailsSection.company.alreadyCustomer,
//       contact_to_bank_advisor: completingCompanyDetailsSection.company.alreadyContactWithBank,
//       bank_advisor_name: completingCompanyDetailsSection.company.nameOfContactPerson,
//     },
//   };
// }
//
// function mapUserDetailsToApi(values: FieldTypes, mode: InquiryMode, isLoggedIn: boolean) {
//   const { contactPersonSection, termsAndConditionsSection, passwordSection } =
//     values.userProfilePage;
//
//   if (mode === 'create' && !isLoggedIn) {
//     return {
//       'user-attributes': {
//         salutation: contactPersonSection.gender,
//         'first-name': contactPersonSection.firstName,
//         'last-name': contactPersonSection.lastName,
//         email: contactPersonSection.email,
//         'phone-number': contactPersonSection.phone,
//         'terms-and-conditions-accepted': termsAndConditionsSection.termsAndConditionsAccepted,
//         password: passwordSection.password,
//       },
//     };
//   }
//
//   return {};
// }
//
// function mapFundingDetailsToApi(values: FieldTypes) {
//   const { otherFundingSection } = values.fundingDetailsPage;
//   return {
//     other_product_description: otherFundingSection.description,
//     other_financing_description: otherFundingSection.description,
//   };
// }

// chapter: send inquiry

const useSendMmvInquiry = (mode: InquiryMode) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const t = useTranslations();
  const { makeCall } = useDispatchApiCall(dispatchOptions(t(translations.errors.submitInquiry)));

  return async (values: FieldTypes) => {
    const contractDetails = mapContractDetailsToApi(values);

    const apiData = {
      ...contractDetails,
      form_type: 'mmv',
      purpose_kind: 'project_financing',
      product_kind: 'other',
      compeon_partner: 'mmv', //TODO could this be pulled from inquiryType?
      'consider-subsidies': false,
    };

    const sendMmvInquiry = () => createSpecializedInquiryAction(apiData, 'profi');
    const updateMmvInquiry = (inquiryId: string) =>
      updateSpecializedInquiryAction(inquiryId, apiData);
    return makeCall(inquiryId ? updateMmvInquiry(inquiryId) : sendMmvInquiry());
  };
};

const useSendInquiry = (mode: InquiryMode) => {
  return useSendMmvInquiry(mode);
};

// chapter: forms

const EditCustomerRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={paths.customer.inquiryDetails.edit.contractDetails}
        component={ContractDetails}
      />
      <Route
        exact
        path={paths.customer.inquiryDetails.edit.customerData}
        component={MmvCustomerDataStep}
      />
      <Route
        exact
        path={paths.customer.inquiryDetails.edit.summaryPage}
        component={MmvSummaryStep}
      />
    </Switch>
  );
};

const EditCustomerRoutesWithGuard = withCanEditInquiryGuard(EditCustomerRoutes);

export const MmvEditCustomerPortalForm = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const initialValues = useGetInitialValuesForEditMode();
  const sendInquiry = useSendInquiry('editCustomer');
  const history = useHistory();
  const t = useTranslations();
  const { error: errorToast } = useToasts();
  useRootRedirect('editCustomer', {
    editCustomer: paths.customer.inquiryDetails.edit.contractDetails,
    editOperation: paths.operation.inquiryDetails.edit.contractDetails,
  });

  const handleSubmit = async (values: FieldTypes) => {
    const { error } = await sendInquiry(values);
    if (!error) {
      history.push(paths.customer.inquiryDetails.details.replace(':id', inquiryId!!));
    } else {
      console.error(error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
  };

  return (
    <InquiryProcessForm<FieldTypes> initialValues={initialValues} handleSubmit={handleSubmit}>
      <EditCustomerRoutesWithGuard />
    </InquiryProcessForm>
  );
};

const CreateRoutes = () => {
  return <Switch></Switch>;
};
export const MmvCreateForm = () => {
  const initialValues = useGetInitialValuesForEditMode();
  const sendInquiry = useSendInquiry('create');
  const history = useHistory();
  const t = useTranslations();
  const { error: errorToast } = useToasts();

  const handleSubmit = async (values: FieldTypes) => {
    const { error } = await sendInquiry(values);
    if (!error) {
      history.push(paths.finalPage);
    } else {
      console.error(error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
  };

  return (
    <InquiryProcessForm<FieldTypes> initialValues={initialValues} handleSubmit={handleSubmit}>
      <CreateRoutes />
    </InquiryProcessForm>
  );
};

import React from 'react';

import { useMultiStyleConfig } from '@chakra-ui/react';
import { bool, func, node, object, oneOfType, string, instanceOf, number } from 'prop-types';

import {
  StyledLabelWrapper as LabelWrapper,
  StyledLabelAndFieldWrapper as Wrapper,
  StyledCaptionWrapper as CaptionWrapper,
} from 'components/ControlWrapper/styles';
import { InputCaption, Optional } from 'components/Text';
import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { useTranslations } from 'utils/hooks/useTranslations';

export const FieldWrapper = ({
  caption,
  optional,
  tooltip,
  children,
  additionalLabelElement,
  errorMessage,
  shouldShowError,
  isHorizontal,
  popoverPlacement,
  fontCase,
  isCheckBox = false,
}) => {
  const t = useTranslations();

  const styles = useMultiStyleConfig('FieldWrapper', { captionFontCase: fontCase });
  return (
    <Wrapper horizontalLayout={isHorizontal} tooltip={!!tooltip} isCheckBox={isCheckBox}>
      <LabelWrapper>
        <CaptionWrapper>
          {caption && (
            <InputCaption fontCase={styles.caption.textTransform}>{caption}</InputCaption>
          )}
          {tooltip && (
            <PopoverComponent
              mt="-12px"
              ml={1}
              text={tooltip}
              popoverPlacement={popoverPlacement}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={6} display="block" />}
                  label={t('buttons.moreInfos')}
                />
              }
            />
          )}
        </CaptionWrapper>
        {optional && <Optional>{t('other.optional')}</Optional>}
        {additionalLabelElement && additionalLabelElement()}
      </LabelWrapper>
      {children}

      {
        // TODO: unify provided errors
        shouldShowError &&
          errorMessage &&
          (Array.isArray(errorMessage) ? (
            errorMessage.map((message, index) => <FieldError key={message} message={message} />)
          ) : (
            <FieldError message={errorMessage} />
          ))
      }
    </Wrapper>
  );
};

FieldWrapper.propTypes = {
  selected: oneOfType([string, number, instanceOf(Date)]),
  caption: string,
  optional: bool,
  isHorizontal: bool,
  tooltip: string,
  isCheckboxTooltip: bool,
  children: node,
  additionalLabelElement: func,
  errorMessage: oneOfType([object, string]),
  shouldShowError: bool,
  popoverPlacement: string,
  fontCase: string,
};

FieldWrapper.defaultProps = {
  caption: '',
  errorMessage: '',
  optional: false,
  isHorizontal: false,
  tooltip: null,
  isCheckboxTooltip: false,
  children: null,
  additionalLabelElement: null,
  shouldShowError: true,
  popoverPlacement: 'auto',
};

export default FieldWrapper;

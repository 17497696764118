import {mittweidaFields} from 'mittweida/inquiry/fields';
import {InquiryType} from "modules/Inquiry/Inquiry.type";
import {fieldValidators} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import {ValidationMapType} from 'new/form/common/types';
import {validateFieldValidationMap, ValidateSectionFunction} from 'new/form/useValidateFields';
import {InquiryConstants} from 'schema/inquirySchema.constants';

const {
  demolitionNecessary,
  heritageProtected,
  developmentNecessary,
  ownSalesPlanned,
  energyPass,
  buildingPermit,
  permitBasis,
  permitStatus,
  notesOnPermit,
} = mittweidaFields.financingNeedPage.otherProjectInformation;

export const validateOtherProjectInformationSection: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  return validateFieldValidationMap({
    validationMap: otherProjectInformationSectionValidationMap,
    form,
    values,
    inquiryType: InquiryType.profiMittweida,
  });
};

export const otherProjectInformationSectionValidationMap: ValidationMapType = {
  [demolitionNecessary]: fieldValidators.boolean().required(),
  [heritageProtected]: fieldValidators.boolean().required(),
  [developmentNecessary]: fieldValidators.boolean().required(),
  [ownSalesPlanned]: fieldValidators.boolean().optional(),
  [energyPass]: fieldValidators.boolean().optional(),
  [buildingPermit]: fieldValidators.boolean().optional(),
  [permitBasis]: fieldValidators.string().optional(),
  [permitStatus]: fieldValidators.string().optional(),
  [notesOnPermit]: fieldValidators
    .string()
    .optional()
    .max(InquiryConstants.MAX_MITTWEIDA_NOTES_ON_PERMIT_LENGTH),
};

import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from 'websocket';

import { getUserToken } from 'store/user/selectors';
import { EnvironmentVariable, getEnvironmentFeatureFlag } from 'utils/environment';

const WS_URL = getEnvironmentFeatureFlag(EnvironmentVariable.WS_URL);

export enum Channels {
  INQUIRY = 'InquiryChannel',
  UPLOADABLE_FILE = 'UploadableFileChannel',
}

interface WelcomeMessage {
  type: 'welcome';
}

interface PingMessage {
  type: 'ping';
  message: number;
}

interface ConfirmSubscriptionMessage {
  type: 'confirm_subscription';
  identifier: string;
}

interface UploadedFilesMessage {
  type: 'uploaded_files';
  message: {
    id: string;
  };
}

interface ArchivedFilesMessage {
  type: 'archived_files';
  message: {
    id: string;
  };
}

interface PrivateUploadedFilesMessage {
  type: 'private_uploaded_files';
  message: {
    id: string;
  };
}

export type WebSocketMessage =
  | WelcomeMessage
  | PingMessage
  | ConfirmSubscriptionMessage
  | UploadedFilesMessage
  | ArchivedFilesMessage
  | PrivateUploadedFilesMessage;

type OnMessageCallback = (message: IMessageEvent) => void;

interface WebSocketProps {
  url?: string;
  customProps?: any;
  channel: Channels;
  onMessage?: OnMessageCallback;
  onOpen?: () => void;
}

export const useWebSocket = (
  { url, channel, onMessage, customProps }: WebSocketProps = { channel: Channels.INQUIRY },
) => {
  const userToken = useSelector(getUserToken);

  const urlPath = url ?? WS_URL;
  const socketUrl = `${urlPath}?token=${userToken}`;

  useEffect(() => {
    if (!urlPath) return;

    const client = new W3CWebSocket(socketUrl);

    client.onopen = () => {
      // subscribe to channel
      const subscribeString = JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify({
          channel,
          ...customProps,
        }),
      });
      client.send(subscribeString);
    };

    client.onmessage = (message: IMessageEvent) => {
      if (onMessage) {
        onMessage(message);
      }
    };

    return () => {
      client.close();
    };
  }, []); // eslint-disable-line
};

import styled from 'styled-components/macro';

import { Text } from 'components/Text';
import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';

export const StyledHeading = styled(Text)`
  margin-bottom: 2rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
`;

export const FieldWrapper = styled.div`
  width: 100%;
  max-width: 40rem;
  margin-bottom: 1.5rem;
`;

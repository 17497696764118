import React from 'react';

import { useSelector } from 'react-redux';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';
import { getProfitabilityPropertyDetailsTable } from '../../store/selectors';
import type { MappingProps } from '../../types';
import { getRowStylesMap, useUnitFormatter } from '../../utils';

interface PropertyDetailsItem {
  total_space: number;
  total_units: number;
  parking_lots: number;
  total_parking: number;
  garage_parking: number;
  commercial_space: number;
  commercial_units: number;
  residential_space: number;
  residential_units: number;
}
export interface PropertyDetailsProps {
  current: PropertyDetailsItem;
  future: PropertyDetailsItem;
}

const mapping: MappingProps<PropertyDetailsItem> = [
  { key: 'residential_space', unit: 'sqm', styles: { bold: true, indented: true } },
  { key: 'commercial_space', unit: 'sqm', styles: { bold: true, indented: true } },
  { key: 'total_space', unit: 'sqm', styles: { bold: true, highlighted: true } },
  { key: 'residential_units', unit: 'units', styles: { bold: true, indented: true } },
  { key: 'commercial_units', unit: 'units', styles: { bold: true, indented: true } },
  { key: 'total_units', unit: 'units', styles: { bold: true, highlighted: true } },
  { key: 'garage_parking', unit: 'places', styles: { bold: true, indented: true } },
  { key: 'parking_lots', unit: 'places', styles: { bold: true, indented: true } },
  { key: 'total_parking', unit: 'places', styles: { bold: true, highlighted: true } },
];

const useGetPropertyDetailsTable = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.propertyDetails',
  );
  const formatter = useUnitFormatter();
  const propertyDetailsTableData = useSelector(getProfitabilityPropertyDetailsTable);
  const { usageType } = useSelector(getMarketDataFilterLegacy);
  return usePortalTable({
    data: mapping.map(({ key, unit }) => ({
      id: key,
      label: t(`rows.${key}`),
      value: formatter[unit](propertyDetailsTableData[usageType][key]),
    })),
    headers: [
      {
        key: 'label',
        text: t('headers.label'),
      },
      {
        key: 'value',
        text: t('headers.value'),
      },
    ],
  });
};

export const PropertyDetails = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.propertyDetails',
  );

  const propertyDetailsTable = useGetPropertyDetailsTable();
  const rowStyles = getRowStylesMap(mapping);

  if (!propertyDetailsTable) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Card title={t('title')}>
      <PortalTable {...propertyDetailsTable} rowStylesMap={{ ...rowStyles }} />
    </Card>
  );
};

import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { translateTargetMet } from 'mittweida/portals/operations/pages/FinancingCriteria/targetMetTranslator';

import { getMarketDataFilterLegacy } from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { replaceDotWithCommaAndAddUnit } from '../../../../../utils/valueFormats';
import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';
import { getFinanacialCriteriaDataStockholder } from '../../store/selectors';

interface FinancialCriteriaStockholderItem {
  value: string;
  target?: string;
  target_met: boolean | null;
}

export interface FinancialCriteriaStockholder {
  equity_buying_costs_percent: FinancialCriteriaStockholderItem;
  total_equity_percent: FinancialCriteriaStockholderItem;
  dscr: FinancialCriteriaStockholderItem;
  yield_residential: FinancialCriteriaStockholderItem;
  yield_commercial: FinancialCriteriaStockholderItem;
  warning_limit_unsecured_portion_indication: FinancialCriteriaStockholderItem;
}

type FinancialCriteriaStockholderKey = keyof FinancialCriteriaStockholder;

interface FinancialCriteriaTableData {
  id: string;
  label: string;
  value: string;
  target?: string;
  target_met: string;
}

const orderArray = [
  'equity_buying_costs_percent',
  'total_equity_percent',
  'dscr',
  'yield_residential',
  'yield_commercial',
  'warning_limit_unsecured_portion_indication',
];

const useFinancialCriteriaStockholderMapping = (objectData: FinancialCriteriaStockholder) => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.financingCriteria');
  const mappedArray: Array<FinancialCriteriaTableData> = Object.keys(objectData).map((key) => {
    const unit = key === 'dscr' ? undefined : '%';
    const value = objectData[key as FinancialCriteriaStockholderKey].value;
    const target = objectData[key as FinancialCriteriaStockholderKey].target;

    return {
      id: key,
      label: t(`tables.financingCriteriaTable.rowNames.investor.${key}`),
      value: replaceDotWithCommaAndAddUnit(value, unit),
      target: replaceDotWithCommaAndAddUnit(target, unit),
      target_met: translateTargetMet(
        objectData[key as FinancialCriteriaStockholderKey].target_met,
        t,
      ),
    };
  });

  return orderArray.map((item) => {
    return mappedArray.find((object) => {
      if (object.id === item) {
        return object;
      }
      return null;
    });
  });
};

const FinancingCriteriaPropertyStockholder = () => {
  const financialCriteriaDataStockholder: {
    current: FinancialCriteriaStockholder;
    future: FinancialCriteriaStockholder;
  } = useSelector(getFinanacialCriteriaDataStockholder);

  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.financingCriteria.tables.financingCriteriaTable',
  );
  const filterData = useSelector(getMarketDataFilterLegacy);

  const tableData = useFinancialCriteriaStockholderMapping(
    financialCriteriaDataStockholder?.[filterData?.usageType],
  );

  const financingCriteriaTable = usePortalTable({
    data: tableData,
    headers: [
      { key: 'label', text: '' },
      { key: 'target', text: t('columnNames.target') },
      { key: 'value', text: t('columnNames.value') },
      { key: 'target_met', text: t('columnNames.targetMet') },
    ],
  });

  return (
    <Box paddingTop={10}>
      <Card title={t('title.investor')}>
        <PortalTable
          {...financingCriteriaTable}
          headerStylesMap={{
            value: { width: '10%' },
            target: { width: '10%' },
            label: { width: '40%' },
            target_met: { width: '40%' },
          }}
        />
      </Card>
    </Box>
  );
};

export default FinancingCriteriaPropertyStockholder;

import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { NewStaticFieldWithField } from 'components/StaticField';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { mmvFields } from 'mmv/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const { objectDescription, state, objectAlreadyDelivered } =
  mmvFields.contractDetails.financingObjectSection;

interface FinancingObjectSectionProps {
  sectionNumber?: number;
}
export const FinancingObjectSection = (props: FinancingObjectSectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      title={t(translations.inquiryType.mmv.steps.contractDetails.sections.financingObject.title)}
      name="financingObject"
      {...props}
    >
      <FormRow>
        <NewStaticFieldWithField
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingObject
              .objectDescription.caption,
          )}
          name={objectDescription}
        />
        <NewStaticFieldWithField
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingObject.state
              .caption,
          )}
          name={state}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={objectAlreadyDelivered}
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingObject
              .objectAlreadyDelivered.caption,
          )}
          tooltip={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingObject
              .objectAlreadyDelivered.tooltip,
          )}
        />
        <InputWithField
          name="serialNumber"
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingObject.serialNumber
              .caption,
          )}
          optional
          tooltip={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingObject.serialNumber
              .tooltip,
          )}
        />
      </FormRow>
    </FormSection>
  );
};

import React from 'react';

import { useSelector } from 'react-redux';

import loadEditProfitabilitySection from 'api/CompeonReverseApi/operation/loadEditProfitabilitySections/actions';
import Condition from 'components/Condition';
import { ITableHeader } from 'components/PortalTable/TableHeader.model';
import paths from 'constants/paths';
import {
  FILTERS_SALE_TYPES,
  PROFITABILITY_RECORD_TYPE,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatColumnValues } from 'utils/valueFormats';

import mapProfitabilityToEdit from '../../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import {
  profitabilitySaleAsRentValueTranslationsMapping,
  PROFITABILITY_SALE_AS_RENT,
  PROFITABILITY_SALE_AS_RENT_HEADERS,
} from '../../../types';
import { useRoles } from '../../hooks';
import TableCardWithEdit from '../../TableCardWithEdit';
interface props {
  data: {
    [key: string]: {
      [PROFITABILITY_SALE_AS_RENT_HEADERS.CUSTOMER_CURRENT]: number;
      [PROFITABILITY_SALE_AS_RENT_HEADERS.CUSTOMER_CURRNET]: number;
      [PROFITABILITY_SALE_AS_RENT_HEADERS.CUSTOMER_FUTURE]: number;
      [PROFITABILITY_SALE_AS_RENT_HEADERS.MARKET_DATA]: number;
      [PROFITABILITY_SALE_AS_RENT_HEADERS.REE]: number;
    };
  };
}

const RentProfitability: React.FC<props> = ({ data }: props) => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { rentalParameters } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const t = useTranslations('pages.planningEvaluation.profitabilityCalculation.sections.rent');
  const { saleType } = useSelector(getMarketDataFilterLegacy);
  const { hasRoleBankAdvisorOrRealEstateExpert } = useRoles();
  const { onEdit } = useEditFormConfig({
    pathToEdit: rentalParameters(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS,
    loadData: loadEditProfitabilitySection,
    editFormStructure: mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS](),
  });
  const canEdit = hasRoleBankAdvisorOrRealEstateExpert;
  const headers: ITableHeader[] = [
    {
      key: PROFITABILITY_SALE_AS_RENT_HEADERS.LABEL,
      text: t('tableHeaders.type'),
    },
    {
      key: PROFITABILITY_SALE_AS_RENT_HEADERS.CUSTOMER_CURRENT,
      text: t('tableHeaders.customerCurrent'),
    },
    {
      key: PROFITABILITY_SALE_AS_RENT_HEADERS.CUSTOMER_FUTURE,
      text: t('tableHeaders.customerFuture'),
    },
    {
      key: PROFITABILITY_SALE_AS_RENT_HEADERS.MARKET_DATA,
      text: t('tableHeaders.marketData'),
    },
    {
      key: PROFITABILITY_SALE_AS_RENT_HEADERS.REE,
      text: t('tableHeaders.reeRent'),
    },
  ];

  const mapUnit = (value: number) => {
    return formatColumnValues(value);
  };

  const highlightedRows = [
    `${PROFITABILITY_SALE_AS_RENT.PROFITABILITY_TOTAL_RENTAL_INCOME}`,
    `${PROFITABILITY_SALE_AS_RENT.PROFITABILITY_NOI_BEFORE_TAXES}`,
    `${PROFITABILITY_SALE_AS_RENT.PROFITABILITY_EARNING_BEFORE_TAXES}`,
    `${PROFITABILITY_SALE_AS_RENT.PROFITABILITY_OUTCOME_AFTER_TAXES}`,
    `${PROFITABILITY_SALE_AS_RENT.PROFITABILITY_ECF_AFTER_TAXES}`,
  ];

  const mapRow = (key: string) => {
    return {
      id: key,
      [PROFITABILITY_SALE_AS_RENT_HEADERS.LABEL]: t(
        `tableRows.${profitabilitySaleAsRentValueTranslationsMapping[key]}`,
      ),
      // TODO: currnet_customer Will be removed once handled from BE
      [PROFITABILITY_SALE_AS_RENT_HEADERS.CUSTOMER_CURRENT]: mapUnit(
        data[key]?.current_customer || data[key]?.currnet_customer,
      ),
      [PROFITABILITY_SALE_AS_RENT_HEADERS.CUSTOMER_FUTURE]: mapUnit(data[key]?.future_customer),
      [PROFITABILITY_SALE_AS_RENT_HEADERS.MARKET_DATA]: mapUnit(data[key]?.from_market_data),
      [PROFITABILITY_SALE_AS_RENT_HEADERS.REE]: mapUnit(data[key]?.from_ree),
      ...(highlightedRows.includes(key) && { rowHighlight: true, valueBold: true }),
    };
  };

  const rows = data ? Object.keys(profitabilitySaleAsRentValueTranslationsMapping).map(mapRow) : [];

  return (
    <Condition condition={saleType === FILTERS_SALE_TYPES.RENT}>
      <AccordionComponent headingLevel="h3" mb={8} title={t('title')} defaultIndex={[0]}>
        <TableCardWithEdit
          canEdit={canEdit}
          onEdit={onEdit}
          tableHeaders={headers}
          tableData={rows}
          componentName={PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS}
        />
      </AccordionComponent>
    </Condition>
  );
};

export default RentProfitability;

import React from 'react';

import env from '@beam-australia/react-env';
import { useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import { LogoImage, LogoLink } from 'modules/Header/PartnerLogo/styles';
import { PARTNER_LOGO } from 'modules/Inquiry/Form/formFields';
import { useIsUnderOperationPortalPath } from 'shared/hooks/useIsUnderOperationPortalPath';
import { isLoggedInSelector } from 'store/user/selectors';
import useIsScrolledToTop from 'utils/hooks/useIsScrolledToTop';

const PartnerLogo = () => {
  const isScrolledToTop = useIsScrolledToTop();
  const {
    meta: { logoUrl },
  } = usePartnerConfig();

  const isOperationPortal = useIsUnderOperationPortalPath();
  const startRouteForCustomer = paths.inquiry.new.root;
  const isLoggedIn = useSelector(isLoggedInSelector);

  if (!logoUrl) {
    return null;
  }

  let redirectLink = startRouteForCustomer;

  if (isOperationPortal) {
    redirectLink = isLoggedIn ? paths.operation.inquiryList : paths.operation.login;
  }

  return (
    <LogoLink to={redirectLink}>
      <LogoImage
        src={`${env('LOGO_STORAGE_URL')}${logoUrl}`}
        alt="logo"
        biggerHeader={isScrolledToTop}
        data-testid={PARTNER_LOGO}
      />
    </LogoLink>
  );
};

export default PartnerLogo;

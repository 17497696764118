import { ThemeOverride } from '@chakra-ui/react';

const overrides: ThemeOverride = {
  components: {
    LoginForm: {
      defaultProps: {
        variant: 'horizontal',
      },
    },
    CustomerLogin: {
      defaultProps: {
        variant: 'withoutRegisterLink',
      },
    },
    CustomerLoginHeading: {
      defaultProps: {
        variant: 'primary',
      },
    },
    CustomerInquiryListHeading: {
      defaultProps: {
        variant: 'hideSearch',
      },
    },
    CustomerLoginPortalPage: {
      defaultProps: {
        size: 'xs',
        variant: 'blendIn',
      },
    },
    FieldWrapper: {
      defaultProps: {
        captionFontCase: 'none',
      },
    },
    Header: {
      defaultProps: {
        hideLoginLink: true,
      },
    },
  },
};

export default overrides;

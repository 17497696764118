import React from 'react';

import Condition from 'components/Condition';
import ArchiveIcon from 'theme/components/Icon/ArchiveIcon';

import { ArchiveDocumentsModal } from './ArchiveDocumentsModal';
import { useArchiveDocumentsButton } from '../hooks/useArchiveDocumentsButton';

export const ArchivePrivateDocumentButton = () => {
  const { isOpen, onClose, onOpen, shouldShowArchiveDocumentsButton } = useArchiveDocumentsButton();

  return (
    <Condition condition={shouldShowArchiveDocumentsButton}>
      <ArchiveIcon boxSize={4} cursor="pointer" color="brand.default" onClick={onOpen} />
      <ArchiveDocumentsModal isOpen={isOpen} onClose={onClose} isPrivateFile />
    </Condition>
  );
};

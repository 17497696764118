import * as React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  getCompanyId,
  getInquiryDetailsSelector,
  getInquiryIdSelector,
} from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

type AutoMergeActionProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
};

export const AutoMergeAction = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: AutoMergeActionProps) => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.autoMerge');
  const inquiryId = useSelector(getInquiryIdSelector);
  const companyId = useSelector(getCompanyId);
  const history = useHistory();
  const { dashboardActions } = inquiryDetails;
  const { selectedInquiryType } = useFormConfig();
  const isCrefoFactoring = selectedInquiryType === InquiryType.onlinefactoring;

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>

          <TextComponent mb={6}>
            <MarkdownWrapper text={t('description')} />
          </TextComponent>

          <ButtonComponent
            onClick={() =>
              history.push(
                paths.operation.inquiryDetails.companyDetails.associatedPersons(
                  inquiryId!,
                  companyId,
                ),
              )
            }
            leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
            variant="primary"
            disabled={isCrefoFactoring && !dashboardActions?.isKycProcessCompleted}
          >
            {t('action')}
          </ButtonComponent>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};

import React from 'react';

import { Form } from 'react-final-form';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { useMultipleFiles } from 'components/UploadBlock/hooks/useMultipleFiles';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import ButtonComponent from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

const EXACT_PERSON_NO_DIGITS = 10;

export interface ArchiveDocumentsFormValues {
  personalIdentificationNumber: number;
}

interface ArchiveDocumentsModalProps {
  isPrivateFile?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const ArchiveDocumentsModal = ({
  isOpen,
  onClose,
  isPrivateFile = false,
}: ArchiveDocumentsModalProps) => {
  const { archiveFile } = useMultipleFiles({ isPrivateFile });
  const [isLoading, setIsLoading] = React.useState(false);
  const { required, notNegative, exactLength } = useFieldValidators();

  const t = useTranslations();

  const {
    cancel,
    confirm,
    title,
    content,
    fields: { personalIdentificationNumber },
  } = translations.pages.inquiryDetails.documentExchange.archiveDocuments.modal;

  const handleFormSubmit = async (values: ArchiveDocumentsFormValues) => {
    setIsLoading(true);
    await archiveFile(values);
    setIsLoading(false);
    onClose();
  };

  const footer = (
    <>
      <ButtonComponent type="button" variant="tertiary" mr={4} onClick={onClose}>
        {t(cancel)}
      </ButtonComponent>
      <ButtonComponent isLoading={isLoading} type="submit" form="archive-document">
        {t(confirm)}
      </ButtonComponent>
    </>
  );

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title={t(title)} footer={footer}>
      <TextComponent mb={8}>{t(content)}</TextComponent>
      <Form<ArchiveDocumentsFormValues>
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="archive-document">
            <FormRow>
              <InputWithField
                name={personalIdentificationNumber}
                caption={t(personalIdentificationNumber)}
                type="number"
                validate={combineValidators(
                  required,
                  notNegative,
                  exactLength(EXACT_PERSON_NO_DIGITS),
                )}
                separateThousands={false}
              />
            </FormRow>
          </form>
        )}
      />
    </ModalComponent>
  );
};

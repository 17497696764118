import { mmvFields } from 'mmv/inquiry/fields';
import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

const fields = mmvFields;
const translations =
  translationsObject.inquiryType.mmv.steps.customerDataPage.sections.beneficialOwnerSection.fields;

export const beneficialOwnerSectionLabelsMap: LabelsMap = {
  [fields['has-beneficiary-owner']]: translations.hasBeneficiaryOwner.caption,
  [fields['is-beneficiary-owner']]: translations.isBeneficiaryOwner.caption,

  [fields['beneficiary-owner.beneficiary-company-share']]: translations.companyShare.caption,
  [fields['beneficiary-owner.beneficiary-tax-id']]: translations.beneficiaryTaxId.caption,
  [fields['beneficiary-owner.beneficiary-private-address']]:
    translations.beneficiaryPrivateAddress.caption,
  [fields['beneficiary-owner.beneficiary-private-city']]:
    translations.beneficiaryPrivateCity.caption,
  [fields['beneficiary-owner.beneficiary-private-country']]:
    translations.beneficiaryPrivateCountry.caption,
  [fields['beneficiary-owner.beneficiary-zip-code']]: translations.beneficiaryZipCode.caption,
  [fields['beneficiary-owner.beneficiary-birth-country']]:
    translations.beneficiaryBirthCountry.caption,
  [fields['beneficiary-owner.beneficiary-birthday']]: translations.beneficiaryBirthday.caption,
  [fields['beneficiary-owner.beneficiary-first-name']]: translations.firstName.caption,
  [fields['beneficiary-owner.beneficiary-last-name']]: translations.lastName.caption,
  [fields['beneficiary-owner.beneficiary-gender']]: translations.gender.caption,

  [fields['self-beneficiary-owner.beneficiary-company-share']]: translations.companyShare.caption,
  [fields['self-beneficiary-owner.beneficiary-tax-id']]: translations.beneficiaryTaxId.caption,
  [fields['self-beneficiary-owner.beneficiary-private-address']]:
    translations.beneficiaryPrivateAddress.caption,
  [fields['self-beneficiary-owner.beneficiary-private-city']]:
    translations.beneficiaryPrivateCity.caption,
  [fields['self-beneficiary-owner.beneficiary-private-country']]:
    translations.beneficiaryPrivateCountry.caption,
  [fields['self-beneficiary-owner.beneficiary-zip-code']]: translations.beneficiaryZipCode.caption,
  [fields['self-beneficiary-owner.beneficiary-birth-country']]:
    translations.beneficiaryBirthCountry.caption,
  [fields['self-beneficiary-owner.beneficiary-birthday']]: translations.beneficiaryBirthday.caption,
  [fields['self-beneficiary-owner.beneficiary-first-name']]: translations.firstName.caption,
  [fields['self-beneficiary-owner.beneficiary-last-name']]: translations.lastName.caption,
  [fields['self-beneficiary-owner.beneficiary-gender']]: translations.gender.caption,
};

import React from 'react';

import { Grid, Box } from '@chakra-ui/react';

import { ComputedMarketDataLocation } from 'models/types/MarketData.type';
import { Rating } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/Rating';
import { RiskClass } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/RiskClass';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

interface LocationAnalysisTilesProps {
  location: ComputedMarketDataLocation
}

export const LocationAnalysisTiles: React.FC<LocationAnalysisTilesProps> = ({ location }) => {
  const t = useTranslations('pages.planningEvaluation.marketComparison');
  const topHeaderPadding = 2;

  const listResidentialLocDes = [
    t('tilesDetails.residentialLocation.bottomText.first'),
    t('tilesDetails.residentialLocation.bottomText.second'),
    t('tilesDetails.residentialLocation.bottomText.third'),
    t('tilesDetails.residentialLocation.bottomText.forth'),
    t('tilesDetails.residentialLocation.bottomText.fifth'),
  ];

  const listOfRatingTooltipTrans = [
    t('tilesDetails.residentialLocation.popUpText.first'),
    t('tilesDetails.residentialLocation.popUpText.second'),
    t('tilesDetails.residentialLocation.popUpText.third'),
    t('tilesDetails.residentialLocation.popUpText.forth'),
    t('tilesDetails.residentialLocation.popUpText.fifth'),
  ];

  return (
    <AccordionComponent headingLevel="h3" mb={8} title={t('crousalTilesHeader')}>
      <Grid
        templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
        gap={6}
      >
        <Box position="relative">
          <RiskClass
            isMarketpage
            explanationTextList={listResidentialLocDes}
            report={location.locationIndex}
            tooltip={listOfRatingTooltipTrans}
            heading={t('tilesDetails.residentialLocation.header')}
            headerPadding={topHeaderPadding}
          />
        </Box>
        <Box position="relative">
          <Rating
            isMarketpage
            explanationText={t('tilesDetails.localTrendSecond.bottomText')}
            report={{ rating: formatDecimal(location.localTrendOneYear, { unit: '%' }) }}
            heading={t('tilesDetails.localTrendSecond.header')}
            tooltip={t('tilesDetails.localTrendSecond.tooltip')}
            headerPadding={topHeaderPadding}
          />
        </Box>
        <Box position="relative">
          <Rating
            isMarketpage
            explanationText={t('tilesDetails.localTrendFirst.bottomText')}
            report={{ rating: formatDecimal(location.localTrendThreeYears, { unit: '%' }) }}
            heading={t('tilesDetails.localTrendFirst.header')}
            tooltip={t('tilesDetails.localTrendFirst.tooltip')}
            headerPadding={topHeaderPadding}
          />
        </Box>
        <Box position="relative">
          <Rating
            isMarketpage
            explanationText={t('tilesDetails.regionalTrend.bottomText')}
            report={{ rating: formatDecimal(location.regionalTrend, { unit: '%' }) }}
            heading={t('tilesDetails.regionalTrend.header')}
            tooltip={t('tilesDetails.regionalTrend.tooltip')}
            headerPadding={topHeaderPadding}
          />
        </Box>
      </Grid>
    </AccordionComponent>
  );
};

import { mittweidaFeToBeMapping } from 'mittweida/config/feToBeMappingKeys';
import { mittweidaStepData } from 'mittweida/config/stepData';
import { mittweidaStepSequence } from 'mittweida/config/stepSequence';
import { mittweidaSummaryFields } from 'mittweida/config/summaryFields';
import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { PortalConfig } from 'new/portals/state/portalConfigContext/types';

export const mittweidaPortalConfig: PortalConfig<MittweidaFieldTypes> = {
  stepSequence: mittweidaStepSequence,
  stepData: mittweidaStepData,
  summaryFields: mittweidaSummaryFields,
  feToBeMappingKeys: mittweidaFeToBeMapping,
};

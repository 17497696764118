import * as React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';

import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  onClick(): void;
  id: string;
}

export const AssignToRealEstateExpertSectionCard = ({ onClick, id }: Props) => {
  const t = useTranslations(
    'pages.inquiryDetails.dashboard.actions.assignInquiryToRealEstateExpert',
  );

  return (
    <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
      <GridItem>
        <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
          {t('title')}
        </HeadingComponent>
        <TextComponent mb={3}>{t('description')}</TextComponent>
        <ButtonComponent
          leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
          variant="primary"
          onClick={onClick}
        >
          {t('action')}
        </ButtonComponent>
      </GridItem>
    </Grid>
  );
};

import _get from 'lodash/get';

import {
  CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_EXISTING_RENT_PER_SQM,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_EXISTING_RENT_PER_SQM,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './mapProfitabilitySectionsToApi/utils';

const mapMarketValueToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  return {
    current: {
      building: {
        commercial_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM))) || initialValue,
        comparative_value_per_sqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING))) || initialValue,
        existing_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_EXISTING_RENT_PER_SQM))) || initialValue,
        residential_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM))) || initialValue,
      },
      unit: {
        commercial_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM))) || initialValue,
        comparative_value_per_sqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT))) || initialValue,
        existing_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_EXISTING_RENT_PER_SQM))) || initialValue,
        residential_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM))) || initialValue,
      }
    },
    future: {
      building: {
        commercial_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM))) || initialValue,
        comparative_value_per_sqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING))) || initialValue,
        existing_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_EXISTING_RENT_PER_SQM))) || initialValue,
        residential_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM))) || initialValue,
      },
      unit: {
        commercial_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM))) || initialValue,
        comparative_value_per_sqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT))) || initialValue,
        existing_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_EXISTING_RENT_PER_SQM))) || initialValue,
        residential_rent_per_sqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM))) || initialValue,
      }
    }
  }
}

export default mapMarketValueToApi;

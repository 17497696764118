import { useDisclosure } from '@chakra-ui/hooks';
import { useSelector } from 'react-redux';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';
import { isCustomerSelector } from 'store/user/selectors';

export const useArchiveDocumentsButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isCustomer = useSelector(isCustomerSelector);
  const isMittweida = useSelector(getInquiryFormTypeSelector) === InquiryType.profiMittweida;
  const shouldShowArchiveDocumentsButton = !isCustomer && isMittweida;
  return {
    isOpen,
    onClose,
    onOpen,
    shouldShowArchiveDocumentsButton,
  };
};

import { size } from 'lodash';
import { useSelector } from 'react-redux';

import { useMultipleFiles } from 'components/UploadBlock/hooks/useMultipleFiles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { IState } from 'store';
import { getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';
import { isCustomerSelector } from 'store/user/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import type { FileReclassificationButtonProps } from './FileReclassificationButtonContainer';
import { FileReclassificationButtonSelector } from './selectors';

const OTHER_PLACEHOLDER = '__OTHER__';

export const useFileReclassificationButton = ({
  isAssessmentFile,
  fileRequestId,
  isPrivateFile,
  fileId,
}: FileReclassificationButtonProps) => {
  const isCustomer = useSelector(isCustomerSelector);
  const isMittweida = useSelector(getInquiryFormTypeSelector) === InquiryType.profiMittweida;
  const isFileReclassificationEnabled = !isCustomer && isMittweida && !isAssessmentFile;

  const { reclassifyFile } = useMultipleFiles({ fileRequestId, isPrivateFile });
  const t = useTranslations('components.upload.reclassifyDocumentModal');
  // FIXME
  // @ts-ignore
  const { classificationOptions, file, fileRequest } = useSelector((state: IState) =>
    FileReclassificationButtonSelector(
      // FIXME
      // @ts-ignore
      state,
      { fileId, fileRequestId },
    ),
  );

  const handleReclassification = (classification: string) =>
    reclassifyFile
      ? reclassifyFile(fileId, classification === OTHER_PLACEHOLDER ? null : classification)
      : null;

  const options = [...classificationOptions, { label: t('other'), value: OTHER_PLACEHOLDER }];

  const isReclassifiable = size(options) > 1;
  const isPotentiallyIncorrectlyClassified =
    !isAssessmentFile &&
    !isPrivateFile &&
    file.classification != null &&
    file.classification !== fileRequest?.classification;

  const initialClassification = fileRequest?.classification || OTHER_PLACEHOLDER;

  return {
    isFileReclassificationEnabled,
    isReclassifiable,
    initialClassification,
    isPotentiallyIncorrectlyClassified,
    options,
    handleReclassification,
  };
};

import React from 'react';

import BeneficiaryOwnersSection from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/BeneficiaryOwnersSection';

interface BeneficialOwnerSectionSectionProps {
  sectionNumber: number;
}

export const BeneficialOwnerSection = (props: BeneficialOwnerSectionSectionProps) => {
  return <BeneficiaryOwnersSection {...props} />;
};

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import loadEditProfitabilitySection from 'api/CompeonReverseApi/operation/loadEditProfitabilitySections/actions';
import loadMarketValueDetails from 'api/CompeonReverseApi/operation/loadMarketValueDetails/actions';
import loadMwMarketData from 'api/CompeonReverseApi/operation/loadMwMarketData/actions';
import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import submitEditMarketValues from 'api/CompeonReverseApi/operation/submitEditMarketValues/action';
import submitEditMwMarketValues from 'api/CompeonReverseApi/operation/submitEditMwMarketValues/action';
import submitPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/submitPlanningEvaluationProfitabilityData/action';
import mapProfitabilityActionsToTypes, {
  submitProfitability
} from 'api/CompeonReverseApi/operation/submitProfitabilityValues/action';
import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import mapBankValuesAdditionalInformationToApi from 'modules/EditMarketValuesReeProfitability/mapBankValuesAdditionalInformationToApi';
import mapBankValuesObjectDataToApi from 'modules/EditMarketValuesReeProfitability/mapBankValuesObjectDataToApi';
import mapFinancingCriteriaToApi from 'modules/EditMarketValuesReeProfitability/mapFinancingCriteriaToApi';
import mapMarketValueToApi from 'modules/EditMarketValuesReeProfitability/mapMarketValueToApi';
import mapMarketValueToReeEditApi from 'modules/EditMarketValuesReeProfitability/mapMarketValueToReeEditApi';
import mapMittweidaProfitabilityToApi from 'modules/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToApi';
import mapMwMarketValueToApi from 'modules/EditMarketValuesReeProfitability/mapMwMarketValueToApi';
import mapProfitabilityToApi from 'modules/EditMarketValuesReeProfitability/mapProfitabilityToApi';
import {
  PROFITABILITY_RECORD_TYPE,
  MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
  PROFI_RECORD_TYPE,
  BANK_VALUES_RECORD_TYPE,
  FINANCING_CRITERIA_RECORD_TYPE,
  MW_MARKET_DATA_RECORD_TYPE,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

type LocationState = {
  editFormStructure: Array<EditMarketValue>;
  recordType: string;
};

export const useProjectCostingEditSubmit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { recordType } = location.state as LocationState;
  const inquiryId = useSelector(getInquiryIdSelector) || '';

  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    isPendingInitially: false,
  });
  const history = useHistory();

  const handleEditMarketValues = async (data: Record<string, unknown>) => {
    const mappedDataToPayload = mapMarketValueToApi(data);
    const { error } = await makeCall(submitEditMarketValues(mappedDataToPayload, inquiryId));
    if (!error) {
      const marketValue = await loadMarketValueDetails(
        // FIXME
        // @ts-ignore
        makeCall,
        inquiryId,
        PROFI_RECORD_TYPE.VALUE_API,
      );
      if (!marketValue.error) {
        history.goBack();
      }
    }
  };

  const handleEditMwMarketValues = async (data: Record<string, unknown>) => {
    const mappedDataToPayload = mapMwMarketValueToApi(data);
    const { error } = await makeCall(submitEditMwMarketValues(mappedDataToPayload, inquiryId));
    if (!error) {
      const marketValue = await loadMwMarketData(
        // FIXME
        // @ts-ignore
        makeCall,
        inquiryId,
        MW_MARKET_DATA_RECORD_TYPE,
        dispatch,
      );
      if (!marketValue.error) {
        history.goBack();
      }
    }
  };

  const handleReeEditMarketValues = async (data: Record<string, unknown>) => {
    const mappedDataToPayload = mapMarketValueToReeEditApi(data);
    const { error } = await makeCall(submitProfitability(inquiryId, { realEstateExpert: mappedDataToPayload }));
    if (!error) history.goBack()
  };

  const handleEditBankValuesObjectData = async (data: Record<string, unknown>) => {
    const mappedDataToPayload = mapBankValuesObjectDataToApi(data);
    const { error } = await makeCall(
      submitPlanningEvaluationProfitabilityData(mappedDataToPayload, inquiryId),
    );
    if (!error) {
      const marketValue = await loadPlanningEvaluationProfitabilityData(
        // FIXME
        // @ts-ignore
        makeCall,
        inquiryId,
        BANK_VALUES_RECORD_TYPE.OBJECT_DATA,
        dispatch,
      );
      if (!marketValue.error) {
        history.goBack();
      }
    }
  };

  const handleEditBankValuesAdditionalInformation = async (data: Record<string, unknown>) => {
    const mappedDataToPayload = mapBankValuesAdditionalInformationToApi(data);
    const { error } = await makeCall(
      submitPlanningEvaluationProfitabilityData(mappedDataToPayload, inquiryId),
    );
    if (!error) {
      const marketValue = await loadPlanningEvaluationProfitabilityData(
        // FIXME
        // @ts-ignore
        makeCall,
        inquiryId,
        BANK_VALUES_RECORD_TYPE.ADDITIONAL_INFORMATION,
        dispatch,
      );
      if (!marketValue.error) {
        history.goBack();
      }
    }
  };

  const handleEditFinancingCriteria = async (data: Record<string, unknown>) => {
    const mappedDataToPayload = mapFinancingCriteriaToApi(data);
    const { error } = await makeCall(
      submitPlanningEvaluationProfitabilityData(mappedDataToPayload, inquiryId),
    );
    if (!error) {
      const marketValue = await loadPlanningEvaluationProfitabilityData(
        // FIXME
        // @ts-ignore
        makeCall,
        inquiryId,
        FINANCING_CRITERIA_RECORD_TYPE,
        dispatch,
      );
      if (!marketValue.error) {
        history.goBack();
      }
    }
  };

  const handleEditProfitability = async (
    data: Record<string, unknown>,
    sectionType: PROFITABILITY_RECORD_TYPE,
  ) => {
    let mappedDataToPayload = mapProfitabilityToApi(data)[sectionType];
    if (mappedDataToPayload) {
      const { error } = await makeCall(
        mapProfitabilityActionsToTypes(mappedDataToPayload, inquiryId)[sectionType],
      );
      if (!error) {
        const profitabilityValue = await loadEditProfitabilitySection(
          // FIXME
          // @ts-ignore
          makeCall,
          inquiryId,
          sectionType,
        );
        if (!profitabilityValue.error) {
          history.goBack();
        }
      }
    }
  };

  const handleEditMittweidaProfitability = async (
    data: Record<string, unknown>,
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
  ) => {
    const mappedDataToPayload = mapMittweidaProfitabilityToApi(data)[recordType];
    const { error } = await makeCall(
      submitPlanningEvaluationProfitabilityData(
        mappedDataToPayload as Record<string, unknown>,
        inquiryId,
      ),
    );
    if (!error) {
      setTimeout(async () => {
        const marketValue = await loadPlanningEvaluationProfitabilityData(
          // FIXME
          // @ts-ignore
          makeCall,
          inquiryId,
          recordType,
          dispatch,
        );
        if (!marketValue.error) {
          history.goBack();
        }
      }, 100);
    }
  };

  const handleEditFormSubmit = (data: Record<string, unknown>) => {
    switch (recordType) {
      case PROFI_RECORD_TYPE.VALUE_API:
        handleEditMarketValues(data);
        break;
      case PROFI_RECORD_TYPE.REE:
        handleReeEditMarketValues(data);
        break;
      case BANK_VALUES_RECORD_TYPE.OBJECT_DATA:
        handleEditBankValuesObjectData(data);
        break;
      case BANK_VALUES_RECORD_TYPE.ADDITIONAL_INFORMATION:
        handleEditBankValuesAdditionalInformation(data);
        break;
      case FINANCING_CRITERIA_RECORD_TYPE:
        handleEditFinancingCriteria(data);
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS,
        );
        break;
      case MW_MARKET_DATA_RECORD_TYPE:
        handleEditMwMarketValues(data);
        break;
      case PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION:
        handleEditProfitability(data, PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION);
        break;
      case PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS:
        handleEditProfitability(data, PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS);
        break;
      case PROFITABILITY_RECORD_TYPE.PRESALES_DATA:
        handleEditProfitability(data, PROFITABILITY_RECORD_TYPE.PRESALES_DATA);
        break;
      case PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS:
        handleEditProfitability(data, PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS);
        break;
      case PROFITABILITY_RECORD_TYPE.SALES_COST_RATE:
      case PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT:
        handleEditProfitability(data, PROFITABILITY_RECORD_TYPE.SALES_COST_RATE);
        break;
      case PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS:
        handleEditProfitability(data, PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS);
        break;
      default:
        return;
    }
  };

  return {
    handleEditFormSubmit,
  };
};

import * as React from 'react';

import { CheckIcon } from '@chakra-ui/icons';
import { Center, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import paths from 'constants/paths';
import {
  PRODUCT_KIND,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__LEASING,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFormValues } from 'store/hooks/useFormValues';
import { getCreatedInquiryId, getMeta } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { textStyle } from '../../../../theme/themeConstants';

const Header = () => {
  const t = useTranslations('pages.finalPage.heading');
  const {
    details: { fullName: partnerFullName },
  } = usePartnerConfig();

  return (
    <>
      <HeadingComponent as="h2" mb={2} textAlign="center">
        {t('header', { partnerFullName })}
      </HeadingComponent>
      <TextComponent textAlign="center" color="text.tertiary">
        {t('subheader')}
      </TextComponent>
    </>
  );
};

const TransmissionSuccessfulHeader = () => {
  const t = useTranslations('pages.finalPage.heading');
  const metaData = useSelector(getMeta);
  const product = useFormValues()[PRODUCT_KIND];
  const productMatch = product !== PRODUCT_KIND__LEASING && product !== PRODUCT_KIND__HIRE_PURCHASE;
  const inquiryId = useSelector(getCreatedInquiryId);

  return (
    <Center>
      <VStack>
        <HeadingComponent as="h3" variant="secondary" mb={4} color="green.500">
          {t('headerTransmission')}
        </HeadingComponent>
        <CheckIcon w={6} h={6} color="green.500" textAlign="center" />
        <TextComponent as="i" mb={2} textAlign="center" color="green.500">
          {t('subheaderQuestion')}
        </TextComponent>
        {metaData?.fastlaneEligible && !productMatch && (
          <>
            <TextComponent color="red.500" fontWeight={FONT_WEIGHT_BOLD} {...textStyle.sizeL}>
              {t('uploadOffer.header')}
            </TextComponent>
            <ButtonComponent
              as="a"
              href={paths.customer.inquiryDetails.documentExchange.replace(':id', inquiryId)}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              mb="4"
            >
              {t('uploadOffer.button', { roleSpecific: true })}
            </ButtonComponent>
          </>
        )}
        {metaData?.fastlaneEligible && (
          <TextComponent as="p" mb={2} textAlign="center">
            {t('subheader2')}
          </TextComponent>
        )}
      </VStack>
    </Center>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: TransmissionSuccessfulHeader,
  default: Header,
});

import * as React from 'react';

import { Box, GridItem, HStack, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import {
  getCompanyId,
  getCustomerConfirmationStatus,
  getInquiryCustomerName,
} from 'store/inquiryDetails/selectors';
import { isCustomerSelector } from 'store/user/selectors';
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListTerm,
} from 'theme/components/DescriptionList';
import { LinkComponent } from 'theme/components/Link';
import { useTranslations } from 'utils/hooks/useTranslations';

import CompeonInquiryState from '../CompeonInquiryState';
import CustomerConfirmationStatusLabel from '../ConfirmationStatus/CustomerConfirmationStatusLabel';
import { useSummaryCardData } from '../useSummaryCardData';

export const AdditionalInformationItem = () => {
  const t = useTranslations();
  const data = useSummaryCardData();
  const { selectedInquiryType } = useFormConfig();
  const isContract = data.lane === InquiryLane.contract;
  const isDefault = data.formType === InquiryType.default;
  const isDvag = selectedInquiryType === InquiryType.dvag;
  const isBfsService = data.formType === InquiryType.bfsService;
  const isCustomer = useSelector(isCustomerSelector);
  const companyId = useSelector(getCompanyId);
  const { customerFullName } = useSelector(getInquiryCustomerName);
  const customerConfirmationStatus = useSelector(getCustomerConfirmationStatus);

  const shouldShowCustomerConfirmationStatus =
    useConfig(CONFIG.SHOW_CUSTOMER_CONFIRMATION_STATUS) && !isCustomer;

  const customerConfirmationStatusLabel = (
    <HStack spacing={2}>
      <Box>{customerFullName}</Box>
      <CustomerConfirmationStatusLabel customerConfirmationStatus={customerConfirmationStatus} />
    </HStack>
  );

  return (
    <GridItem>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        position="relative"
        height="100%"
        paddingBottom={3}
        _before={{
          content: "''",
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '-32px',
          width: '1px',
          backgroundColor: 'background.darkGrey',
        }}
      >
        <VStack spacing="1rem" align="flex-start">
          <DescriptionList>
            <DescriptionListTerm>
              {t('pages.inquiryDetails.dashboard.summary.companyName')}
            </DescriptionListTerm>
            <DescriptionListDescription>
              {!isCustomer && !isDefault && !isDvag && !isBfsService ? (
                <LinkComponent
                  variant="secondary"
                  href={paths.operation.companies.overview(companyId)}
                >
                  {data.companyName}
                </LinkComponent>
              ) : (
                data.companyName
              )}
            </DescriptionListDescription>
          </DescriptionList>
          <Condition condition={shouldShowCustomerConfirmationStatus}>
            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.customerName')}
              </DescriptionListTerm>
              <DescriptionListDescription>
                {customerConfirmationStatusLabel}
              </DescriptionListDescription>
            </DescriptionList>
          </Condition>
          <Condition condition={!isBfsService}>
            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.status')}
              </DescriptionListTerm>
              <DescriptionListDescription>{data.status}</DescriptionListDescription>
            </DescriptionList>
          </Condition>

          <CompeonInquiryState />

          {!isDefault && !isDvag && !isCustomer && (
            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.inquiryType')}
              </DescriptionListTerm>
              <DescriptionListDescription>
                {isBfsService
                  ? data.status
                  : isContract
                  ? t('pages.inquiryDetails.dashboard.summary.inquiryDigitalContract')
                  : t('pages.inquiryDetails.dashboard.summary.inquiryTypeDigital')}
              </DescriptionListDescription>
            </DescriptionList>
          )}
        </VStack>
      </Box>
    </GridItem>
  );
};

import React from 'react';

import { Flex, SimpleGrid, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Condition from 'components/Condition';
import Spinner from 'components/Spinner';
import { FILTERS_FINANCING_ROLE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import FinancingRoleSelect from 'pages/operationPortal/PlanningEvaluation/SharedComponent/FinancingRoleSelect';
import ProjectCostingHeader from 'pages/operationPortal/PlanningEvaluation/SharedComponent/ProjectCostingHeader';
import UsageTypeRadio from 'pages/operationPortal/PlanningEvaluation/SharedComponent/UsageTypeRadio';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinancingDetails } from './FinancingDetails';
import { InvestmentPlan } from './InvestmentPlan';
import { LendingValue } from './LendingValue';
import { ProfitabilitySales } from './ProfitabilitySales';
import { PropertyDetails } from './PropertyDetails';
import { Rent } from './Rent';
import { Risk } from './Risk';
import { SourceOfFunds } from './SourceOfFunds';
import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';
import { getProfitabilityDataTopBoxes } from '../../store/selectors';
import { useUnitFormatter } from '../../utils';

interface ProfitabilityTopBoxItem {
  total_space: string;
  total_costs: string;
  calculated_debt_service: string;
  financing_amount: string;
}
export interface ProfitabilityTopBoxes {
  current: ProfitabilityTopBoxItem;
  future: ProfitabilityTopBoxItem;
}

export const ProfitabilityCalculation = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.profitabilityCalculation');

  const formatter = useUnitFormatter();

  const { financingRole, usageType } = useSelector(getMarketDataFilterLegacy);

  const topBoxes = useSelector(getProfitabilityDataTopBoxes);

  if (!topBoxes) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <VStack spacing={8} align="stretch">
      <ProjectCostingHeader heading={t('title')} subheading={''} />
      <Flex mb={10} align={'center'}>
        <UsageTypeRadio disableCurrentUsage={false} />
        <FinancingRoleSelect />
      </Flex>
      <SummaryCards>
        <SummaryCard
          caption={t('boxes.top.totalSpace')}
          value={formatter.sqm(topBoxes[usageType]?.total_space)}
        />
        <SummaryCard
          caption={t('boxes.top.totalCosts')}
          value={formatter.currency(topBoxes[usageType]?.total_costs)}
        />
        <SummaryCard
          caption={t('boxes.top.calculatedDebtService')}
          value={formatter.currency(topBoxes[usageType]?.calculated_debt_service)}
        />
        <SummaryCard
          caption={t('boxes.top.financingAmount')}
          value={formatter.currency(topBoxes[usageType].financing_amount)}
        />
      </SummaryCards>
      <SimpleGrid
        columns={{
          md: 1,
          lg: 2,
        }}
        gap={'3rem'}
        mb={'2rem'}
      >
        <FinancingDetails />
        <PropertyDetails />
      </SimpleGrid>
      <InvestmentPlan />
      <SourceOfFunds />
      <Condition
        condition={[FILTERS_FINANCING_ROLE.DEVELOPER, FILTERS_FINANCING_ROLE.PARTITION].includes(
          financingRole,
        )}
      >
        <ProfitabilitySales />
      </Condition>
      <Condition condition={[FILTERS_FINANCING_ROLE.DEVELOPER].includes(financingRole)}>
        <Risk />
      </Condition>
      <Condition
        condition={[FILTERS_FINANCING_ROLE.PARTITION, FILTERS_FINANCING_ROLE.STOCKHOLDER].includes(
          financingRole,
        )}
      >
        <Rent />
      </Condition>
      <LendingValue />
    </VStack>
  );
};

export default ProfitabilityCalculation;

import React from 'react';

import { DayPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { ValueRadioGroupWithField } from 'components/OnlineFactoringRadioGroup';
import { SelectWithField } from 'components/Selects/Select';
import Condition from 'modules/ConditionalSubform/Condition';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import { canShowSection } from 'modules/Inquiry/Form/formConditions';
import {
  BFS_SERVICE__MONTHLY_TURNOVER,
  BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  BFS_SERVICE__COMPANY_OTHER_INDUSTRY,
  PRE_FINANCING_PERIOD,
  PURPOSE_KIND_BFS,
  BFS_SERVICE__PURPOSE_KIND_OTHER,
} from 'modules/Inquiry/Form/formFields';
import {
  BFS_SERVICE_MAX_AMOUNT_VALUE,
  BFS_SERVICE_MIN_AMOUNT_VALUE,
} from 'modules/Inquiry/inquiry.constants';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { InquiryConstants } from 'schema/inquirySchema.constants';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useFormValues } from 'store/hooks/useFormValues';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import { BfsServiceDatePickerContainer } from './styles';
import { AmountWithField } from '../../Amount/Amount';

export const validateBFSServiceNeedSection = validateSectionFields([
  BFS_SERVICE__COMPANY_OTHER_INDUSTRY,
  BFS_SERVICE__MONTHLY_TURNOVER,
  BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING,
  PRE_FINANCING_PERIOD,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
]);

const BFSServiceNeedSection = () => {
  const t = useTranslations();
  const tFields = useTranslations('pages.bfsService.sections');
  const industrySoftwareForBillingOptions = useInquirySelectOptions(
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  );
  const servicePurchasedInvoicesPaidOutOnOptions = useInquirySelectOptions(
    BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  );
  const { required, minPrice, maxPrice } = useFieldValidators();
  const formValues = useFormValues();
  const companyIndustryIsOthers = formValues[PURPOSE_KIND_BFS] === BFS_SERVICE__PURPOSE_KIND_OTHER;

  const allowedInvoicPurchaseDates = () => {
    const months = 36;
    const allowedDates: Date[] = [];
    const today = new Date();
    let currentMonth = today.getMonth();
    let currentYear = today.getFullYear();

    for (let i = 0; i < months; i++) {
      if (currentMonth > 12) {
        currentMonth = 1;
        currentYear = currentYear + 1;
      }
      allowedDates.push(new Date(currentMonth + '/01/' + currentYear));
      allowedDates.push(new Date(currentMonth + '/15/' + currentYear));
      currentMonth = currentMonth + 1;
    }
    return allowedDates;
  };

  return (
    <FormSection title={' '} sectionNumber={0}>
      <Condition
        condition={canShowSection(BFS_SERVICE__COMPANY_OTHER_INDUSTRY)}
        isBusinessCondition
      >
        <FormRow>
          <InputWithField
            name={BFS_SERVICE__COMPANY_OTHER_INDUSTRY}
            captionTooltip={tFields('factoringNeeds.fields.companyIndustry.tooltip')}
          />
        </FormRow>
      </Condition>
      <FormRow>
        <AmountWithField
          name={BFS_SERVICE__MONTHLY_TURNOVER}
          tooltip={tFields('factoringAndFactomatNeeds.fields.monthlyTurnover.tooltip')}
          validate={combineValidators(
            required,
            minPrice(BFS_SERVICE_MIN_AMOUNT_VALUE),
            maxPrice(BFS_SERVICE_MAX_AMOUNT_VALUE),
          )}
        />
      </FormRow>
      <FormRow>
        <BfsServiceDatePickerContainer>
          <DayPickerWithField
            name={BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE}
            minDate={InquiryConstants.TEN_DAY_FROM_NOW}
            includeDates={allowedInvoicPurchaseDates()}
            captionTooltip={tFields(
              'factoringAndFactomatNeeds.fields.firstInvoicePurchaseDate.tooltip',
            )}
            validate={combineValidators(required)}
            isClearable
          />
        </BfsServiceDatePickerContainer>
      </FormRow>
      <FormRow>
        <SelectWithField
          name={BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING}
          placeholder={t('placeholders.pleaseChoose')}
          options={industrySoftwareForBillingOptions}
          tooltip={tFields('factoringAndFactomatNeeds.fields.softwareBillingIndustry.tooltip')}
          validate={required}
        />
      </FormRow>
      <Condition
        condition={canShowSection(BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING)}
        isBusinessCondition
      >
        <FormRow>
          <InputWithField
            name={BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING}
            placeholder={tFields(
              'factoringAndFactomatNeeds.fields.otherSoftwareBillingIndustry.placeholder',
            )}
            captionTooltip={tFields(
              'factoringAndFactomatNeeds.fields.otherSoftwareBillingIndustry.tooltip',
            )}
          />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(PRE_FINANCING_PERIOD)} isBusinessCondition>
        <FormRow>
          <ValueRadioGroupWithField
            name={PRE_FINANCING_PERIOD}
            validate={combineValidators(required)}
          />
        </FormRow>
      </Condition>
      <Condition
        condition={canShowSection(BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON)}
        isBusinessCondition
      >
        <FormRow>
          <SelectWithField
            name={BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON}
            placeholder={t('placeholders.pleaseChoose')}
            options={servicePurchasedInvoicesPaidOutOnOptions}
            tooltip={tFields('factoringAndFactomatNeeds.fields.purchasedInvoicesPaidOn.tooltip')}
            optional={companyIndustryIsOthers}
            validate={companyIndustryIsOthers ? undefined : combineValidators(required)}
          />
        </FormRow>
      </Condition>
    </FormSection>
  );
};

export default BFSServiceNeedSection;

import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import loadMwMarketData from 'api/CompeonReverseApi/operation/loadMwMarketData/actions';
import paths from 'constants/paths';
import mapMarketDetailsToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapMarketDetailsToEdit';
import { MW_MARKET_DATA_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { getMarketDataFilterLegacy } from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { FieldUnitType, unitFormatters } from '../../../../inquiry/steps/summary/units';
import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';
import { getMarketComparisonObjectEvaluation } from '../../store/selectors';

interface MarketComparisonObjectEvaluation {
  duration: string;
  sales_price: string;
  duration_max: string;
  duration_min: string;
  commercial_rent: string;
  sales_price_max: string;
  sales_price_min: string;
  residential_rent: string;
  residential_rent_min: string;
  residential_rent_max: string;
  existing_residential_rent: string;
  existing_residential_rent_min: string;
  existing_residential_rent_max: string;
}

type ObjectEvaluationKey = keyof MarketComparisonObjectEvaluation;
type mappedUnitObjectKey = keyof Pick<
  MarketComparisonObjectEvaluation,
  'sales_price' | 'residential_rent' | 'existing_residential_rent' | 'commercial_rent' | 'duration'
>;

export interface MarketComparisonObjectEvaluationValues {
  future: {
    unit: MarketComparisonObjectEvaluation;
    building: MarketComparisonObjectEvaluation;
  };
  current: {
    unit: MarketComparisonObjectEvaluation;
    building: MarketComparisonObjectEvaluation;
  };
}

const orderedArray = [
  'sales_price',
  'residential_rent',
  'existing_residential_rent',
  'commercial_rent',
  'duration',
];

const mappedUnitObject: Record<
  mappedUnitObjectKey,
  { displayUnit: string; formattingUnit: FieldUnitType }
> = {
  sales_price: { displayUnit: '€/m²', formattingUnit: 'currency' },
  residential_rent: { displayUnit: '€/m²', formattingUnit: 'currency' },
  existing_residential_rent: { displayUnit: '€/m²', formattingUnit: 'currency' },
  commercial_rent: { displayUnit: '€/m²', formattingUnit: 'currency' },
  duration: { displayUnit: 'Tage', formattingUnit: 'days' },
};

const formatValue = (value: string, formatter: Function | undefined) => {
  if (formatter) {
    const formattedValue = formatter(value, false);
    if (formattedValue == null) {
      return '-';
    }
    return formattedValue;
  }
  return '-';
};

const useMarketComparisonObjectEvaluationMapping = (
  ObjectEvaluationData: MarketComparisonObjectEvaluation,
) => {
  const t = useTranslations(
    `pages.planningEvaluation.mittweida.pages.marketComparison.tables.objectEvaluation.rowNames`,
  );

  return orderedArray.map((key) => {
    const value = ObjectEvaluationData[key as ObjectEvaluationKey];
    const valueMin = ObjectEvaluationData[`${key}_min` as ObjectEvaluationKey];
    const valueMax = ObjectEvaluationData[`${key}_max` as ObjectEvaluationKey];

    const formatter = unitFormatters[mappedUnitObject[key as mappedUnitObjectKey].formattingUnit];
    return {
      id: key,
      label: t(key),
      unit: mappedUnitObject[key as mappedUnitObjectKey].displayUnit,
      value: formatValue(value, formatter),
      valueMin: formatValue(valueMin, formatter),
      valueMax: formatValue(valueMax, formatter),
    };
  });
};
const ObjectEvaluation = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.marketComparison.tables.objectEvaluation',
  );
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { editMarketValueDetails } = paths.operation.inquiryDetails.planningEvaluation;
  const { onEdit } = useEditFormConfig({
    pathToEdit: editMarketValueDetails(inquiryId),
    recordType: MW_MARKET_DATA_RECORD_TYPE,
    loadData: loadMwMarketData,
    editFormStructure: mapMarketDetailsToEdit(),
  });
  const objectEvaluationData: MarketComparisonObjectEvaluationValues = useSelector(
    getMarketComparisonObjectEvaluation,
  );
  const filterData = useSelector(getMarketDataFilterLegacy);

  const tableData = useMarketComparisonObjectEvaluationMapping(
    objectEvaluationData?.[filterData?.usageType]?.[
      filterData?.saleType === 'building' ? 'building' : 'unit'
    ],
  );

  const objectEvaluationTable = usePortalTable({
    data: tableData,
    headers: [
      { key: 'label', text: '' },
      { key: 'unit', text: t('columnNames.unit') },
      { key: 'value', text: t('columnNames.value') },
      { key: 'valueMin', text: t('columnNames.valueMin') },
      { key: 'valueMax', text: t('columnNames.valueMax') },
    ],
  });

  return (
    <Box paddingTop={10}>
      <Card title={t('title')} isExpandable={true} isExpanded={true} onEdit={onEdit}>
        <Box>
          <PortalTable
            {...objectEvaluationTable}
            columnStylesMap={{ value: { color: 'brand.default' } }}
            headerStylesMap={{
              value: { width: '19%', color: 'brand.default' },
              valueMin: { width: '19%' },
              valueMax: { width: '19%' },
              unit: { width: '19%' },
              label: { width: '24%' },
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default ObjectEvaluation;

import { FormApi } from 'final-form';
import { useForm, useFormState } from 'react-final-form';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';

import { ValidationMapType, FieldTypes } from './common/types';
import { shouldShowField } from './hooks/useCanShowField';

export interface ValidateSectionFunction<Type> {
  (form: FormApi, values: FieldTypes<Type>, store: any):
    | boolean
    | ((value: any, values: FieldTypes<Type>) => boolean);
}

type ValidateField<Type> = {
  field: string;
  values: FieldTypes<Type>;
  form: FormApi;
  inquiryType: Type;
};

type ValidateArrayFields<Type> = {
  arrayValues: Array<any>;
  parentName: string;
  values: FieldTypes<Type>;
  fieldsWithoutParent: string[];
  form: FormApi;
  inquiryType: Type;
};

export const validateField = <Type extends InquiryType>({
  field,
  values,
  form,
  inquiryType,
}: ValidateField<Type>) => {
  return validateFields<Type>({ fields: [field], values, form, inquiryType });
};

type ValidateFields<Type> = {
  fields: string[];
  values: FieldTypes<Type>;
  form: FormApi;
  inquiryType: Type;
};

export const validateArrayFields = <Type extends InquiryType>({
  arrayValues,
  parentName,
  fieldsWithoutParent,
  form,
  values,
  inquiryType,
}: ValidateArrayFields<Type>) => {
  const fieldNames: Array<string> = [];
  fieldsWithoutParent.forEach((fieldName) => {
    fieldNames.push(...arrayValues.map((_, index) => `${parentName}[${index}].${fieldName}`));
  });

  return validateFields<Type>({ fields: fieldNames, values, form, inquiryType });
};

export const validateFields = <Type extends InquiryType>({
  fields,
  values,
  form,
  inquiryType,
}: ValidateFields<Type>) => {
  const visibleFields = fields.filter((name) =>
    shouldShowField<FieldTypes<Type>, Type>(name, values, form, inquiryType),
  );
  const fieldStates = visibleFields.map((name) => {
    return form.getFieldState(name);
  });
  return fieldStates.every((field) => field?.valid);
};

export const useValidateFields = <Type extends InquiryType>({
  fields,
  inquiryType,
}: {
  fields: Array<string>;
  inquiryType: Type;
}) => {
  const form = useForm();
  const { values } = useFormState<FieldTypes<Type>>();
  return validateFields({ fields, values, form, inquiryType });
};

export const useValidateArrayFields = <Type extends InquiryType>({
  arrayValues,
  parentName,
  fieldsWithoutParent,
  inquiryType,
}: Pick<
  ValidateArrayFields<Type>,
  'arrayValues' | 'parentName' | 'fieldsWithoutParent' | 'inquiryType'
>) => {
  const form = useForm();
  const { values } = useFormState<FieldTypes<Type>>();

  return validateArrayFields<Type>({
    arrayValues,
    parentName,
    fieldsWithoutParent,
    values,
    form,
    inquiryType,
  });
};

// export const useValidateField = <FieldTypes>({ field }: { field: string }) => {
//   const form = useForm();
//   const { values } = useFormState<FieldTypes>();
//   return validateFields({ fields: [field], values, form, inquiryType: InquiryType.profiMittweida });
// };

type ValidateFieldValidationMap<Type> = {
  validationMap: ValidationMapType<FieldTypes<Type>>;
  inquiryType: Type;
};

type ValidateFieldValidationMapArgs<Type> = {
  form: FormApi;
  values: FieldTypes<Type>;
} & ValidateFieldValidationMap<Type>;
/**
 * Don't forget to put your map in src/modules/Inquiry/inquiryFieldValidation/validateField.ts!
 */
export const validateFieldValidationMap = <Type extends InquiryType>({
  validationMap,
  form,
  values,
  inquiryType,
}: ValidateFieldValidationMapArgs<Type>) => {
  const fieldsToValidate = Object.keys(validationMap).map((key) => key);
  return validateFields<Type>({
    fields: fieldsToValidate,
    values,
    form,
    inquiryType: inquiryType ?? InquiryType.profiMittweida,
  });
};

export const useValidateFieldValidationMap = <
  Type extends InquiryType = InquiryType.profiMittweida,
>({
  validationMap,
  inquiryType,
}: ValidateFieldValidationMap<Type>) => {
  const fieldsToValidate = Object.keys(validationMap).map((key) => key);
  return useValidateFields<Type>({
    fields: fieldsToValidate,
    inquiryType: inquiryType ?? InquiryType.profiMittweida,
  });
};

import { useCallback } from 'react';

import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { editMarketValueReeProfitabillityLabelsMap } from 'modules/EditMarketValuesReeProfitability/editMarketValueReeProfitabillityLabel';
import { editMittweidaProfitabilityLabelMap } from 'modules/EditMarketValuesReeProfitability/editMittweidaProfitabilityLabel';
import {
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  FAVORED_DECISION_DATE,
  EXCLUDING_QUESTION_FIRST,
  PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
} from 'modules/Inquiry/Form/formFields';
import {
  LIQUIDITY_MONTHS_FOR_SMALL_COMPANY,
  LIQUIDITY_MONTHS_FOR_BIG_COMPANY,
} from 'modules/Inquiry/inquiry.constants';
import { additionalFieldsTranslations } from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/additionalKeyTranslations';
import { beneficiaryOwnerDetailsLabelsMap } from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/beneficiaryDetailsLabels';
import { companyDetailsLabelMap } from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/companyDetailsLabels';
import {
  bfsServiceFinancingNeedLabelsMap,
  financingNeedLabelsMap,
} from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/financingNeedLabels';
import {
  bfsSPeopleDetailsLabelsMap,
  peopleDetailsLabelsMap,
} from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/peopleDetailsLabels';
import {
  bfsServiceRequestDetailsLabels,
  requestDetailsLabelsMap,
} from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/requestDetailsLabels';
import { userProfileLabelsMap } from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/userProfileLabels';
import { onlineFactoringLabelsMap } from 'onlinefactoring/labels';
import { useTranslations } from 'utils/hooks/useTranslations';

import { bfsContactPersonLabelsMap } from './labelTranslator/bfsContactPersonLabels';
import { contractDetailsLabelsMap } from './labelTranslator/contractDetailsLabels';
import { mittweidaLabelsMap } from '../../../mittweida/i18n/labels';
import { mittweidaOperationLabelsMap } from '../../../mittweida/portals/operations/labels';
import { mmvLabels } from '../../../mmv/i18n/labels';
import { quickCheckLabelsMap } from '../../../pages/operationPortal/PlanningEvaluation/QuickCheck/QuickCheckLabelsMap';
import {
  extractNestedFieldName,
  removeBracketsFromArrayFields,
} from '../../../utils/form/getFieldName';
import { PROJECT_FINANCING__OBJECT_USAGE } from '../Form/formFields';
import { InquiryType } from '../Inquiry.type';

const temporaryMonthNumber = `${LIQUIDITY_MONTHS_FOR_SMALL_COMPANY}/${LIQUIDITY_MONTHS_FOR_BIG_COMPANY}`;

const roleSpecificTranslations = [
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  FAVORED_DECISION_DATE,
  EXCLUDING_QUESTION_FIRST,
];

export type LabelMapType = { [key: string]: string };

const DEFAULT_LABELS: LabelMapType = {
  ...financingNeedLabelsMap,
  ...companyDetailsLabelMap,
  ...requestDetailsLabelsMap,
  ...userProfileLabelsMap,
  ...peopleDetailsLabelsMap,
  ...additionalFieldsTranslations,
  ...editMarketValueReeProfitabillityLabelsMap,
  ...quickCheckLabelsMap,
};

const MITTWEIDA_LABELS: LabelMapType = {
  ...mittweidaLabelsMap,
  ...mittweidaOperationLabelsMap,
  ...editMittweidaProfitabilityLabelMap,
};

const LEASEPLAN_LABELS: LabelMapType = {
  ...contractDetailsLabelsMap,
  ...beneficiaryOwnerDetailsLabelsMap,
  ...peopleDetailsLabelsMap,
};

const ONLINEFACTORING_LABELS: any = {
  ...onlineFactoringLabelsMap,
  ...companyDetailsLabelMap,
  ...userProfileLabelsMap,
  ...peopleDetailsLabelsMap,
};

const BFS_LABELS: LabelMapType = {
  ...financingNeedLabelsMap,
  ...companyDetailsLabelMap,
  ...requestDetailsLabelsMap,
  ...userProfileLabelsMap,
  ...peopleDetailsLabelsMap,
  ...additionalFieldsTranslations,
  ...bfsContactPersonLabelsMap,
};

const BFS_SERVICE_LABELS: LabelMapType = {
  ...DEFAULT_LABELS,
  ...beneficiaryOwnerDetailsLabelsMap,
  ...bfsServiceFinancingNeedLabelsMap,
  ...bfsServiceRequestDetailsLabels,
  ...bfsSPeopleDetailsLabelsMap,
};

const MMV_LABELS: LabelMapType = {
  ...mmvLabels,
};

const inquiryTypeLabelsMap: { [key in InquiryType]?: LabelMapType } = {
  [InquiryType.default]: DEFAULT_LABELS,
  [InquiryType.profiRLL]: DEFAULT_LABELS,
  [InquiryType.profiMittweida]: MITTWEIDA_LABELS,
  [InquiryType.onlinefactoring]: ONLINEFACTORING_LABELS,
  [InquiryType.leaseplan]: LEASEPLAN_LABELS,
  [InquiryType.bfs]: BFS_LABELS,
  [InquiryType.bfsService]: BFS_SERVICE_LABELS,
  [InquiryType.mmv]: MMV_LABELS,
};

const commonInquiryTypeFinancingKeys = [
  ...Object.keys(financingNeedLabelsMap),
  PROJECT_FINANCING__OBJECT_USAGE,
];

const inquiryTypeFinancingKeys: { [key in InquiryType]?: string[] } = {
  default: commonInquiryTypeFinancingKeys,
  [InquiryType.profiRLL]: [
    ...commonInquiryTypeFinancingKeys,
    PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
  ],
  [InquiryType.onlinefactoring]: [...Object.keys(onlineFactoringLabelsMap)],
  [InquiryType.bfsService]: Object.keys(bfsServiceFinancingNeedLabelsMap),
};

export const financingNeedKeys = (type: InquiryType): string[] =>
  inquiryTypeFinancingKeys[type] ?? inquiryTypeFinancingKeys.default!;

export const contractDetailsKeys = Object.keys(contractDetailsLabelsMap);
export const companyDetailsKeys = Object.keys(companyDetailsLabelMap);

export const useInquiryLabelTranslator = () => {
  const t = useTranslations();
  const {
    details: { fullName: partnerFullName },
  } = usePartnerConfig();
  const { selectedInquiryType } = useFormConfig();
  const labels = inquiryTypeLabelsMap[selectedInquiryType] ?? DEFAULT_LABELS;

  return useCallback(
    // FIXME
    // @ts-ignore
    (fieldKey) => {
      /*
       * For the old inquiry flow it is enough to extract the nested field name for field arrays.
       * This is how a nested field name looks like: 'project-financing-object-usages-future[0].type-of-use-future'
       * but the label of the field is just 'type-of-use-future
       */
      const oldFieldNamingScheme = extractNestedFieldName(fieldKey);

      /*
       * For the new inquiry flow the fieldName contains the parent of the field name but should not use the indexed
       * bracket notation that is used for array fields. This is how a nest field name looks like in the new flow:
       * 'financingNeedPage.objectInformationSection.futureUsage[0].typeOfUse' but the actual label here is:
       * 'financingNeedPage.objectInformationSection.futureUsage.typeOfUse'
       */
      const newFieldNamingScheme = removeBracketsFromArrayFields(fieldKey);

      /*
       * If we do not find a label with the new field name schema we look into the labels map with the old naming schema.
       * One of this should always be successful or else there is another error why a label is not found.
       */
      const label = labels[newFieldNamingScheme] || labels[oldFieldNamingScheme];

      const roleSpecific = roleSpecificTranslations.includes(newFieldNamingScheme)
        ? { roleSpecific: true }
        : undefined;
      if (!label) {
        return '';
      }
      return t(label, {
        months: temporaryMonthNumber,
        legalName: partnerFullName,
        ...roleSpecific,
      });
    },
    [labels, partnerFullName, t],
  );
};

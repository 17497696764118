import React from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import NavigationBarComponent from 'components/NavigationBar';
import { NavigationLink } from 'components/NavigationBar/styles';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';

import { 
  NAVIGATION_DASHBOARD_LINK,
  NAVIGATION_DETAILS_LINK,
  NAVIGATION_DOCUMENTS_LINK,
  NAVIGATION_BACK_BUTTON,
 } from '../../../../modules/Inquiry/Form/formFields'

const NavigationBar = ({ id }) => {
  const { formatMessage } = useIntl();
  const { selectedInquiryType } = useFormConfig();

  const dashboardNavigation = [
    <NavigationLink
      key="dashboard"
      to={paths.customer.inquiryDetails.dashboard.replace(':id', id)}
      data-testid={NAVIGATION_DASHBOARD_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.dashboard' })}
    </NavigationLink>,
    <NavigationLink
      key="documentExchange"
      to={paths.customer.inquiryDetails.documentExchange.replace(':id', id)}
      data-testid={NAVIGATION_DOCUMENTS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.documentExchange' })}
    </NavigationLink>,
    <NavigationLink
      key="details"
      to={paths.customer.inquiryDetails.details.replace(':id', id)}
      data-testid={NAVIGATION_DETAILS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.details' })}
    </NavigationLink>,
  ];

  const leaseplanNavigation = [
    <NavigationLink
      key="dashboard"
      to={paths.customer.inquiryDetails.dashboard.replace(':id', id)}
      data-testid={NAVIGATION_DASHBOARD_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.dashboard' })}
    </NavigationLink>,
    <NavigationLink
      key="documentExchange"
      to={paths.customer.inquiryDetails.documentExchange.replace(':id', id)}
      data-testid={NAVIGATION_DOCUMENTS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.documentExchange' })}
    </NavigationLink>,
  ];

  const mainNavigation = [
    <NavigationLink
      key="dashboard"
      to={paths.customer.inquiryDetails.dashboard.replace(':id', id)}
      data-testid={NAVIGATION_DASHBOARD_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.dashboard' })}
    </NavigationLink>,
    <NavigationLink
      key="details"
      to={paths.customer.inquiryDetails.details.replace(':id', id)}
      data-testid={NAVIGATION_DETAILS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.details' })}
    </NavigationLink>,
    <NavigationLink
      key="documentExchange"
      to={paths.customer.inquiryDetails.documentExchange.replace(':id', id)}
      data-testid={NAVIGATION_DOCUMENTS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.documentExchange' })}
    </NavigationLink>,
  ];

  const DVAGNavigation = [
    <NavigationLink
      key="details"
      to={paths.customer.inquiryDetails.details.replace(':id', id)}
      data-testid={NAVIGATION_DETAILS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.details' })}
    </NavigationLink>,
    <NavigationLink
      key="documentExchange"
      to={paths.customer.inquiryDetails.documentExchange.replace(':id', id)}
      data-testid={NAVIGATION_DOCUMENTS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.documentExchange' })}
    </NavigationLink>,
  ];

  const bfsServiceNavigation = [
    <NavigationLink
      key="dashboard"
      to={paths.customer.inquiryDetails.dashboard.replace(':id', id)}
      data-testid={NAVIGATION_DASHBOARD_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.dashboard' })}
    </NavigationLink>,
    <NavigationLink
      key="documentExchange"
      to={paths.customer.inquiryDetails.documentExchange.replace(':id', id)}
      data-testid={NAVIGATION_DOCUMENTS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.documentExchange' })}
    </NavigationLink>,
    <NavigationLink
      key="details"
      to={paths.customer.inquiryDetails.details.replace(':id', id)}
      data-testid={NAVIGATION_DETAILS_LINK}
    >
      {formatMessage({ id: 'pages.inquiryDetails.navigation.details' })}
    </NavigationLink>,
  ];

  const inquiryTypeNavigation = {
    [InquiryType.bigPicture]: dashboardNavigation,
    [InquiryType.onlinefactoring]: dashboardNavigation,
    [InquiryType.leaseplan]: leaseplanNavigation,
    [InquiryType.bfsService]: bfsServiceNavigation,
    [InquiryType.dvag]: DVAGNavigation,
    default: mainNavigation,
  };

  const additionalNavigation = (
    <NavigationLink
      key="back"
      exact
      to={paths.customer.inquiryList}
      data-testid={NAVIGATION_BACK_BUTTON}
    >
      <ArrowLeftIcon boxSize={6} mr={2} />
      {formatMessage({ id: 'buttons.back' })}
    </NavigationLink>
  );

  return (
    <NavigationBarComponent
      mainNavigation={inquiryTypeNavigation[selectedInquiryType] || inquiryTypeNavigation.default}
      additionalNavigation={additionalNavigation}
    />
  );
};

NavigationBar.propTypes = {
  id: PropTypes.string.isRequired,
};

export default NavigationBar;

import { FormApi } from 'final-form';
import { useForm, useFormState } from 'react-final-form';

import { mittweidaConditions } from 'mittweida/inquiry/conditions';
import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { conditions as mmvConditions } from 'mmv/inquiry/conditions';
import {InquiryType} from "modules/Inquiry/Inquiry.type";

type Validations<FieldTypes> = Record<string, ({ values, form }: { values: FieldTypes; form: FormApi }) => boolean>

const CONDITIONS_MAP: Record<string, Validations<any>> = {
  [InquiryType.profiMittweida]: mittweidaConditions,
  [InquiryType.mmv]: mmvConditions,
}

export function shouldShowField<FieldTypes, Type extends InquiryType>(name: string, values: FieldTypes, form: FormApi, inquiryType: Type): boolean {
  const conditionMap = CONDITIONS_MAP[inquiryType]
  const condition = conditionMap?.[name];
  return condition ? condition({ values, form }) : true;
}

export function useCanShowField<FieldTypes = MittweidaFieldTypes>(name: string, inquiryType = InquiryType.profiMittweida) {
  const { values } = useFormState<FieldTypes>();
  const form = useForm();
  return shouldShowField(name, values, form, inquiryType);
}

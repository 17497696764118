import {
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';
import { COMPANY_FIELDS } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { useFieldChangedValue } from 'store/hooks/useFormValues';

export const getSelectedCompany = (companyInSystem: any, companyAssignedToUser: any) => {
  if (
    companyAssignedToUser &&
    companyAssignedToUser !== COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  ) {
    return companyAssignedToUser;
  }

  return companyInSystem;
};

export const useGetSelectedCompany = () => {
  const companyInSystem = useFieldChangedValue<unknown>(COMPANY_DETAILS_COMPANY, null);
  const companyAssignedToUser = useFieldChangedValue<unknown>(
    COMPANY_DETAILS_EXISTING_USER_COMPANY,
    null,
  );

  return getSelectedCompany(companyInSystem, companyAssignedToUser);
};

export const useGetSelectedCompanyParam = (
  param: (typeof COMPANY_FIELDS)[keyof typeof COMPANY_FIELDS],
) => {
  const company = useGetSelectedCompany();
  return company && company[param];
};

import { useCallback, useState } from 'react';

import { saveAs } from 'file-saver';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import type { ArchiveDocumentsFormValues } from 'components/ArchiveDocuments/components/ArchiveDocumentsModal';
import { ALLOWED_MIME_TYPES, MAX_FILES, MAX_FILE_SIZE } from 'constants/file';
import { mapApiFile, mapApiPrivateFile } from 'shared/documentExchange/documentExchange.service';
import { useToasts } from 'shared/hooks/useToasts';
import {
  removeFileAction,
  removePrivateFileAction,
  removeAssessmentFileAction,
  updateFileClassificationAction,
  updatePrivateFileClassificationAction,
} from 'store/documentExchange/documentExchange.actions';
import {
  addFileAction,
  addPrivateFileAction,
  uploadFileToFileRequestAction,
} from 'store/documentExchange/documentExchange.actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useFilesEndpoint } from './useFilesEndpoint';
import { useNewUploadFile } from './useNewUploadFile';
import { useThrottleRequests } from './useThrottledFileUpload';

const useUploadFile = (inquiryId: string, fileRequestId?: string, isPrivateFile?: boolean) => {
  const dispatch = useDispatch();
  const endpoint = isPrivateFile
    ? endpoints.INQUIRIES.PRIVATE_FILES.LIST
    : endpoints.INQUIRIES.FILES.LIST;
  const url = endpoint.compose({ params: { id: inquiryId } });
  const { startUpload } = useNewUploadFile(url);

  return useCallback(
    async (file: File) => {
      const uploadedFile = await startUpload(
        file,
        fileRequestId ? { file_upload_request_id: fileRequestId } : {},
      );
      if (fileRequestId)
        dispatch(uploadFileToFileRequestAction(fileRequestId, mapApiFile(uploadedFile)));
      else if (isPrivateFile) dispatch(addPrivateFileAction(mapApiPrivateFile(uploadedFile)));
      else dispatch(addFileAction(mapApiFile(uploadedFile)));
    },
    [startUpload, dispatch, fileRequestId, isPrivateFile],
  );
};

const usePreviewFile = (isPrivateFile?: boolean, isAssessmentFile?: boolean) => {
  const { makeCall } = useDispatchApiCall();
  const endpoint = useFilesEndpoint({ isPrivateFile, isAssessmentFile, route: 'DOWNLOAD' });

  return useCallback(
    async (id: string | unknown) => {
      const res = await makeCall(
        callReverseApi({
          url: endpoint.compose({ params: { id: id as string } }),
          method: API_METHODS.GET,
          responseType: 'blob',
        }),
      );
      const blob = new Blob([res.payload.data], {
        type: res.payload.headers['content-type'],
      });
      window.open(URL.createObjectURL(blob));
    },
    [makeCall, endpoint],
  );
};

const useDownloadFile = (isPrivateFile?: boolean, isAssessmentFile?: boolean) => {
  const t = useTranslations('components.download');
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    errorMessage: t('error'),
  });
  const endpoint = useFilesEndpoint({ isPrivateFile, isAssessmentFile, route: 'DOWNLOAD' });
  return useCallback(
    async (id: string, fileName: string) => {
      const res = await makeCall(
        callReverseApi({
          url: endpoint.compose({ params: { id: id as string } }),
          method: API_METHODS.GET,
          responseType: 'blob',
        }),
      );
      const blob = new Blob([res.payload.data], {
        type: res.payload.headers['content-type'],
      });
      saveAs(blob, fileName);
    },
    [makeCall, endpoint],
  );
};

const useDeleteFile = (isPrivateFile?: boolean, isAssessmentFile?: boolean) => {
  const t = useTranslations('components.upload');
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    errorMessage: t('removeError'),
  });
  const endpoint = useFilesEndpoint({ isPrivateFile, isAssessmentFile, route: 'DETAILS' });

  const action = isPrivateFile
    ? removePrivateFileAction
    : isAssessmentFile
    ? removeAssessmentFileAction
    : removeFileAction;

  const dispatch = useDispatch();

  return useCallback(
    async (id: string) => {
      await makeCall(
        callReverseApi({
          url: endpoint.compose({
            params: { id },
          }),
          method: API_METHODS.DELETE,
        }),
      );
      dispatch(action(id));
    },
    [makeCall, dispatch, action, endpoint],
  );
};

const useReclassifyFile = (isPrivateFile?: boolean) => {
  const t = useTranslations('components.upload.reclassifyDocumentModal');
  const { error } = useToasts();
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    errorMessage: t('error'),
  });
  const endpoint = useFilesEndpoint({ isPrivateFile, route: 'CLASSIFICATION' });
  const dispatch = useDispatch();
  return useCallback(
    async (fileId: string, classification: string | null) => {
      const res = await makeCall(
        callReverseApi({
          url: endpoint.compose({ params: { id: fileId } }),
          method: API_METHODS.PATCH,
          data: {
            data: {
              attributes: {
                classification,
              },
            },
          },
        }),
      );

      if (res.error) return error({});
      else if (isPrivateFile)
        dispatch(updatePrivateFileClassificationAction(mapApiPrivateFile(res.payload.data.data)));
      else
        dispatch(
          updateFileClassificationAction(
            mapApiFile(res.payload.data.data),
            res.payload.data.data.relationships?.file_upload_request?.data?.id,
          ),
        );
    },
    [makeCall, dispatch, error, endpoint, isPrivateFile],
  );
};

const useArchiveFile = (isPrivateFile?: boolean) => {
  const t = useTranslations('pages.inquiryDetails.documentExchange.archiveDocuments');
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    errorMessage: t('error'),
  });

  const { success } = useToasts();

  const inquiryId = useSelector(getInquiryIdSelector) ?? '';

  const endpoint = isPrivateFile
    ? endpoints.PRIVATE_FILES.ARCHIVE
    : endpoints.INQUIRIES.FILES.ARCHIVE;

  return useCallback(
    async ({ personalIdentificationNumber }: ArchiveDocumentsFormValues) => {
      const res = await makeCall(
        callReverseApi({
          url: endpoint.compose({
            params: { id: inquiryId },
          }),
          method: API_METHODS.POST,
          data: {
            personal_identification_number: personalIdentificationNumber,
          },
        }),
      );
      if (!res.error) success({ description: t('success') });
    },
    [makeCall, inquiryId, endpoint, success, t],
  );
};

interface UseMultipleFilesProps extends DropzoneProps {
  fileRequestId?: string;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
}
const useMultipleFiles = ({
  fileRequestId,
  isPrivateFile = false,
  isAssessmentFile = false,
  ...props
}: UseMultipleFilesProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const t = useTranslations('components.upload.uploadMultipleDocuments');
  const inquiryId = useSelector(getInquiryIdSelector) ?? '';
  const { success } = useToasts();

  const previewFile = usePreviewFile(isPrivateFile, isAssessmentFile);
  const uploadFile = useUploadFile(inquiryId, fileRequestId, isPrivateFile);
  const downloadFile = useDownloadFile(isPrivateFile, isAssessmentFile);
  const deleteFile = useDeleteFile(isPrivateFile, isAssessmentFile);
  const reclassifyFile = useReclassifyFile(isPrivateFile);
  const archiveFile = useArchiveFile(isPrivateFile);
  const throttleRequests = useThrottleRequests();

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ALLOWED_MIME_TYPES,
    multiple: true,
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_FILES,
    onDrop: async (acceptedFiles) => {
      if (!acceptedFiles.length) return;
      setIsUploading(true);
      await throttleRequests(acceptedFiles.map((file) => () => uploadFile(file)));
      setIsUploading(false);
      success({ description: t('successMessage') });
    },
    ...props,
  });

  return {
    getRootProps,
    getInputProps,
    open,
    isUploading,
    previewFile,
    downloadFile,
    uploadFile,
    deleteFile,
    reclassifyFile,
    archiveFile,
  };
};

export { useMultipleFiles };

import React from 'react';

import { useSelector } from 'react-redux';

import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import InquirySettingsProvider from 'modules/Inquiry/InquirySettingsProvider';
import InquirySpecificTypeForm from 'pages/inquiryFlow/InquirySpecificTypeForm';
import { isBankAdvisor, isCustomerSelector, isLoggedInSelector } from 'store/user/selectors';

import CreateInquiryNotAllowed from './CreateInquiryNotAllowed';

const InquiryCreate = () => {
  const isCustomer = useSelector(isCustomerSelector);
  const hasBankAdvisorRole = useSelector(isBankAdvisor);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = InquiryType.profiRLL === selectedInquiryType;
  const isBfsService = InquiryType.bfsService === selectedInquiryType;

  const canCreateInquiry =
    !isLoggedIn ||
    (isCustomer && !isBfsService) ||
    (hasBankAdvisorRole && !isProfiInquiry && !isBfsService);

  if (!canCreateInquiry) {
    return <CreateInquiryNotAllowed />;
  }

  return (
    <main>
      <InquirySettingsProvider>
        <ProgressBar />
        <InquirySpecificTypeForm />
      </InquirySettingsProvider>
    </main>
  );
};

export default InquiryCreate;

import React from 'react';

import { ValidationErrorItem } from '@hapi/joi';
import { useForm } from 'react-final-form';

import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import { useFieldValue } from 'store/hooks/useFormValues';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import withFormField from 'utils/form/withFormField';
import { useTranslations } from 'utils/hooks/useTranslations';

import { TextWrapper } from './styles';
import { InputCaption, Text } from '../Text';

type StaticFieldProps = {
  caption: string;
  tooltip?: string;
  text: React.ReactNode;
  error?: ValidationErrorItem | string;
  sideSymbol?: () => React.ReactNode;
  isHorizontal?: boolean;
  dataTestId?: string;
  noFlex?: boolean;
};

const StaticField = ({
  caption,
  tooltip,
  text,
  error,
  sideSymbol,
  isHorizontal,
  dataTestId,
  noFlex,
}: StaticFieldProps) => {
  const t = useTranslations();

  // avoid to show just a caption without value
  if (!text) return null;

  return (
    <>
      <TextWrapper
        className="wrapper"
        isHorizontal={isHorizontal}
        data-testid={dataTestId}
        as="dl"
        noFlex={noFlex}
      >
        <InputCaption className="caption" as="dt">
          {caption}
          {tooltip && (
            <PopoverComponent
              ml={1}
              transform="translateY(-1px)"
              text={tooltip}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={6} d="block" />}
                  label={t('buttons.moreInfos')}
                />
              }
            />
          )}
        </InputCaption>
        <Text className="value" as="dd">
          {text}
          {sideSymbol && <span className="symbol">{sideSymbol()}</span>}
        </Text>
      </TextWrapper>
      {error && <FieldError message={error} />}
    </>
  );
};

export default StaticField;

type AdapterProps = {
  fieldName: string;
  caption: string;
  errorMessage?: ValidationErrorItem | string;
  tooltip?: string;
  parseValue?(value: any): any;
  sideSymbol?: () => React.ReactNode;
  isHorizontal?: boolean;
};

const StaticFieldAdapter = ({
  errorMessage,
  caption,
  tooltip,
  parseValue,
  sideSymbol,
  isHorizontal,
  fieldName,
}: AdapterProps) => {
  const value = useFieldValue(fieldName, '');
  const valueParser = parseValue ? parseValue(value) : value;

  return (
    <StaticField
      caption={caption}
      tooltip={tooltip}
      error={errorMessage}
      text={valueParser}
      sideSymbol={sideSymbol}
      isHorizontal={isHorizontal}
    />
  );
};

export const StaticFieldWithField = withFormField(StaticFieldAdapter);

const NewStaticFieldAdapter = ({
  fieldName,
  caption,
  errorMessage,
  tooltip,
  parseValue,
  sideSymbol,
  isHorizontal,
}: AdapterProps) => {
  const form = useForm();
  const value = form.getFieldState(fieldName)?.value;
  const valueParser = parseValue ? parseValue(value) : value;

  return (
    <StaticField
      caption={caption}
      tooltip={tooltip}
      error={errorMessage}
      text={valueParser}
      sideSymbol={sideSymbol}
      isHorizontal={isHorizontal}
    />
  );
};

export const NewStaticFieldWithField = withFormField(NewStaticFieldAdapter);

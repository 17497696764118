import React, { ReactNode } from 'react';

import provenExpertBadge from 'assets/specific-icons/proven-expert.svg';
import sslBadge from 'assets/specific-icons/ssl.png';
import { InnerPageContainer } from 'components/Page/Page';
import BadgeLogo from 'modules/FormPage/BadgeLogo';
import Badges from 'modules/FormPage/Badges';
import BottomBar from 'modules/FormPage/BottomBar';
import { StyledFormPageLayout } from 'modules/FormPage/FormPageLayout/styles';
import NavWrapper from 'modules/FormPage/NavWrapper';


class INewFormPage {
  children: ReactNode;

  actionButtons?: ReactNode = null;

  additionalButton?: ReactNode = null;

  withBadges?: boolean = true;
}
// Presentation layer of inquiry form
const FormPageLayout = (
  { children, actionButtons, additionalButton, withBadges }: INewFormPage = new INewFormPage(),
) => {
  const includeBadges = withBadges ?? true;
  return (
    <StyledFormPageLayout>
      <InnerPageContainer>
        {children}

        <BottomBar>
          <NavWrapper>{actionButtons}</NavWrapper>
          {includeBadges && (
            <Badges>
              <BadgeLogo src={provenExpertBadge} />
              <BadgeLogo src={sslBadge} />
            </Badges>
          )}
        </BottomBar>
        {additionalButton}
      </InnerPageContainer>
    </StyledFormPageLayout>
  );
};

export default FormPageLayout;

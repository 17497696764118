import _isEmpty from 'lodash/isEmpty';

/**
 * In react-final-form array fields are not treated as empty arrays [] but as an array with an empty object [{}].
 * Thus the length of the array is always at least 1 and should never be 0, so we make the check for the length and if the
 * first object is empty as well.
 * @param values
 */
export function isArrayFieldEmpty(values: Array<any>) {
  if (values.length === 0) {
    return true;
  }

  if (_isEmpty(values[0])) {
    return true;
  }

  return false;
}

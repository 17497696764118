import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { translateTargetMet } from 'mittweida/portals/operations/pages/FinancingCriteria/targetMetTranslator';

import { getMarketDataFilterLegacy } from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { replaceDotWithCommaAndAddUnit } from '../../../../../utils/valueFormats';
import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';
import { getFinanacialCriteriaDataDeveloper } from '../../store/selectors';

interface FinancialCriteriaDeveloperItem {
  value: string;
  target?: string;
  target_met: boolean | null;
}

export interface FinancialCriteriaDeveloper {
  equity_buying_costs_percent: FinancialCriteriaDeveloperItem;
  gak_equity_percent: FinancialCriteriaDeveloperItem;
  equity_project_percent: FinancialCriteriaDeveloperItem;
  margin_percent: FinancialCriteriaDeveloperItem;
  presales_rate: FinancialCriteriaDeveloperItem;
  remaining_risk_percent: FinancialCriteriaDeveloperItem;
  warning_limit_unsecured_portion_buying_phase: FinancialCriteriaDeveloperItem;
  warning_limit_unsecured_portion_whole_project: FinancialCriteriaDeveloperItem;
}

type FinancialCriteriaDeveloperKey = keyof FinancialCriteriaDeveloper;

interface FinancialCriteriaTableData {
  id: string;
  label: string;
  value: string;
  target?: string;
  target_met: string;
}

const orderArray = [
  'equity_buying_costs_percent',
  'gak_equity_percent',
  'equity_project_percent',
  'margin_percent',
  'presales_rate',
  'remaining_risk_percent',
  'warning_limit_unsecured_portion_buying_phase',
  'warning_limit_unsecured_portion_whole_project',
];

const useFinancialCriteriaDeveloperMapping = (objectData: FinancialCriteriaDeveloper) => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.financingCriteria');
  const mappedArray: Array<FinancialCriteriaTableData> = Object.keys(objectData).map((key) => {
    const value = objectData[key as FinancialCriteriaDeveloperKey].value;
    const target = objectData[key as FinancialCriteriaDeveloperKey].target;
    const unit = '%';

    return {
      id: key,
      label: t(`tables.financingCriteriaTable.rowNames.developer.${key}`),
      value: replaceDotWithCommaAndAddUnit(value, unit),
      target: replaceDotWithCommaAndAddUnit(target, unit),
      target_met: translateTargetMet(
        objectData[key as FinancialCriteriaDeveloperKey].target_met,
        t,
      ),
    };
  });

  return orderArray.map((item) => {
    return mappedArray.find((object) => {
      if (object.id === item) {
        return object;
      }
      return null;
    });
  });
};

const FinancingCriteriaPropertyDeveloper = () => {
  const financialCriteriaDataDeveloper: {
    current: FinancialCriteriaDeveloper;
    future: FinancialCriteriaDeveloper;
  } = useSelector(getFinanacialCriteriaDataDeveloper);

  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.financingCriteria.tables.financingCriteriaTable',
  );
  const filterData = useSelector(getMarketDataFilterLegacy);

  const tableData = useFinancialCriteriaDeveloperMapping(
    financialCriteriaDataDeveloper?.[filterData?.usageType],
  );

  const financingCriteriaTable = usePortalTable({
    data: tableData,
    headers: [
      { key: 'label', text: '' },
      { key: 'target', text: t('columnNames.target') },
      { key: 'value', text: t('columnNames.value') },
      { key: 'target_met', text: t('columnNames.targetMet') },
    ],
  });

  return (
    <Box paddingTop={10}>
      <Card title={t('title.developer')}>
        <PortalTable
          {...financingCriteriaTable}
          headerStylesMap={{
            value: { width: '10%' },
            target: { width: '10%' },
            label: { width: '40%' },
            target_met: { width: '40%' },
          }}
        />
      </Card>
    </Box>
  );
};

export default FinancingCriteriaPropertyDeveloper;

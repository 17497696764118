import React from 'react';

import LegalRepresentationSection from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentationSection';

interface AuthorizationOfRepresentationSectionProps {
  sectionNumber: number;
}

export const AuthorizationOfRepresentationSection = (
  props: AuthorizationOfRepresentationSectionProps,
) => {
  return <LegalRepresentationSection {...props} />;
};

import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { persistDecorator } from 'new/form/persist';
import { NewProgressBar } from 'new/form/progress';
import { useInquiryProcessConfig } from 'new/form/state/inquiryProcessConfig/hooks';

type Props<FieldTypes> = {
  children: React.ReactNode;
  initialValues: FieldTypes | null;
  handleSubmit: (values: FieldTypes) => void;
};

export function InquiryProcessForm<FieldTypes>({
  children,
  initialValues,
  handleSubmit,
}: Props<FieldTypes>) {
  const {
    formStateData: { initialFormValues, formName },
  } = useInquiryProcessConfig();

  return (
    <Form
      decorators={[persistDecorator]}
      subscription={{ submitting: true, pristine: true }}
      initialValues={initialValues || initialFormValues}
      mutators={{ ...arrayMutators }}
      onSubmit={(values) => handleSubmit(values)}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} id={formName}>
          {children}
          <NewProgressBar />
        </form>
      )}
    />
  );
}

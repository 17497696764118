import {mittweidaFields} from 'mittweida/inquiry/fields';
import {InquiryType} from "modules/Inquiry/Inquiry.type";
import {fieldValidators} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import {validateFieldValidationMap, ValidateSectionFunction} from 'new/form/useValidateFields';

export const validateRoleSection: ValidateSectionFunction<InquiryType.profiMittweida> = (form, values) => {
  return validateFieldValidationMap({ validationMap: roleSectionValidationMap, form, values, inquiryType: InquiryType.profiMittweida });
};

export const roleSectionValidationMap = {
  [mittweidaFields.financingNeedPage.roleSection.projectFinancingRole]: fieldValidators
    .string()
    .required(),
};

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DisabledFormSection from 'components/DisabledFormSection';
import { useFormConfig } from 'config/formConfig/hooks';
import Condition from 'modules/ConditionalSubform/Condition';
import {
  COMPANY_DETAILS,
  FINANCING_NEED,
  INDUSTRY__CRAFT,
  PURPOSE_KIND,
  PURPOSE_KIND__KFINANZ,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_TURNOVER_CLASS,
} from 'modules/Inquiry/Form/formFields';
import { useCompanyDetailsSectionValidators } from 'modules/Inquiry/Form/Validations/default/useCompanyDetailsSectionValidators';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import { NUMBER_OF_LIFTS } from 'onlinefactoring/formFields';
import CompanyDetailsHeader from 'pages/inquiryFlow/CompanyDetails/CompanyDetailsHeader/CompanyDetailsHeader';
import DvagCompanyDetails from 'pages/inquiryFlow/CompanyDetails/DVAG/CompanyDetails';
import Company, {
  validateCompanySelection,
} from 'pages/inquiryFlow/CompanyDetails/sections/Company';
import { useProcessLaneCheck } from 'pages/inquiryFlow/useProcessLaneCheck';
import CompletingCompanyDetails from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { setCreatedInquiryId } from 'store/progress/actions';
import { getStoredValueSelector } from 'store/progress/selectors';
import { setTokenAction } from 'store/user/actions';
import { isLoggedInSelector, refreshTokenSelector } from 'store/user/selectors';
import { getExpirationDate } from 'store/user/service';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getTurnoverClass } from './getTurnoverClass';
import TermsAndConditions from './sections/TermsAndConditions';
import { useSendPartialSpecializedInquiry } from '../Rentenbank/useSendSpecializedInquiry';

const CompanyDetails = () => {
  const getSectionValidators = useCompanyDetailsSectionValidators();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const t = useTranslations();
  const { pathToNextStep } = useInquiryNavigation(COMPANY_DETAILS);
  const { selectedInquiryType } = useFormConfig();
  const accessToken = useSelector(refreshTokenSelector);
  const history = useHistory();
  const { isRetryPending, checkRepeatedlyLane } = useProcessLaneCheck();
  const sendPartialSpecializedInquiry = useSendPartialSpecializedInquiry();
  const dispatch = useDispatch();

  const handlePartialSpecializedInquiry = async () => {
    const res = await sendPartialSpecializedInquiry();
    if (!res.error) {
      const inquiryId = res.payload.data.data.id;
      if (!accessToken) {
        const token = res.payload.headers['temp-access-token'];
        const refreshToken = res.payload.headers['temp-refresh-token'];
        const expiresIn = res.payload.headers['temp-expires-in'];
        const createdAt = res.payload.headers['temp-created-at'];
        const expiresAt = getExpirationDate(Number(createdAt), Number(expiresIn));

        dispatch(setTokenAction({ token, refreshToken, expiresAt, scope: 'customer' }));
      }

      dispatch(setCreatedInquiryId(inquiryId));
      await checkRepeatedlyLane(inquiryId, 10, selectedInquiryType);
      if (pathToNextStep) history.push(pathToNextStep);
    }
  };

  const handleSubmit = async () => {
    switch (selectedInquiryType) {
      case InquiryType.bigPicture:
      case InquiryType.onlinefactoring:
      case InquiryType.bfsService:
        await handlePartialSpecializedInquiry();
        break;
      default:
        if (pathToNextStep) {
          history.push(pathToNextStep);
        }
    }
  };
  const selectedNumberOfLifts = useSelector(
    getStoredValueSelector([FINANCING_NEED, NUMBER_OF_LIFTS]),
  );
  const selectedPurposeKind = useSelector(getStoredValueSelector([FINANCING_NEED, PURPOSE_KIND]));
  const isKfinanzPurpose = selectedPurposeKind === PURPOSE_KIND__KFINANZ;

  const initialValues = isKfinanzPurpose
    ? {
        [USER_PROFILE_INDUSTRY]: INDUSTRY__CRAFT,
        [USER_PROFILE_TURNOVER_CLASS]:
          selectedNumberOfLifts && getTurnoverClass(selectedNumberOfLifts),
      }
    : undefined;

  const fieldController = FormFieldController.instance;
  fieldController.saveInitial(initialValues);

  return (
    <InquiryFormNew
      headerComp={<CompanyDetailsHeader />}
      currentStepName={COMPANY_DETAILS}
      getSectionValidators={getSectionValidators}
      isLoading={isRetryPending}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {() => (
        <>
          <Company tooltip={t('pages.userProfile.sections.completingContactData.tooltip')} />
          <Condition
            condition={({ form }: { form: any }) => validateCompanySelection(isLoggedIn)(form)}
            onFail={
              <DisabledFormSection
                sectionNumber={2}
                placeholder={t(
                  'pages.userProfile.sections.completingContactData.pleaseSelectCompany',
                )}
              />
            }
          >
            <CompletingCompanyDetails />
            {(selectedInquiryType === InquiryType.bigPicture ||
              selectedInquiryType === InquiryType.onlinefactoring) && (
              <TermsAndConditions sectionNumber={3} />
            )}
          </Condition>
        </>
      )}
    </InquiryFormNew>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagCompanyDetails,
  default: CompanyDetails,
});

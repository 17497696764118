import * as React from 'react';

import { Divider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Asset } from 'components/Asset';
import Page from 'components/Page/Page';
import paths from 'constants/paths';
import { USER_REPRESENTATION, UPLOAD_DOCUMENTS_BUTTON } from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import LoginIcon from 'theme/components/Icon/LoginIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinalPageAction } from '../actions/FinalPageAction';

const { toBoolean } = fieldParsers;
export const LeasePlanFinalPage: React.FC = () => {
  const t = useTranslations('pages.finalPage.leasePlan');
  const inquiryId = useSelector(getCreatedInquiryId);
  const inquiryDetailsUrl = paths.customer.inquiryDetails.dashboard.replace(':id', inquiryId);

  const fieldController = FormFieldController.instance;
  const userRepresentation = toBoolean(`${fieldController.getValue(USER_REPRESENTATION).value}`);

  const heading = userRepresentation
    ? t('documentandvideo.buttonDescription')
    : t('documentandvideo.buttonDescriptionNotLegalRep');

  const subHeading = userRepresentation
    ? t('documentandvideo.subHeading')
    : t('documentandvideo.subHeadingNotLegalRep');

  return (
    <Page>
      <HeadingComponent as="h2" mb={2} textAlign="center">
        {t('heading')}
      </HeadingComponent>
      <TextComponent textAlign="center" color="text.tertiary">
        {t('subHeading')}
      </TextComponent>
      <Divider opacity="1" mt={12} mb={8} borderColor="border.lightGrey" />
      <FinalPageAction asset={<Asset type="inquiry" value={InquiryAssetKeys.DASHBOARD_ACTION} />}>
        <HeadingComponent as="h2" mb={2} textAlign="left">
          {heading}
        </HeadingComponent>
        <TextComponent textAlign="left" mb={6} color="text.tertiary">
          {subHeading}
        </TextComponent>
        <ButtonComponent
          data-testid={UPLOAD_DOCUMENTS_BUTTON}
          as="a"
          href={inquiryDetailsUrl}
          variant="primary"
          leftIcon={<LoginIcon boxSize={6} display="block" />}
        >
          {heading}
        </ButtonComponent>
      </FinalPageAction>
    </Page>
  );
};

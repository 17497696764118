import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { mittweidaSteps } from 'mittweida/inquiry/steps';
import { mmvSteps } from 'mmv/inquiry/steps';
import {
  FINANCING_NEED,
  COMPANY_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE,
  SUMMARY,
  FINAL_PAGE,
  CONTACT_PERSON_STEP,
  PEOPLE_DETAILS,
  CONTRACT_DETAILS,
  CUSTOMER_DATA,
  LEASEPLAN_FINAL_PAGE,
  CONTACT_PERSON_DETAILS,
  BENEFICIARY_OWNER_DETAILS,
  LEGAL_REPRESENTATIVE_DETAILS,
} from 'modules/Inquiry/Form/formFields';

import { InquiryType } from '../Inquiry.type';

const { customer, operation } = paths;

export interface IInquiryPath {
  id: string;
  progressBarTitle?: string;
  sectionTitle?: string;
  paths: {
    newInquiry: string;
    customerEdit: string;
    agentEdit: string;
  };
}

const inquiryPathSegments: { [stepName: string]: IInquiryPath } = {
  [FINANCING_NEED]: {
    id: FINANCING_NEED,
    progressBarTitle: 'pages.financingNeed.name',
    paths: {
      newInquiry: paths.financingNeed,
      customerEdit: customer.inquiryDetails.edit.financingNeed,
      agentEdit: operation.inquiryDetails.edit.financingNeed,
    },
  },
  [CONTRACT_DETAILS]: {
    id: CONTRACT_DETAILS,
    progressBarTitle: 'pages.contractDetails.name',
    paths: {
      newInquiry: paths.contractDetails,
      customerEdit: customer.inquiryDetails.edit.contractDetails,
      agentEdit: operation.inquiryDetails.edit.contractDetails,
    },
  },
  [COMPANY_DETAILS]: {
    id: COMPANY_DETAILS,
    progressBarTitle: 'pages.companyDetails.name',
    paths: {
      newInquiry: paths.companyDetails,
      customerEdit: customer.inquiryDetails.edit.companyDetails,
      agentEdit: operation.inquiryDetails.edit.companyDetails,
    },
  },
  [REQUEST_DETAILS]: {
    id: REQUEST_DETAILS,
    progressBarTitle: 'pages.requestDetails.name',
    paths: {
      newInquiry: paths.requestDetails,
      customerEdit: customer.inquiryDetails.edit.requestDetails,
      agentEdit: operation.inquiryDetails.edit.requestDetails,
    },
  },
  [USER_PROFILE]: {
    id: USER_PROFILE,
    progressBarTitle: 'pages.userProfile.name',
    paths: {
      newInquiry: paths.userProfile,
      customerEdit: customer.inquiryDetails.edit.userProfile,
      agentEdit: operation.inquiryDetails.edit.userProfile,
    },
  },
  [SUMMARY]: {
    id: SUMMARY,
    paths: {
      newInquiry: paths.summaryPage,
      customerEdit: customer.inquiryDetails.edit.summaryPage,
      agentEdit: operation.inquiryDetails.edit.summaryPage,
    },
  },
  [FINAL_PAGE]: {
    id: FINAL_PAGE,
    paths: {
      newInquiry: paths.finalPage,
      customerEdit: customer.inquiryDetails.details,
      agentEdit: operation.inquiryDetails.root,
    },
  },
  [CONTACT_PERSON_STEP]: {
    id: CONTACT_PERSON_STEP,
    progressBarTitle: 'pages.contactPersonStep.title',
    paths: {
      newInquiry: paths.contactPerson,
      customerEdit: paths.customer.inquiryDetails.edit.contactPerson,
      agentEdit: paths.operation.inquiryDetails.edit.contactPerson,
    },
  },
  [PEOPLE_DETAILS]: {
    id: PEOPLE_DETAILS,
    progressBarTitle: 'pages.peopleDetails.name',
    paths: {
      newInquiry: paths.peopleDetails,
      customerEdit: customer.inquiryDetails.edit.peopleDetails,
      agentEdit: operation.inquiryDetails.edit.peopleDetails,
    },
  },
  [CUSTOMER_DATA]: {
    id: CUSTOMER_DATA,
    progressBarTitle: 'pages.customerData.name',
    paths: {
      newInquiry: paths.customerData,
      customerEdit: customer.inquiryDetails.edit.customerData,
      agentEdit: customer.inquiryDetails.edit.customerData,
    },
  },
  [CONTACT_PERSON_DETAILS]: {
    id: CONTACT_PERSON_DETAILS,
    progressBarTitle: 'pages.contactPersonDetails.name',
    paths: {
      newInquiry: paths.contactPersonDetails,
      customerEdit: customer.inquiryDetails.edit.contactPersonDetails,
      agentEdit: operation.inquiryDetails.edit.contactPersonDetails,
    },
  },
};

const inquiryPathSegmentsByInquiryType: Record<string, Record<string, IInquiryPath>> = {
  [InquiryType.onlinefactoring]: {
    [FINANCING_NEED]: {
      id: FINANCING_NEED,
      progressBarTitle: 'inquiryType.onlinefactoring.steps.purpose.title',
      paths: {
        newInquiry: paths.financingNeed,
        customerEdit: customer.inquiryDetails.edit.financingNeed,
        agentEdit: customer.inquiryDetails.edit.financingNeed,
      },
    },
    [COMPANY_DETAILS]: {
      id: COMPANY_DETAILS,
      progressBarTitle: 'inquiryType.onlinefactoring.steps.companyDetails.title',
      paths: {
        newInquiry: paths.companyDetails,
        customerEdit: customer.inquiryDetails.edit.companyDetails,
        agentEdit: operation.inquiryDetails.edit.companyDetails,
      },
    },
    [REQUEST_DETAILS]: {
      id: REQUEST_DETAILS,
      progressBarTitle: 'inquiryType.onlinefactoring.steps.financingDetails.title',
      paths: {
        newInquiry: paths.requestDetails,
        customerEdit: customer.inquiryDetails.edit.requestDetails,
        agentEdit: operation.inquiryDetails.edit.requestDetails,
      },
    },
    [PEOPLE_DETAILS]: {
      id: PEOPLE_DETAILS,
      progressBarTitle: 'pages.peopleDetails.name',
      paths: {
        newInquiry: paths.peopleDetails,
        customerEdit: customer.inquiryDetails.edit.peopleDetails,
        agentEdit: operation.inquiryDetails.edit.peopleDetails,
      },
    },
    [SUMMARY]: {
      id: SUMMARY,
      paths: {
        newInquiry: paths.summaryPage,
        customerEdit: customer.inquiryDetails.edit.summaryPage,
        agentEdit: operation.inquiryDetails.edit.summaryPage,
      },
    },

    [FINAL_PAGE]: {
      id: FINAL_PAGE,
      paths: {
        newInquiry: paths.finalPage,
        customerEdit: customer.inquiryDetails.details,
        agentEdit: operation.inquiryDetails.root,
      },
    },
  },
  [InquiryType.bfs]: {
    ...inquiryPathSegments,
    [FINANCING_NEED]: {
      id: FINANCING_NEED,
      progressBarTitle: 'pages.financingNeed.name',
      paths: {
        newInquiry: paths.financingNeed,
        customerEdit: customer.inquiryDetails.edit.financingNeed,
        agentEdit: operation.inquiryDetails.edit.financingNeed,
      },
    },
  },
  [InquiryType.leaseplan]: {
    [CONTRACT_DETAILS]: {
      id: CONTRACT_DETAILS,
      progressBarTitle: 'pages.contractDetails.name',
      paths: {
        newInquiry: paths.contractDetails,
        customerEdit: customer.inquiryDetails.edit.contractDetails,
        agentEdit: operation.inquiryDetails.edit.contractDetails,
      },
    },
    [CUSTOMER_DATA]: {
      id: CUSTOMER_DATA,
      progressBarTitle: 'pages.customerData.name',
      paths: {
        newInquiry: paths.customerData,
        customerEdit: customer.inquiryDetails.edit.customerData,
        agentEdit: customer.inquiryDetails.edit.customerData,
      },
    },
    [LEASEPLAN_FINAL_PAGE]: {
      id: LEASEPLAN_FINAL_PAGE,
      paths: {
        newInquiry: paths.finalPage,
        customerEdit: paths.finalPage,
        agentEdit: operation.inquiryDetails.root,
      },
    },
  },
  [InquiryType.bfsService]: {
    ...inquiryPathSegments,
    [FINANCING_NEED]: {
      id: FINANCING_NEED,
      progressBarTitle: 'pages.inquiryDetails.detailed.financingDetails',
      sectionTitle: 'pages.inquiryDetails.detailed.financingDetails',
      paths: {
        newInquiry: paths.financingNeed,
        customerEdit: customer.inquiryDetails.edit.financingNeed,
        agentEdit: operation.inquiryDetails.edit.financingNeed,
      },
    },
    [LEGAL_REPRESENTATIVE_DETAILS]: {
      id: LEGAL_REPRESENTATIVE_DETAILS,
      sectionTitle: 'pages.inquiryDetails.detailed.legalRepresentativeDetails',
      paths: {
        newInquiry: paths.peopleDetails,
        customerEdit: customer.inquiryDetails.edit.peopleDetails,
        agentEdit: operation.inquiryDetails.edit.peopleDetails,
      },
    },
    [BENEFICIARY_OWNER_DETAILS]: {
      id: BENEFICIARY_OWNER_DETAILS,
      sectionTitle: 'pages.inquiryDetails.detailed.beneficiaryOwners',
      paths: {
        newInquiry: paths.peopleDetails,
        customerEdit: customer.inquiryDetails.edit.peopleDetails,
        agentEdit: operation.inquiryDetails.edit.peopleDetails,
      },
    },
  },
  [InquiryType.profiMittweida]: {
    [mittweidaSteps.financingNeedPage]: {
      id: mittweidaSteps.financingNeedPage,
      progressBarTitle: 'inquiryType.mittweida.pages.financingNeed.progressBarTitle',
      paths: {
        newInquiry: paths.financingNeed,
        customerEdit: customer.inquiryDetails.edit.financingNeed,
        agentEdit: customer.inquiryDetails.edit.financingNeed,
      },
    },
    [mittweidaSteps.fundingDetailsPage]: {
      id: mittweidaSteps.financingNeedPage,
      progressBarTitle: 'inquiryType.mittweida.pages.financingDetails.progressBarTitle',
      paths: {
        newInquiry: paths.financingNeed,
        customerEdit: customer.inquiryDetails.edit.financingNeed,
        agentEdit: customer.inquiryDetails.edit.financingNeed,
      },
    },
    [mittweidaSteps.userProfilePage]: {
      id: mittweidaSteps.userProfilePage,
      progressBarTitle: 'inquiryType.mittweida.pages.userDetails.progressBarTitle',
      paths: {
        newInquiry: paths.financingNeed,
        customerEdit: customer.inquiryDetails.edit.financingNeed,
        agentEdit: customer.inquiryDetails.edit.financingNeed,
      },
    },
    [mittweidaSteps.companyDetailPage]: {
      id: mittweidaSteps.companyDetailPage,
      progressBarTitle: 'inquiryType.mittweida.pages.companyDetails.progressBarTitle',
      paths: {
        newInquiry: paths.financingNeed,
        customerEdit: customer.inquiryDetails.edit.financingNeed,
        agentEdit: customer.inquiryDetails.edit.financingNeed,
      },
    },
  },
  [InquiryType.mmv]: {
    [mmvSteps.customerDataPage]: {
      id: mmvSteps.customerDataPage,
      progressBarTitle: 'inquiryType.mmv.pages.financingNeed.progressBarTitle',
      paths: {
        newInquiry: paths.financingNeed,
        customerEdit: customer.inquiryDetails.edit.financingNeed,
        agentEdit: customer.inquiryDetails.edit.financingNeed,
      },
    },
  },
};

export const getStepSegmentByInquiryType = (selectedInquiryType: InquiryType) => {
  const stepByInquiryType = inquiryPathSegmentsByInquiryType[selectedInquiryType];
  if (stepByInquiryType) {
    return stepByInquiryType;
  }
  return inquiryPathSegments;
};

export const useStepSegmentByInquiryType = () => {
  const { selectedInquiryType } = useFormConfig();
  return getStepSegmentByInquiryType(selectedInquiryType);
};

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryProcessConfig } from 'new/form/model';

import { initialFormState } from './initialFormState';
import { initialFormValues } from './initialFormValues';
import { routeToPageNameMap } from './routeToPageNameMap';
import { stepData } from './stepData';
import { stepSequence } from './stepSequence';
import { validations } from './validations';
import { MMV_FORM } from '../index';

export const inquiryProcessConfig: InquiryProcessConfig<InquiryType.mmv> = {
  validations,
  stepData,
  stepSequence,
  routeToPageNameMap,
  initialFormState,
  initialFormValues,
  formName: MMV_FORM,
};

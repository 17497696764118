import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import loadMarketValueDetails from 'api/CompeonReverseApi/operation/loadMarketValueDetails/actions';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import MittweidaMarketComparison from 'mittweida/portals/operations/pages/MarketComparison';
import { ComputedMarketData } from 'models/types/MarketData.type';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { EditStepWrapper } from 'modules/SummaryTable/EditStepButton/styles';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import ButtonComponent from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { formatDateWithTime } from 'utils/date';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { deepCamelcaseKeys } from 'utils/object';

import mapMarketDetailsToEdit from '../EditMarketValuesReeProfitability/mapMarketDetailsToEdit';
import { PROFI_RECORD_TYPE } from '../SharedComponent/constants';
import { getMarketDataFilter } from '../SharedComponent/filterManagementReducer';
import { LocationAnalysisTiles } from '../SharedComponent/LocationAnalysisTiles';
import MarketDataEvaluationTable from '../SharedComponent/MarketDataEvaluationTable';
import MarketDataTopBoxes from '../SharedComponent/MarketDataTopBoxes';
import { fetchMarketData } from '../SharedComponent/PlanningEvaluation.service';
import ProjectCostingFilters from '../SharedComponent/ProjectCostingFilters';
import ProjectCostingHeader from '../SharedComponent/ProjectCostingHeader';
import useEditFormConfig from '../useEditFormConfig';

const ProfiMarketComparison = () => {
  const t = useTranslations('pages.planningEvaluation.marketComparison');
  const tBase = useTranslations();
  const { makeCall, isPending } = useDispatchApiCall({
    showErrorNotification: true,
    isPendingInitially: true,
  });
  const { saleType, usageType } = useSelector(getMarketDataFilter);
  const inquiryId = useSelector(getCreatedInquiryId) || '';
  const { editMarketValueDetails: editMarketDataEvaluationPath } = paths.operation.inquiryDetails.planningEvaluation;
  const [updatedAt, setUpdatedAt] = useState<string>()
  const [computedMarketData, setComputedMarketData] = useState<ComputedMarketData>()

  const { onEdit } = useEditFormConfig({
    pathToEdit: editMarketDataEvaluationPath(inquiryId),
    recordType: PROFI_RECORD_TYPE.VALUE_API,
    loadData: loadMarketValueDetails,
    editFormStructure: mapMarketDetailsToEdit(),
  });

  useEffect(() => {
    const effect = async () => {
      const { error, payload } = await makeCall(fetchMarketData((inquiryId)))

      if (error) return

      setUpdatedAt(payload.data.data.attributes.updated_at)
      setComputedMarketData(deepCamelcaseKeys(payload.data.data.attributes.computed_details))
    }

    effect()
  }, [inquiryId, makeCall])

  return (
    <Box pb="20">
      {isPending || !computedMarketData ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <ProjectCostingHeader
            heading={t('title')}
            subheading={t('subheading')}
            subheadingDate={updatedAt ? formatDateWithTime(updatedAt) : '-'}
          />

          <ProjectCostingFilters />
          <MarketDataTopBoxes topBoxes={computedMarketData.topBoxes[usageType][saleType]} />
          <AccordionComponent headingLevel="h3" mb={8} title={t('crousalHeader')}>
            <MarketDataEvaluationTable evaluation={computedMarketData?.evaluation[usageType][saleType]} />

            <EditStepWrapper>
              <ButtonComponent
                leftIcon={<EditIcon boxSize={6} display="block" />}
                onClick={onEdit}
                variant="tertiary"
              >
                {tBase('buttons.edit')}
              </ButtonComponent>
            </EditStepWrapper>
          </AccordionComponent>

          <LocationAnalysisTiles location={computedMarketData?.location[usageType][saleType]} />
        </>
      )}
    </Box>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiRLL]: ProfiMarketComparison,
  [InquiryType.profiMittweida]: MittweidaMarketComparison,
});

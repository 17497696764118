import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'components/Spinner';
import { InquiryRevision } from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';
import {
  fetchInquiryRevision,
  mapRevisionFromApi,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.service';
import {
  getNextRevisionIdSelector,
  getPreviousRevisionIdSelector,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.state';
import InquiryRevisionUser from 'modules/InquiryDetails/InquiryRevision/InquiryRevisionUser';
import RevisionSingleModification from 'modules/InquiryDetails/InquiryRevision/RevisionSingleModification';
import {
  StyledRevisionInfo as Info,
  StyledRevisionActions as Actions,
  StyledChangeWrapper,
} from 'modules/InquiryDetails/InquiryRevision/styles';
import { getRevision } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import TimeIcon from 'theme/components/Icon/TimeIcon';
import UserIcon from 'theme/components/Icon/UserIcon';
import { formatDateWithTime } from 'utils/date';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import makeCancellableCallback from 'utils/makeCancellableCallback';

import { REVISION_ACTIONS } from './actions';

const RevisionChange: React.FC = () => {
  const state = useSelector(getRevision);
  const dispatch = useDispatch();

  const [revision, setRevision] = useState<InquiryRevision>();

  const { isPending, makeCall } = useDispatchApiCall();
  const t = useTranslations('pages.inquiryDetails.changelogs');

  useEffect(() => {
    const { cancel, callback } = makeCancellableCallback(({ payload }: any) => {
      setRevision(mapRevisionFromApi(payload.data));
    });

    if (state.selectedRevisionId) {
      setRevision(undefined);
      makeCall(fetchInquiryRevision(state.selectedRevisionId), callback);
    }
    return cancel;
  }, [makeCall, state.selectedRevisionId]);

  if (isPending) {
    return <Spinner />;
  }

  if (!revision) {
    return null;
  }

  const selectNextRevision = () =>
    dispatch({
      type: REVISION_ACTIONS.SELECT_REVISION,
      data: getPreviousRevisionIdSelector(state),
    });
  const selectPreviousRevision = () =>
    dispatch({
      type: REVISION_ACTIONS.SELECT_REVISION,
      data: getNextRevisionIdSelector(state),
    });

  return (
    <>
      <Info>
        <dt>
          <UserIcon boxSize={8} mr={2} />
          {t('changesBy')}:
        </dt>
        <dd>
          {revision.owner ? (
            <InquiryRevisionUser user={revision.owner} />
          ) : (
            t(`initiator.${revision.initiator}`)
          )}
        </dd>
      </Info>

      <Info>
        <dt>
          <TimeIcon boxSize={8} mr={2} />
          {t('changeDate')}:
        </dt>
        <dd>{formatDateWithTime(revision.createdAt)}</dd>
      </Info>

      <Actions>
        <ButtonComponent
          data-testid="previous-change-btn"
          disabled={!state.canGetPrevious}
          leftIcon={<ArrowLeftIcon boxSize={6} display="block" />}
          onClick={selectPreviousRevision}
          variant="secondary"
        >
          {t('previousChange')}
        </ButtonComponent>
        <ButtonComponent
          data-testid="next-change-btn"
          disabled={!state.canGetNext}
          onClick={selectNextRevision}
          rightIcon={<ArrowRightIcon boxSize={6} display="block" />}
          variant="secondary"
        >
          {t('nextChange')}
        </ButtonComponent>
      </Actions>

      <StyledChangeWrapper>
        {revision.modification.map((modification) => (
          <div data-testid="revision-modification" key={modification.attribute}>
            <RevisionSingleModification key={modification.attribute} modification={modification} />
          </div>
        ))}
      </StyledChangeWrapper>
    </>
  );
};

export default RevisionChange;

import React, { useCallback, useState } from 'react';

import { Divider, Flex, HStack, Icon, useDisclosure, VStack, Text, Center } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { ReactComponent as DocumentIcon } from 'assets/specific-icons/file.svg';
import { ArchivePrivateDocumentButton } from 'components/ArchiveDocuments/components/ArchivePrivateDocumentButton';
import Condition from 'components/Condition';
import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { useMultipleFiles } from 'components/UploadBlock/hooks/useMultipleFiles';
import type { IPrivateFile, UploadableFileTypes } from 'models/File.model';
import { translations } from 'new/form/common/types';
import { getFileClassificationMap } from 'store/documentExchange/documentExchange.selectors';
import { AlertDialogComponent as DeleteFileInFileRequestDialog } from 'theme/components/AlertDialog';
import { BadgeComponent, BadgeStatus } from 'theme/components/Badge';
import ArchiveCheckIcon from 'theme/components/Icon/ArchiveCheckIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import ViewIcon from 'theme/components/Icon/ViewIcon';
import { TooltipComponent } from 'theme/components/Tooltip';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FileReclassificationButton } from './FileReclassificationButton';

interface FileIconProps {
  file: Partial<UploadableFileTypes>;
  showFileDownloadStatusBadge?: boolean;
}
const FileIcon = ({ file, showFileDownloadStatusBadge = false }: FileIconProps) => {
  const t = useTranslations();
  const { newFile } = translations.components.upload;
  const { success } = translations.pages.inquiryDetails.documentExchange.archiveDocuments.tooltip;
  if (showFileDownloadStatusBadge && file.status === DOWNLOAD_STATUS.NOT_DOWNLOADED)
    return (
      <BadgeComponent status={BadgeStatus.INFO} fontSize="sm">
        {t(newFile)}
      </BadgeComponent>
    );
  else if (file.archivedAt)
    return (
      <TooltipComponent label={t(success, { lastArchived: formatDateWithTime(file.archivedAt) })}>
        <ArchiveCheckIcon boxSize={4} color="brand.default" />
      </TooltipComponent>
    );
  else return <Icon as={DocumentIcon} boxSize={4} />;
};

interface FileListProps {
  files: Array<UploadableFileTypes>;
  fileRequestId?: string;
  showFileDownloadStatusBadge?: boolean;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
}

export const FileList = ({
  files,
  fileRequestId,
  showFileDownloadStatusBadge,
  isPrivateFile,
  isAssessmentFile,
}: FileListProps) => {
  const { deleteFile, downloadFile, previewFile } = useMultipleFiles({
    fileRequestId,
    isPrivateFile,
    isAssessmentFile,
  });
  const {
    isOpen: isDeleteFileDialogOpen,
    onOpen: onDeleteFileDialogOpen,
    onClose: onDeleteFileDialogClose,
  } = useDisclosure();
  const t = useTranslations();
  const { cancelText, confirmText, remove, doYouWantToRemove } = translations.components.upload;

  const { id, fileName } = files[0];
  const [selectedFile, setSelectedFile] = useState<{ id: string; fileName: string }>({
    id,
    fileName,
  });

  const fileClassificationMap = useSelector(getFileClassificationMap);

  const isPrivateOtherFile = useCallback(
    (file: Partial<UploadableFileTypes>): file is IPrivateFile =>
      Boolean(isPrivateFile) && (file as IPrivateFile).classification !== null,
    [isPrivateFile],
  );

  return (
    <>
      <VStack divider={<Divider borderColor="border.lightGrey" />} spacing={4} align="stretch">
        {files.map(({ id, createdAt, fileName, ...fileProps }: UploadableFileTypes) => (
          <Flex alignItems="center" justifyContent="space-between" key={id}>
            <Center width={8}>
              <FileIcon
                file={fileProps}
                showFileDownloadStatusBadge={showFileDownloadStatusBadge}
              />
            </Center>
            <HStack flexGrow="1" spacing={16} paddingLeft={8}>
              <Text color="text.secondary" width={40}>
                {formatDateWithTime(createdAt)}
              </Text>
              <Text
                color="text.secondary"
                width={isPrivateFile ? 80 : 96}
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {fileName}
              </Text>
              <Condition condition={isPrivateOtherFile(fileProps)}>
                <Text
                  color="text.secondary"
                  width={80}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {fileClassificationMap[(fileProps as IPrivateFile).classification!]}
                </Text>
              </Condition>
            </HStack>
            <HStack spacing={4}>
              <Condition condition={isPrivateOtherFile(fileProps)}>
                <ArchivePrivateDocumentButton />
              </Condition>
              <FileReclassificationButton
                fileId={id}
                fileRequestId={fileRequestId}
                isPrivateFile={isPrivateFile}
                isAssessmentFile={isAssessmentFile}
              />
              <ViewIcon
                color="brand.default"
                boxSize={4}
                cursor="pointer"
                onClick={() => previewFile(id)}
              />
              <DownloadIcon
                color="brand.default"
                boxSize={4}
                cursor="pointer"
                onClick={() => downloadFile(id, fileName)}
              />
              <DeleteIcon
                color="brand.default"
                boxSize={4}
                cursor="pointer"
                onClick={() => {
                  setSelectedFile({ id, fileName });
                  onDeleteFileDialogOpen();
                }}
              />
            </HStack>
          </Flex>
        ))}
      </VStack>
      <DeleteFileInFileRequestDialog
        cancelText={t(cancelText)}
        confirmText={t(confirmText)}
        isOpen={isDeleteFileDialogOpen}
        onClose={onDeleteFileDialogClose}
        onConfirm={() => {
          deleteFile(selectedFile.id);
          onDeleteFileDialogClose();
        }}
        title={t(remove)}
        children={t(doYouWantToRemove, { filename: selectedFile.fileName })}
      />
    </>
  );
};

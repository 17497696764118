import React from 'react';

import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Container } from 'components/PortalPage/styles';
import { Seperator } from 'components/Seperator';
import StaticField from 'components/StaticField';
import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import { useFetchAssociatedPersons } from 'pages/operationPortal/CompaniesDetails/helpers/useFetchAssociatedPersons';
import { getCompanyDetails } from 'pages/operationPortal/CompaniesDetails/store/selectors';
import { StyledOverviewFields, Subtitle } from 'pages/operationPortal/CompaniesDetails/styles';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import WarningIcon from 'theme/components/Icon/WarningIcon';
import { TextComponent } from 'theme/components/Text';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { SourceType } from './AssociatedPersonMergeSections';

const AssociatedPerson = () => {
  const { id: inquiryId, companyId } = useParams<{ id: string; companyId: string }>();
  const t = useTranslations('pages.companiesDetails.associatedPerson');
  const { data: associatedPersons } = useFetchAssociatedPersons(companyId);
  const companyDetails = useSelector(getCompanyDetails);
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const customersSoleSignatureAuthorized = _.get(inquiryDetails, 'userLegalRepresentation');
  const customerLegalRepresentativeId = _.get(inquiryDetails, 'customerLegalRepresentativeId');

  const history = useHistory();
  const mergeActionPath = (personId: string) =>
    inquiryId
      ? paths.operation.inquiryDetails.companyDetails.associatedPersonsMerge(
          inquiryId,
          companyId,
          personId,
        )
      : paths.operation.companies.associatedPersonsMerge(companyId, personId);

  const legalRepresentatives = associatedPersons.filter(
    (p: IAssociatedPerson) => p.type === 'legal_representatives',
  );
  const beneficialOwners = associatedPersons.filter(
    (p: IAssociatedPerson) => p.type === 'beneficiary_owners',
  );

  const mapPerson = (person: IAssociatedPerson) => (
    <AccordionComponent
      headingLevel="h3"
      mb={8}
      title={`${person.firstName} ${person.lastName}`}
      text={
        person.type === 'legal_representatives'
          ? t('properties.authorizedToRepresent')
          : t('properties.beneficialOwner')
      }
      action={
        person.source === SourceType.USER_INPUT &&
        !person.successfullyMerged && (
          <ButtonComponent
            variant="secondary"
            leftIcon={<WarningIcon boxSize={6} display="block" />}
            onClick={() => history.push(mergeActionPath(person.id))}
          >
            {t('merge.button')}
          </ButtonComponent>
        )
      }
    >
      {person.type === 'legal_representatives' && (
        <>
          <Subtitle>{t('properties.represent')}</Subtitle>
          <StyledOverviewFields>
            <StaticField caption={t('properties.role')} text={person.role ?? '-'} />
            <StaticField
              caption={t('properties.powerOfRepresentation.caption')}
              text={
                customerLegalRepresentativeId === person.id &&
                person.source === SourceType.USER_INPUT
                  ? customersSoleSignatureAuthorized
                    ? t('properties.powerOfRepresentation.values.soleRepresentative')
                    : t('properties.powerOfRepresentation.values.notSoleRepresentative')
                  : '-'
              }
            />
          </StyledOverviewFields>
        </>
      )}
      {person.type === 'beneficiary_owners' && (
        <>
          <Subtitle>{t('properties.beneficial')}</Subtitle>
          <StyledOverviewFields>
            <StaticField caption={t('properties.ownerType')} text={person.ownerType ?? '-'} />
            <StaticField caption={t('properties.scope')} text={person.scope ?? '-'} />
            <StaticField caption={t('properties.source')} text={person.source ?? '-'} />
          </StyledOverviewFields>
        </>
      )}
      <Subtitle>{t('properties.contactData')}</Subtitle>
      <StyledOverviewFields>
        <StaticField caption={t('properties.email')} text={person.email ?? '-'} />
        <StaticField caption={t('properties.phoneNumber')} text={person.phoneNumber ?? '-'} />
      </StyledOverviewFields>
      <Subtitle>{t('properties.personalData')}</Subtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t('properties.birthDate')}
          text={person.birthDate ? formatDateDays(person.birthDate) : '-'}
          noFlex
        />
        <StaticField caption={t('properties.birthPlace')} text={person.birthPlace ?? '-'} />
      </StyledOverviewFields>
      <StyledOverviewFields>
        <StaticField
          caption={t('properties.privateAddress')}
          text={person.address ? `${person.address}, ${person.zipCode} ${person.city}` : '-'}
        />
        <StaticField caption={t('properties.taxId')} text={person.taxId ?? '-'} />
      </StyledOverviewFields>
    </AccordionComponent>
  );
  return (
    <Container>
      <Box pt="12" pb="20">
        <HeadingComponent as="h2" variant="primary" mb={8}>
          {t('title')}

          <TextComponent
            as="span"
            display="block"
            fontWeight={FONT_WEIGHT_REGULAR}
            color="text.tertiary"
          >
            {companyDetails.companyName}
          </TextComponent>
        </HeadingComponent>

        {legalRepresentatives.length > 0 && (
          <Seperator
            divider={beneficialOwners.length > 0}
            title={`${t('properties.authorizedToRepresent')} (${legalRepresentatives.length})`}
          >
            {legalRepresentatives.map((person: IAssociatedPerson) => mapPerson(person))}
          </Seperator>
        )}
        {beneficialOwners.length > 0 && (
          <Seperator
            divider={false}
            title={`${t('properties.beneficialOwner')} (${beneficialOwners.length})`}
          >
            {beneficialOwners.map((person: IAssociatedPerson) => mapPerson(person))}
          </Seperator>
        )}
      </Box>
    </Container>
  );
};

export default withRolesGuard(PERMISSIONS.COMPANY_DETAILS.DISPLAY)(AssociatedPerson);

import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  BENEFICIARY_OWNER,
  CUSTOMER_DATA,
  EXISTING_BENEFICIARY,
  HAS_BENEFICIARY_OWNER,
  IS_BENEFICIARY_OWNER,
  NO_BENEFICIARY_CHECKBOX,
  USER_EXISTING_REPRESENTATIVE,
  USER_REPRESENTATIVE,
  USER_SHARED_REPRESENTATION,
} from 'modules/Inquiry/Form/formFields';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import BusinessDetails from 'pages/inquiryFlow/CustomerData/BussinessDetails';
import ContactPerson from 'pages/inquiryFlow/CustomerData/ContactPerson';
import { usePeopleDetailsValidators } from 'pages/inquiryFlow/PeopleDetails/usePeopleDetailsValidators';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { initialState } from 'store/inquiryList/reducer';
import { resetForm, setCurrentStage } from 'store/progress/actions';

import { CustomerDataWrapper } from './styles';
import BeneficiaryOwnersSection from '../PeopleDetails/BeneficiaryOwnersSection/BeneficiaryOwnersSection';
import LegalRepresentationSection from '../PeopleDetails/LegalRepresentationSection/LegalRepresentationSection';
import useSendSpecializedInquiry from '../Rentenbank/useSendSpecializedInquiry';
import StepsHeader from '../StepsHeader';

export const leasePlanFormValuesCleanUp = () => {
  const fieldController = FormFieldController.instance;

  fieldController.deleteBulkPair([
    USER_REPRESENTATIVE,
    USER_SHARED_REPRESENTATION,
    USER_EXISTING_REPRESENTATIVE,
    BENEFICIARY_OWNER,
    EXISTING_BENEFICIARY,
    IS_BENEFICIARY_OWNER,
    NO_BENEFICIARY_CHECKBOX,
    HAS_BENEFICIARY_OWNER,
  ]);
};

const CustomerDataUnwrapped = () => {
  const getSectionsValidators = usePeopleDetailsValidators();
  const sendSpecializedInquiry = useSendSpecializedInquiry();
  const { pathToNextStep } = useInquiryNavigation(CUSTOMER_DATA);
  const history = useHistory();
  const dispatch = useDispatch();
  const initialValues = {
    [USER_REPRESENTATIVE]: [],
    [BENEFICIARY_OWNER]: [],
    [IS_BENEFICIARY_OWNER]: 'false',
  };

  const handleSubmit = async () => {
    const { error } = await sendSpecializedInquiry();
    if (!error) {
      if (pathToNextStep) {
        dispatch(
          getInquiriesAction({
            ...initialState.options,
            filterBy: { ...initialState.options.filterBy },
          }),
        );
        dispatch(resetForm(true));
        dispatch(setCurrentStage(2));
        history.push(pathToNextStep);
      }
    }
  };

  return (
    <InquiryFormNew
      initialValues={initialValues}
      currentStepName={CUSTOMER_DATA}
      headerComp={<StepsHeader header={'pages.customerData.heading.header.customer'} />}
      getSectionValidators={getSectionsValidators}
      onSubmit={handleSubmit}
    >
      {() => (
        <CustomerDataWrapper>
          <BusinessDetails sectionNumber={1} />
          <ContactPerson sectionNumber={2} />
          <LegalRepresentationSection sectionNumber={3} />
          <BeneficiaryOwnersSection sectionNumber={4} />
        </CustomerDataWrapper>
      )}
    </InquiryFormNew>
  );
};

export default CustomerDataUnwrapped;

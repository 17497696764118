import * as React from 'react';
import { useState } from 'react';

import { Center, Flex, Grid, GridItem } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import { Asset } from 'components/Asset';
import { CheckboxWithField } from 'components/Checkbox';
import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { StyledFileStatusLabel as StatusLabel } from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ModalComponent } from 'theme/components/Modal';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

interface ConfirmObjectTurnoverStepProps {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
}

export const ConfirmObjectTurnoverStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: ConfirmObjectTurnoverStepProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.confirmObjectTurnover');
  const tUpload = useTranslations('components.upload');
  const { required, mustBeTrue } = useFieldValidators();

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <>
      <ProgressSection
        isFirst={isFirst}
        isLast={isLast}
        isCompleted={isCompleted}
        completedValue={completedValue}
        progressIncrement={progressIncrement}
        progressTotal={progressTotal}
      >
        <Grid templateColumns={['1fr', null, '2fr 1fr']} gap={12}>
          <GridItem>
            <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
              {t('title')}
            </HeadingComponent>
            <TextComponent mb={3}>{t('description')}</TextComponent>
            <ButtonComponent
              onClick={handleOpen}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="primary"
            >
              {t('action')}
            </ButtonComponent>
          </GridItem>

          <GridItem>
            <Center height="100%">
              <Asset
                htmlHeight="200"
                type="dashboard"
                value={ProgressSectionType.CONFIRM_OBJECT_TAKEOVER}
              />
            </Center>
          </GridItem>
        </Grid>
      </ProgressSection>
      <Form
        onSubmit={() => {}}
        initialValues={{ confirmation: false }}
        render={({ handleSubmit, invalid }) => (
          <ModalComponent
            isOpen={isOpen}
            onClose={handleClose}
            title={t('modal.title')}
            footer={
              <Flex align="flex-start" w="full">
                <ButtonComponent
                  isDisabled={invalid}
                  onClick={handleSubmit}
                  leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                >
                  {t('modal.action')}
                </ButtonComponent>
              </Flex>
            }
          >
            <form onSubmit={handleSubmit}>
              <TextComponent mb="6">{t('modal.description')}</TextComponent>
              <Flex flexWrap="wrap" rowGap={4}>
                <UploadedFileBlock
                  file={{
                    id: '0',
                    fileName: 'test.pdf',
                    status: DOWNLOAD_STATUS.NOT_DOWNLOADED,
                    createdAt: new Date(),
                  }}
                  canRemove={false}
                  label={<StatusLabel>{tUpload('newFile')}</StatusLabel>}
                />
                <CheckboxWithField
                  validate={combineValidators(required, mustBeTrue)}
                  name="confirmation"
                  text={t('modal.confirmation')}
                  size="sm"
                />
              </Flex>
            </form>
          </ModalComponent>
        )}
      />
    </>
  );
};

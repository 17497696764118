import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { NewStaticFieldWithField } from 'components/StaticField';
import { mmvFields } from 'mmv/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';
const { accountHolder, iban } = mmvFields.contractDetails.bankDetailsSection;

interface BankDetailsSectionProps {
  sectionNumber?: number;
}
export const BankDetailsSection = (props: BankDetailsSectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      title={t(translations.inquiryType.mmv.steps.contractDetails.sections.bankDetails.title)}
      name="bankDetails"
      {...props}
    >
      <FormRow>
        <NewStaticFieldWithField
          caption={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.bankDetails.accountHolder
              .caption,
          )}
          name={accountHolder}
        />
        <InputWithField name={iban} caption="IBAN" />
      </FormRow>
    </FormSection>
  );
};

import _get from 'lodash/get';

import { RLLProfitability } from 'models/types/Profitability.type';
import {
  CURRENT_USAGE_REE_PARKING_LOT_VALUE,
  CURRENT_USAGE_REE_GARAGE_VALUE,
  CURRENT_USAGE_REE_GARAGE_RENT,
  CURRENT_USAGE_REE_PARKING_LOT_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_VALUE,
  FUTURE_USAGE_REE_GARAGE_VALUE,
  FUTURE_USAGE_REE_GARAGE_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_RENT,
  CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './mapProfitabilitySectionsToApi/utils';

const mapMarketValueToReeEditApi = (data: Record<string, unknown>): RLLProfitability['realEstateExpert'] => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00')!;

  return {
    current: {
      building: {
        marketValuePerSqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING))) || initialValue,
        marketValueParkingLot: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_PARKING_LOT_VALUE))) || initialValue,
        commercialRentPerSqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM))) || initialValue,
        rentParkingLot: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_PARKING_LOT_RENT))) || initialValue,
        marketValueGarage: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_GARAGE_VALUE))) || initialValue,
        residentialRentPerSqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM))) || initialValue,
        rentGarage: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_GARAGE_RENT))) || initialValue,
      },
      unit: {
        marketValuePerSqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT))) || initialValue,
        marketValueParkingLot: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_PARKING_LOT_VALUE))) || initialValue,
        commercialRentPerSqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM))) || initialValue,
        rentParkingLot: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_PARKING_LOT_RENT))) || initialValue,
        marketValueGarage: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_GARAGE_VALUE))) || initialValue,
        residentialRentPerSqm: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM))) || initialValue,
        rentGarage: toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_GARAGE_RENT))) || initialValue,
      }
    },
    future: {
      building: {
        marketValuePerSqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING))) || initialValue,
        marketValueParkingLot: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_PARKING_LOT_VALUE))) || initialValue,
        commercialRentPerSqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM))) || initialValue,
        rentParkingLot: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_PARKING_LOT_RENT))) || initialValue,
        marketValueGarage: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_GARAGE_VALUE))) || initialValue,
        residentialRentPerSqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM))) || initialValue,
        rentGarage: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_GARAGE_RENT))) || initialValue,
      },
      unit: {
        marketValuePerSqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT))) || initialValue,
        marketValueParkingLot: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_PARKING_LOT_VALUE))) || initialValue,
        commercialRentPerSqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM))) || initialValue,
        rentParkingLot: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_PARKING_LOT_RENT))) || initialValue,
        marketValueGarage: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_GARAGE_VALUE))) || initialValue,
        residentialRentPerSqm: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM))) || initialValue,
        rentGarage: toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_GARAGE_RENT))) || initialValue,
      }
    }
  }
}

export default mapMarketValueToReeEditApi;

import _merge from 'lodash/merge';
import { useSelector } from 'react-redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import { SAVE_INQUIRIES } from 'api/CompeonReverseApi/actionTypes';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { INQUIRY_DRAFT_ID, COMPEON_PARTNER } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { mapBFSInquiryToApi } from 'modules/Inquiry/mapInquiryToApi/mapBFSInquiryToApi';
import { mapBFSServiceInquiryToApi } from 'modules/Inquiry/mapInquiryToApi/mapBFSServiceInquiryToApi';
import { mapBigPictureInquiryToApi } from 'modules/Inquiry/mapInquiryToApi/mapBigPictureInquiryToApi';
import { mapInquiryToApi } from 'modules/Inquiry/mapInquiryToApi/mapInquiryToApi';
import { mapProfiInquiryToApi } from 'modules/Inquiry/mapInquiryToApi/mapProfiInquiryToApi';
import { mapLeasePlanToApi } from 'modules/Inquiry/mapInquiryToApi/peopleMapper/peopleDetailsToApi';
import { addInquiryProcessPropsToPayload } from 'shared/inquiry/inquiryProcess';
import { getFormDataFromStorage } from 'shared/inquiry/inquirySessionStorage.helpers';
import { IState } from 'store';
import { getInquiryIdSelector, getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { getInquiryDraftIdSelector, isLeadProcessLane } from 'store/inquiryProcess/selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { isBankAdvisor, isCustomerSelector, isLoggedInSelector } from 'store/user/selectors';
import { pipe, flattenObject } from 'utils/helpers';
import useDispatchApiCall, {
  IMakeCallResponse,
  dispatchOptions,
} from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useFilterDataByBusinessConditions, filterEmptyData } from './useFilterInquiryData';
import { mapOnlinefactoringToApi } from '../../../onlinefactoring/mapOnlinefactoringToApi';

export const typeSpecificMapping: { [kind: string]: Function } = {
  [InquiryType.bfsService]: mapBFSServiceInquiryToApi,
  [InquiryType.default]: mapInquiryToApi,
  [InquiryType.leaseplan]: mapLeasePlanToApi,
  [InquiryType.bfs]: mapBFSInquiryToApi,
  [InquiryType.profiRLL]: mapProfiInquiryToApi,
  [InquiryType.dvag]: mapInquiryToApi,
};

export const createSpecializedInquiryAction = (apiData: any, inquiryType: string) => {
  return callReverseApi({
    actionName: SAVE_INQUIRIES,
    url: endpoints.INQUIRIES.SPECIALIZED.TYPE.compose({ params: { inquiryType } }),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: apiData,
      },
    },
  });
};

const createFinalizeSpecializedInquiryAction = (
  apiData: any,
  inquiryType: string,
  inquiryId: string,
) => {
  return callReverseApi({
    actionName: SAVE_INQUIRIES,
    url: endpoints.INQUIRIES.SPECIALIZED.FINALIZE.compose({
      params: { inquiryType, inquiryId },
    }),
    method: API_METHODS.POST,
    data: (state: IState) => addInquiryProcessPropsToPayload(state.inquiryProcess || {}, apiData),
  });
};

const createPartialSpecializedInquiryAction = (apiData: any, inquiryType: string) =>
  callReverseApi({
    actionName: SAVE_INQUIRIES,
    url: endpoints.INQUIRIES.SPECIALIZED.PARTIAL.compose({ params: { inquiryType } }),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: apiData,
      },
    },
  });

export const updateSpecializedInquiryAction = (inquiryId: string, apiData: any) =>
  callReverseApi({
    actionName: 'UPDATE_INQUIRY',
    url: endpoints.INQUIRIES.SPECIALIZED.DETAILS.compose({ params: { inquiryId } }),
    method: API_METHODS.PUT,
    data: {
      data: {
        attributes: apiData,
      },
    },
  });

const updateInquiryTypeSpecificAction = (inquiryType: string, inquiryId: string, apiData: any) =>
  callReverseApi({
    actionName: 'UPDATE_INQUIRY',
    url: endpoints.INQUIRIES.SPECIALIZED.UPDATE.compose({ params: { inquiryType, inquiryId } }),
    method: API_METHODS.PUT,
    data: {
      data: {
        attributes: apiData,
      },
    },
  });

type IInquirySendFunction = (data?: any) => IMakeCallResponse;

const useSendNewSpecializedInquiry = (): IInquirySendFunction => {
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const isLead = useSelector(isLeadProcessLane);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const t = useTranslations();
  const { makeCall } = useDispatchApiCall(dispatchOptions(t('errors.submitInquiry')));
  const draftId = useSelector(getInquiryDraftIdSelector);
  const { selectedInquiryType } = useFormConfig();
  const inquiryId = useSelector(getCreatedInquiryId);
  const isBA = useSelector(isBankAdvisor);
  const isCustomer = useSelector(isCustomerSelector);

  const filterDataByBusinessConditions = useFilterDataByBusinessConditions();

  return async () => {
    const formData = getFormDataFromStorage(partnerName);

    let inquiryData;
    switch (selectedInquiryType) {
      case InquiryType.bigPicture:
        inquiryData = mapBigPictureInquiryToApi(
          { ...formData, [INQUIRY_DRAFT_ID]: draftId },
          isLoggedIn,
          isLead,
          isBA,
        );
        break;
      case InquiryType.onlinefactoring:
        inquiryData = mapOnlinefactoringToApi(
          { ...formData, [INQUIRY_DRAFT_ID]: draftId },
          isLoggedIn,
          isLead,
        );
        break;
      case InquiryType.bfs:
        inquiryData = mapBFSInquiryToApi({ ...formData, [INQUIRY_DRAFT_ID]: draftId }, isCustomer);
        break;
      case InquiryType.profiRLL:
        inquiryData = mapProfiInquiryToApi({ ...formData }, isCustomer);
        break;
      case InquiryType.bfsService:
        inquiryData = mapBFSServiceInquiryToApi(
          { ...formData, [INQUIRY_DRAFT_ID]: draftId },
          isLoggedIn,
        );
        break;
      case InquiryType.dvag:
        inquiryData = {
          ...typeSpecificMapping[selectedInquiryType]({
            ...formData,
          }),
        };
        inquiryData = {
          ...inquiryData,
          data: {
            ...inquiryData.data,
            attributes: {
              ...inquiryData.data.attributes,
              [INQUIRY_DRAFT_ID]: draftId,
            },
          },
        };
        break;
      default:
        inquiryData = typeSpecificMapping[selectedInquiryType]({
          ...formData,
          [INQUIRY_DRAFT_ID]: draftId,
        });
        break;
    }

    const dataForApi = pipe(filterEmptyData, filterDataByBusinessConditions)(inquiryData);
    const isBigPicture = selectedInquiryType === InquiryType.bigPicture;
    const isOnlineFactoring = selectedInquiryType === InquiryType.onlinefactoring;
    const isLeasePlan = selectedInquiryType === InquiryType.leaseplan;
    const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
    const isDvag = selectedInquiryType === InquiryType.dvag;
    const isBFSService = selectedInquiryType === InquiryType.bfsService;

    const apiData = isProfiInquiry || isDvag ? { ...dataForApi?.data?.attributes } : dataForApi;

    const sendSpecialized = () => createSpecializedInquiryAction(apiData, selectedInquiryType);
    const sendSpecialFinalize = () =>
      createFinalizeSpecializedInquiryAction(dataForApi, selectedInquiryType, inquiryId);

    const sendInquiry =
      isOnlineFactoring || isBigPicture || isLeasePlan || isBFSService
        ? sendSpecialFinalize
        : sendSpecialized;

    return makeCall(sendInquiry());
  };
};

export const useSendPartialSpecializedInquiry = (): IInquirySendFunction => {
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const isLead = useSelector(isLeadProcessLane);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const t = useTranslations();
  const { makeCall } = useDispatchApiCall(dispatchOptions(t('errors.submitInquiry')));
  const { selectedInquiryType } = useFormConfig();

  const filterDataByBusinessConditions = useFilterDataByBusinessConditions();

  return async () => {
    const formData = getFormDataFromStorage(partnerName);

    let inquiryData;
    if (selectedInquiryType === InquiryType.bigPicture) {
      inquiryData = mapBigPictureInquiryToApi(formData, isLoggedIn, isLead);
    } else if (selectedInquiryType === InquiryType.onlinefactoring) {
      inquiryData = mapOnlinefactoringToApi(formData, isLoggedIn, isLead);
    } else {
      inquiryData = typeSpecificMapping[selectedInquiryType](formData);
    }

    const dataForApi = pipe(filterEmptyData, filterDataByBusinessConditions)(inquiryData);

    return makeCall(createPartialSpecializedInquiryAction(dataForApi, selectedInquiryType));
  };
};

const useSendUpdatedSpecializedInquiry = (): IInquirySendFunction => {
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const isLead = useSelector(isLeadProcessLane);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const t = useTranslations();
  const { makeCall } = useDispatchApiCall(dispatchOptions(t('errors.submitInquiry')));
  const draftId = useSelector(getInquiryDraftIdSelector);
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const inquiryId = useSelector(getInquiryIdSelector) ?? '';
  const isCustomer = useSelector(isCustomerSelector);
  const { selectedInquiryType } = useFormConfig();

  const staticData = {
    [INQUIRY_DRAFT_ID]: draftId,
    [COMPEON_PARTNER]: partnerName,
  };

  return (modifiedData?: any) => {
    const isLeasePlan = selectedInquiryType === InquiryType.leaseplan;
    const isBFS = selectedInquiryType === InquiryType.bfs;
    const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
    const isDvag = selectedInquiryType === InquiryType.dvag;
    const formData =
      isLeasePlan || isBFS || isProfiInquiry || isDvag
        ? getFormDataFromStorage(partnerName)
        : inquiryDetails && flattenObject(inquiryDetails);

    let inquiryData;
    if (selectedInquiryType === InquiryType.bigPicture) {
      inquiryData = mapBigPictureInquiryToApi(
        _merge(formData, staticData, modifiedData),
        isLoggedIn,
        isLead,
      );
    } else if (selectedInquiryType === InquiryType.profiRLL) {
      inquiryData = mapProfiInquiryToApi(
        _merge(formData, staticData, modifiedData),
        isCustomer,
        true,
      );
    } else {
      inquiryData = typeSpecificMapping[selectedInquiryType](
        _merge(formData, staticData, modifiedData),
      );
    }

    const dataForApi = pipe(filterEmptyData)(inquiryData);
    const data =
      isProfiInquiry || isDvag
        ? { 'form-type': selectedInquiryType, ...dataForApi?.data?.attributes }
        : dataForApi;

    const sendSpecialized = () => updateSpecializedInquiryAction(inquiryId, data);
    const sendSpecialFinalize = () =>
      createFinalizeSpecializedInquiryAction({ ...dataForApi }, selectedInquiryType, inquiryId);
    const updateByInquiryType = () =>
      updateInquiryTypeSpecificAction(selectedInquiryType, inquiryId, dataForApi);

    let sendInquiry = sendSpecialized;
    switch (selectedInquiryType) {
      case InquiryType.leaseplan:
      case InquiryType.bfsService:
        sendInquiry = sendSpecialFinalize;
        break;
      case InquiryType.bfs:
        sendInquiry = updateByInquiryType;
        break;
    }

    return makeCall(sendInquiry());
  };
};

const useSendSpecializedInquiry = (): IInquirySendFunction => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const sendNewInquiry = useSendNewSpecializedInquiry();
  const updateExistingInquiry = useSendUpdatedSpecializedInquiry();

  return inquiryId ? updateExistingInquiry : sendNewInquiry;
};

export default useSendSpecializedInquiry;

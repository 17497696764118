import React from 'react';

import { useSelector } from 'react-redux';

import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import FileDownloadBlock from 'components/UploadBlock/NewFileRequestBlock/FileDownloadBlock';
import { StyledFileStatusLabel as StatusLabel } from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import { IFile } from 'models/File.model';
import { StyledFilesSection as Section } from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesFromBank/styles';
import {
  getNumOfNewFilesUploadedForCustomer,
  getAdditionalFilesSendByOperation,
} from 'store/documentExchange/documentExchange.selectors';
import { getVideoIdentificationFile } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import FileSectionTitle from '../FileSectionTitle';

const FilesFromBank: React.FC = () => {
  // FIXME
  // @ts-ignore
  const files: IFile[] = useSelector(getAdditionalFilesSendByOperation);
  const videoIdentificationFile = useSelector(getVideoIdentificationFile);

  const notDownloadedFiles = useSelector(getNumOfNewFilesUploadedForCustomer) || 0;

  const t = useTranslations('pages.inquiryDetails.documentExchange');
  const tUpload = useTranslations('components.upload');

  if (!files.length && !videoIdentificationFile?.downloadUrl) {
    return null;
  }

  return (
    <section>
      <FileSectionTitle>
        {t('filesFromBank')}
        {!!notDownloadedFiles && ` (${notDownloadedFiles} ${tUpload('newFile')})`}
      </FileSectionTitle>
      <Section>
        {files.map((file) => (
          <li>
            <UploadedFileBlock
              key={file.id}
              file={file}
              canRemove={false}
              label={
                file.status === DOWNLOAD_STATUS.NOT_DOWNLOADED && (
                  <StatusLabel>{tUpload('newFile')}</StatusLabel>
                )
              }
            />
          </li>
        ))}
        {videoIdentificationFile?.downloadUrl && (
          <li>
            <FileDownloadBlock file={videoIdentificationFile} />
          </li>
        )}
      </Section>
    </section>
  );
};

export default FilesFromBank;

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import { useFormConfig } from 'config/formConfig/hooks';
import type { ApiFile } from 'models/FileApi.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import { addFileAction } from 'store/documentExchange/documentExchange.actions';
import { getAdditionalCustomerFilesWithoutFileRequest } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import FileSectionTitle from '../FileSectionTitle';

const FilesToBank: React.FC = () => {
  const files = useSelector(getAdditionalCustomerFilesWithoutFileRequest);
  const inquiryId = useSelector(getInquiryIdSelector);
  const { selectedInquiryType } = useFormConfig();

  const t = useTranslations(
    `pages.inquiryDetails.documentExchange${
      selectedInquiryType === InquiryType.leaseplan ? `.${InquiryType.leaseplan}` : ''
    }`,
  );
  const tUpload = useTranslations('components.upload');
  const dispatch = useDispatch();

  const addFileToList = (file: ApiFile) => dispatch(addFileAction(mapApiFile(file)));

  return (
    <section data-testid="files-to-bank">
      <FileSectionTitle>{t('otherFilesForBank')}</FileSectionTitle>
      {files.length ? (
        <MultipleFilesBlock
          title={tUpload('otherDocuments.title')}
          description={tUpload('otherDocuments.description')}
          files={files}
          isFileUploadingEnabled
        />
      ) : (
        <UploadAnyFile
          description={t('uploadOtherFilesDesc')}
          onUpload={addFileToList}
          url={endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } })}
        />
      )}
    </section>
  );
};

export default FilesToBank;

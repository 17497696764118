import { StaticField } from 'modules/helpers/useOfferFields';
import { OFFER_FACTORING_LINE } from 'modules/Inquiry/Form/formFields';
import { formatPriceForFactoringLine, replaceDotWithComma } from 'utils/valueFormats';

export const getIndicativeOfferFormattedValueMap = (item: StaticField, value: string) => {
  value = value.replace(/\s/g, '');
  switch (item.name) {
    case OFFER_FACTORING_LINE:
      return `${formatPriceForFactoringLine(parseFloat(value))}`;
    default:
      return `${replaceDotWithComma(value)}${item.unit}`;
  }
};
